$global_color_white: #fff;
$global_color_lightWhite: #f1f1f1;
$global_color_black: #000;
$global_color_bronze: #C1782F;
$global_color_red: #ff0000;
$global_color_maroon: #990000;
$global_color_green: #006633;
$global_color_yellow: #ffd552;
$global_color_orange: #ea5300;
$global_color_lightGray: #ccc;
$global_color_lightBlue: #e3e8f5;
$global_color_lightMediumGray: #757575;
$global_color_mediumGray: #666;
$global_color_darkGray: #333;
$global_color_mediumDarkGray: #565A5C;
$global_color_charcoal: #333f48;
$global_color_primaryBlue: #003da5; 
$global_color_linkBlue: #069;
$global_color_darkBlue: #0052cc;
$global_color_skyBlue: #e1f5fe;
$global_color_mostlyBlack: #040404;
$global_color_transparent: transparent; 
$global_color_title: #0047ba;
$global_color_alert: #e4002b;
$global_color_submenuhover: #286ee5;
$global_color_footer: #26272d;
$global_color_darkModerateViolet: #4c2c92;
$global_fontFamily_body: 'Open Sans', sans-serif;
$global_fontFamily_deptTitle: 'Open Sans', Arial;
$global_fontFamily_default: 'Open Sans';
$global_fontFamily_headers: 'Seattle Text', Arial;
$global_fontFamily_tile: 'Seattle Text';
$global_fontFamily_deptFooter: 'Seattle Text';
$global_fontSize_default: 16px;
$global_fontSize_tablet_default: 14px;
$global_fontSize_mobile_default: 12px;
$global_backgroundImage_logoHome: url('//www.seattle.gov/images/2015_IMG/logoHome.png');
$global_backgroundImage_seattleLogoBW: url('//www.seattle.gov/images/2015_img/logo_bw.png');
$global_title_mayorName: 'Mayor Bruce Harrell';

$google_fontSize_default: 24px;

$mainColMain_fontSize_ol-li: 24px;
$mainColMain_color_danger: #ab2328;
$mainColMain_color_buttonInfo: #69b3e7;
$mainColMain_color_panelWarning: #ffd552;
$mainColMain_color_accordion: #f1f1f1;
$mainColMain_color_accordion_tab: #dad9d6;

$media_backgroundImage_smallDesktop: url('//www.seattle.gov/images/2015_img/PageBackgrounds/portalListWrapperBG960.png');
$media_backgroundImage_largeDesktop: url('//www.seattle.gov/images/2015_img/PageBackgrounds/portalListWrapperBG1280.png');

$seaGov_backgroundColor_normalAlert: #02599d;
$seaGov_backgroundColor_mediumAlert: #fce45c;
$notification_backgroundColor_normalAlert: #e1f5fe;
$notification_backgroundColor_mediumAlert: #fff8e1;
$notification_backgroundColor_highAlert: #ffebee;
$seaGov_fontSize_header: 24px;
$seaGov_fontSize_text: 14px;

$search_fontSize_google: 14px;

$tiles_width_default: 310px;
$tiles_height_default: 150px;
$tiles_height_double: 310px;