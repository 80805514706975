/*Tiles
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/

/*Generic Tiles 
______________________________________________________________________*/
.taxonomyTile.width2 {
    width: 630px;
}

.taxonomyTile.height2 {
    height: $tiles_height_double;
}

.taxonomyTile.programTile:not(.height2):not(.width2) {
    background-size: 100% auto;
}

.taxonomyTile.programTile:not(.height2):not(.width2) a .emptyTitle {
    width: $tiles_width_default;
    height: $tiles_height_default;
    display: block;
    margin: -15px 0px 0px -15px;
    transition: all .25s;
}

.taxonomyTile.programTile:not(.height2):not(.width2) a:hover .emptyTitle {
    border-bottom: 15px solid rgba(0,0,0,0.5);
}

.taxonomyTile > .primaryContent > .fa-circle-info {
    display: none;
}

.tileExcerpt, .tileTextDescription {
    max-height: 110px;
    line-height: 18px;
    color: $global_color_black;
    font-weight: normal;
}

#featuredTileContainer .featuredService .tileExcerpt, #featuredTileContainer .featuredService .tileTextDescription {
    color: $global_color_white;
}

.tileTitle {
    color: $global_color_title;
    margin: 0px 0px 10px 0px;
    font-size: 18px;
    font-family: $global_fontFamily_default;
}

.additionalContent {
    display: none;
}

.height2 .primaryContent {
    height: 280px;
}

.primaryContent:hover {
    cursor: pointer;
}

.departmentIcon {
    width: 26px;
    vertical-align: bottom;
}

.taxonomyTile, #mainColMain div.taxonomyTile {
    background: $global_color_white;
    width: $tiles_width_default;
    height: $tiles_height_default;
    margin: 5px;
    border: 0px;
    padding: 15px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

#featuredTileContainer .taxonomyTile {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

#featuredTileContainer .overlayBox {
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.01) 1%, rgba(0,0,0,0.95) 80%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.01) 1%,rgba(0,0,0,0.95) 80%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.01) 1%,rgba(0,0,0,0.95) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#f2000000',GradientType=0 ); /* IE6-9 */
    box-sizing: border-box;
    height: 125px;
    line-height: 22.8571px;
    position: absolute;
    width: 100%;
    margin-left: -15px;
    bottom: 0; 
}

#featuredTileContainer #featureTileWrapper .overlayBox {
    margin-left: 0px;
}

#featuredTileContainer .newsTile.taxonomyTile h3.newsTileTitle,
#featuredTileContainer .newsTile.taxonomyTile .newsSource {
    cursor: pointer;
}

.overlayBox #additionalContent {
    display: none;
}
/* hidden text overlay */
.textHiddenContainer {
    display: block;
    height: 280px;
}

.taxonomyTileLinkWrapper {
    .iconWrapper {
        position: absolute;
        bottom: 16px;
        right: 10px;
    }
}

.taxonomyTileLinkWrapper:hover .tileTitle,
.taxonomyTileLinkWrapper:hover .featuredVideoTitle {
    text-decoration: underline;
}

//NFlores fix for download icon not displaying on hover
.TitledCollection .taxonomyTile svg:hover {
    color: #0047ba;
}

.TitledCollection .taxonomyTile svg {
    color: #0047ba !important;
}

//NFlores fix for download icon showing outside the tile
.SingleRowTilesetComponent .taxonomyTile svg {
    bottom: 15px;
    position: absolute;
    right: 15px;
    color: #0047ba;
}

/*Feature Tiles
______________________________________________________________________*/

div#featuredTileContainer .featureTile {
    height: $tiles_height_double;
}

#featuredTileContainer .featureTile h3.tileTitle {
    font-family: $global_fontFamily_default;
    font-size: 30px;
    color: $global_color_white;
    line-height: 32px;
    max-width: 580px;
    margin-bottom: 6px;
    text-shadow: rgba(0, 0, 0, 0.648) 0px 0px 10px;
}

.featureTile .primaryContent h3.tileTitle, .programTile .primaryContent h3.tileTitle {
    bottom: 0;
    position: absolute;
    padding-left: 15px;
    padding-right: 15px;
}

.featureTile h3.tileTitle a {
    color: $global_color_white;
}

/* featureTile Alert */
.featureTile.seattleAlert .alertIcon {
    background-color: $global_color_alert;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 40px;
    height: 40px;
    padding: 7px 15px;
}

/* featured tile carousel */
div#featureTileWrapper .carousel.slide {
    margin: -15px;
}

.noTile #featureTileWrapper .carousel.slide {
    margin: 0px;
}

#featuredTilesCarousel a.carousel-control:focus svg {
    outline: solid;
    color: $global_color_linkBlue;    
}

#featuredTilesCarousel .carousel-control > i {
    bottom: 10px;
    position: absolute;
}

/* Fontawesome v5 */
#featuredTilesCarousel .carousel-control > svg {
    bottom: 10px;
    position: absolute;
    height:24px;
}

#featuredTilesCarousel .carousel-control.prev {
    margin-right: 80px;
}

#featuredTilesCarousel .carousel-control.next {
    margin-right: 20px;
}

#featuredTilesCarousel .carousel-control.play {
    margin-right: 50px;
}

#featuredTilesCarousel .carousel-control.pause {
    margin-right: 50px;
    display: none;
}

#featuredTilesCarousel .carousel-inner {
    /*overflow-y: visible;*/
}

#featuredTilesCarousel a.carousel-control {
    background-image: none;
    width: 25px;
    font-size: 25px;
}

/*Twitter Tiles
______________________________________________________________________*/
#featuredTileContainer .twitterTile.taxonomyTile {
    padding-top: 3px;
    padding-bottom: 5px;
    overflow-y: hidden;

    &:focus {
        outline: solid;
        color: $global_color_linkBlue;
    } 
}

#featuredTileContainer .twitterTile.taxonomyTile iframe {
    height: 132px !important;
}

#rightCol .twitterTile.taxonomyTile,
#mainColMain .twitterTile.taxonomyTile,
.twoColLeft .twitterTile.taxonomyTile {
    height: 100% !important;
    max-width: 620px !important;
    width: 100% !important;
}

/*Contact Tiles
______________________________________________________________________*/
#featuredTileContainer .contactTile h3.tileTitle, .contactTile .popover-title {
    color: $global_color_white;
    background-color: $global_color_black;
    font-size: 22px;
    margin: -15px -15px 20px -15px;
    padding: 25px;
}
/*Heading as a Link*/
#featuredTileContainer .contactTile a h3.tileTitle {
    transition: background .3s;
}

#featuredTileContainer .contactTile a h3.tileTitle:hover {
    background: $global_color_primaryBlue;
}

#featuredTileContainer .contactTile a h3.tileTitle:before {
    content: '';
    float: left;
    position: absolute;
    width: 0px;
    margin-top: 26px;
    border-bottom: 2px solid $global_color_white;
    transition: all .4s;
}

#featuredTileContainer .contactTile a h3.tileTitle:hover:before {
    width: 120px;
    border-bottom: 2px solid $global_color_white;
}

/*#featuredTileContainer .contactTile a h3.tileTitle:after {
    content: '\f054';
    font-family: 'fontAwesome';
    color: $global_color_white;
    float: right;
}

#featuredTileContainer .contactTile a h3.tileTitle:hover:after {
    content: '\f054';
}*/

#featuredTileContainer .contactTile a h3.tileTitle svg{
    float: right;
}

/*Hidden Contact Tile Fields*/
#featuredTileContainer .contactTile .fax,
#featuredTileContainer .contactTile .tty,
#featuredTileContainer .contactTile .contactPageLink {
    display: none;
}

.contactTileContentBlock {
    margin-left: 10px;
}

.contactTile .contactPageLink {
    margin-bottom: 20px;
}

.contactTile label, #deptFootContactWrapper label {
    padding-right: 8px;
}

.contactTile a, .phoneFaxAddressWrapper a, .contactTile button, .phoneFaxAddressWrapper button {
    font-weight: bold;
    color: $global_color_black;
    text-decoration: underline;
}

.contactTile a:hover, .contactTile button:hover, .phoneFaxAddressWrapper button:hover, .phoneFaxAddressWrapper a:hover {
    background: $global_color_primaryBlue;
    color: $global_color_white;
    text-decoration: none;
}

.contactTile button, .phoneFaxAddressWrapper button, #parkHourPopover, .closeParkHours {
    background-color: $global_color_transparent;
    border: none;
    padding: 0px;
}

#parkContactPopover .featureContactPhone {
    float: left;
}

#parkContactPopover .featureContactPhoneNumber {
    float: left;
    text-decoration: underline;
    padding-left: 5px;
}

/*Social Media*/
#mainColMain .contactTileSocialMediaWrapper, .contactTileSocialMediaWrapper {
    margin-top: 14px;
    margin-bottom: 9px;

    a {
        color: #474747;
        text-decoration: none !important;
        margin-right: 6px;

        svg {
            font-size: 26px;
        }

        &:hover {
            color: $global_color_primaryBlue;
            background: $global_color_transparent;

            svg {
                color: $global_color_primaryBlue;
            }
        }
    }

    .fa-arrow-up-right-from-square {
        display: none;
    }

    .socialMediaContactItem svg {
        margin-left: 0px !important;
    }
}

#mainColMain .contactTileSocialMediaWrapper a:hover {
    color: $global_color_primaryBlue;
    background: $global_color_transparent;
}

.contactTilePhysicalAddress, .contactTileMailingAddress {
    padding: 0px 10px;
}

.closeAddress {
    border: 0px;
    background: $global_color_transparent;
}

.closeAddress:hover {
    background: $global_color_black;
    color: $global_color_white;
}

/*News Tiles
______________________________________________________________________*/
.newsTile .primaryContent:hover {
    cursor: auto;
}

.newsDateContainer .newsDate {
    background-color: #943400;
    color: $global_color_white;
    margin-left: -15px;
    font-weight: bold;
    font-size: 14px;
    padding: 5px 15px;
}
/* Fontawesome v5 */
.newsDateContainer svg.fa-rss {
    color: #943400;
    font-size: 1.5em;
}

/* News as seattle alert */
.newsWrapperAngular.seattleAlert {
    background-color: $global_color_alert;
    width: $tiles_width_default;
    height: $tiles_height_default;
    margin-top: -15px;
    margin-left: -15px;
    padding: 15px;
}

.newsWrapperAngular.seattleAlert .newsDateContainer .newsDate {
    background-color: $global_color_black;
    color: $global_color_white;
}
/* Fontawesome v5 */
.newsWrapperAngular.seattleAlert .newsDateContainer svg.fa-exclamation, .featureTile.seattleAlert svg.fa-exclamation, svg.fa-download {
    color: $global_color_white;
    font-size: 28px;
}

.newsWrapperAngular.seattleAlert h3.newsTileTitle, .newsWrapperAngular.seattleAlert h3.newsTileTitle a, .newsWrapperAngular.seattleAlert .newsSource, .featureTile.seattleAlert {
    color: $global_color_white;
}
/* News as press release */
.newsDateContainer.pressRelease .newsDate {
    background-color: $global_color_primaryBlue;
}

.newsDateContainer.pressRelease .fa-seattlelogo {
    background-image: $global_backgroundImage_seattleLogoBW;
    height: 24px;
    width: 24px;
    background-size: contain;
}

.primaryContent h3.newsTileTitle {
    font-size: 14px;
    margin-top: 0px;
    padding-top: 15px;
    font-family: $global_fontFamily_body;
    line-height: 16px;
}

h3.newsTileTitle a {
    color: $global_color_black;
}
/*News as press release SIZE B (Council)*/
#featuredTileContainer .newsTile.height2 {
    background-color: $global_color_primaryBlue;
}

#featuredTileContainer .newsTile.height2 .newsWrapperAngular,
#featuredTileContainer .newsTile.height2 .primaryContent {
    height: 100%;
}

#featuredTileContainer .newsTile.height2 .newsDate {
    background: $global_color_white;
    color: $global_color_black;
}

#featuredTileContainer .newsTile.height2 .fa {
    background: $global_color_white $global_backgroundImage_seattleLogoBW;
    background-size: 31px 31px;
    background-repeat: no-repeat;
    background-position: 1px;
    width: 33px;
    height: 33px;
    border-radius: 25px;
}

#featuredTileContainer .newsTile.height2 .fa:before {
    display: none;
}

#featuredTileContainer .newsTile.height2 h3.newsTileTitle {
    height: 100%;
    display: table;
    padding-bottom: 60px;
}

#featuredTileContainer .newsTile.height2 a h3.newsTileTitle,
#featuredTileContainer .newsTile.height2 h3.newsTileTitle a {
    font-family: $global_fontFamily_default;
    color: $global_color_white;
    font-size: 26px;
    line-height: 28px;
    display: table-cell;
    vertical-align: middle;
    height: 262px;
    padding-bottom: 60px;
    text-align: center;
}

#featuredTileContainer .newsTile.height2 .newsSource {
    display: none;
}

.primaryContent .newsSource {
    font-size: 14px;
    position: absolute;
    bottom: 15px;
    right: 15px;
}

/*Basic Color Tiles
______________________________________________________________________*/
.cTileBasic, .bTileBasic {
    padding: 0px;
    background-color: #333f48;
}

.cTileBasic h3, .bTileBasic h3, .bTileBasic .primaryContent {
    color: $global_color_white;
    font-size: 24px;
    font-weight: 800;
    display: table-cell;
    width: $tiles_width_default;
    height: $tiles_height_default;
    padding: 0px 20px;
    vertical-align: middle;
    text-align: center;
}

.bTileBasic h3 {
    font-size: 32px;
}

.bTileBasic .primaryContent {
    height: $tiles_height_double;
}

.BlackonYellow h3 {
    color: $global_color_black;
}

.cTileBasic:hover .primaryContent, .bTileBasic:hover .primaryContent {
    background: rgba(0,0,0,0.5);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#4c000000,endColorstr=#4c000000);
    text-decoration: none;
}

/*colors */
.WhiteonAqua {
    background: #0072be;
}

.WhiteonGreen {
    background: $global_color_green;
}

.BlackonYellow {
    background: $global_color_yellow;
}

.WhiteonGray {
    background: $global_color_mediumGray;
}

/* color block tiles */
*[id*="programTileLink_"]:hover .tileTitle,
*[id*="programTileLink_"]:hover .tileTextDescription {
    text-decoration: underline;
}

.BlackonYellow .title,
.BlackonYellow .excerpt,
*[id*="Tiles"] .secondaryTiles .BlackonYellow .tileTitle,
*[id*="Tiles"] .secondaryTiles .BlackonYellow .tileTextDescription,
*[id*="Tiles"].BlackonYellow .tilesWrapper *[class^="textDescriptionTile"] > .tileTitle,
*[id*="Tiles"].BlackonYellow .tilesWrapper *[class^="textDescriptionTile"] > .tileTextDescription {
    color: $global_color_black;
}

.BlackonWhite .title,
.BlackonWhite .excerpt,
*[id*="Tiles"] .secondaryTiles .BlackonWhite .tileTitle,
*[id*="Tiles"] .secondaryTiles .BlackonWhite .tileTextDescription,
*[id*="Tiles"].BlackonWhite .tilesWrapper *[class^="textDescriptionTile"] > .tileTextDescription,
*[id*="colorBlockTiles_"] .BlackonWhite .tileTitle,
*[id*="colorBlockTiles_"] .BlackonWhite .tileTextDescription {
    color: $global_color_black;
}

.WhiteonAqua .title,
.WhiteonAqua .excerpt,
*[id*="Tiles"] .secondaryTiles .WhiteonAqua .tileTitle,
*[id*="Tiles"] .secondaryTiles .WhiteonAqua .tileTextDescription,
*[id*="Tiles"].WhiteonAqua .tilesWrapper *[class^="textDescriptionTile"] > .tileTitle,
*[id*="Tiles"].WhiteonAqua .tilesWrapper *[class^="textDescriptionTile"] > .tileTextDescription,
*[id*="colorBlockTiles_"] .WhiteonAqua .tileTitle,
*[id*="colorBlockTiles_"] .WhiteonAqua .tileTextDescription {
    color: $global_color_white;
}

.WhiteonGreen .title,
.WhiteonGreen .excerpt,
*[id*="Tiles"] .secondaryTiles .WhiteonGreen .tileTitle,
*[id*="Tiles"] .secondaryTiles .WhiteonGreen .tileTextDescription,
*[id*="Tiles"].WhiteonGreen .tilesWrapper *[class^="textDescriptionTile"] > .tileTitle,
*[id*="Tiles"].WhiteonGreen .tilesWrapper *[class^="textDescriptionTile"] > .tileTextDescription,
*[id*="colorBlockTiles_"] .WhiteonGreen .tileTitle,
*[id*="colorBlockTiles_"] .WhiteonGreen .tileTextDescription {
    color: $global_color_white;
}

.WhiteonBlue .title,
.WhiteonBlue .excerpt,
*[id*="Tiles"] .secondaryTiles .WhiteonBlue .tileTitle,
*[id*="Tiles"] .secondaryTiles .WhiteonBlue .tileTextDescription,
*[id*="Tiles"].WhiteonBlue .tilesWrapper *[class^="textDescriptionTile"] > .tileTitle,
*[id*="Tiles"].WhiteonBlue .tilesWrapper *[class^="textDescriptionTile"] > .tileTextDescription,
*[id*="colorBlockTiles_"] .WhiteonBlue .tileTitle,
*[id*="colorBlockTiles_"] .WhiteonBlue .tileTextDescription {
    color: $global_color_white;
}

.WhiteonGray .title,
.WhiteonGray .excerpt,
*[id*="colorBlockTiles_"] .WhiteonGray .tileTitle,
*[id*="colorBlockTiles_"] .WhiteonGray .tileTextDescription {
    color: $global_color_white;
}

.WhiteonGray .title,
.WhiteonGray .excerpt {
    color: $global_color_white;
}

/* Download Tiles */
.downloadTile.height2:not(.programTile) .fa-download {
    font-size: 120px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.downloadTile.programTile .fa-download {
    float: right;
}

.downloadTile.height2 .primaryContent {
    font-size: 24px;
    text-align: center;
    padding-top: 50px;
}

.downloadTile .primaryContent > h3.tileTitle {
    padding-left: 0px;
}

.downloadTile.height2 h3 {
    font-size: 24px;
    color: $global_color_white;
}

/*Event Tiles
______________________________________________________________________
---Inner iframe styles located in trumba.css
*/
/*B Version as a Spud*/
.taxonomyTile.trumbaComponent.height2 {
    overflow: hidden;
    padding: 10px 18px 0px 18px;
}

.taxonomyTile.trumbaComponent.height2 iframe {
    width: 275px;
    height: 280px;
}

/*C Version as a feed*/
.taxonomyTile.eventTile.trumbaComponent {
    padding: 0px;
}

.taxonomyTile.eventTile.trumbaComponent p {
    display: none;
}

.eventDateBox {
    float: left;
    width: 22%;
    border: solid 1px #eee;
    margin-right: 15px;
    padding: 10px;
    text-align: center;
}

.eventDateBox .month {
    background: $global_color_maroon;
    font-size: 20px;
    margin: -10px -10px 0px;
    height: 40px;
    padding-top: 6px;
    color: $global_color_white;
}

.eventDateBox .date {
    margin: 5px auto;
    font-size: 26px;
    color: $global_color_mediumGray;
}

.eventDateBox .time {
    font-size: 14px;
    margin-left: -4px;
}

.eventContent {
    padding: 10px;
}

.eventContent h3.tileTitle a {
    color: $global_color_black;
}

#featuredTileContainer .eventContent .tileTitle {
    font-size: 20px;
    margin-top: -8px;
}

span.eventVenue {
    color: #777;
}

/*Video Tiles
______________________________________________________________________*/

.width2.height2 .videoItem {
    margin-top: 15px;
}

.slick-slider #featureTileWrapper .taxonomyTile.height2.featuredVideoContainer {
    margin: 0px;
    padding-top: 0px;
}

.videoImage {
    margin: -15px -15px 0 -15px;
    position: relative;
    height: $tiles_height_default;
    background-size: cover;
    background-position: center center;
}

.councilVideos .videoImage {
    margin: 0px;
}

.height2 .videoImage {
    height: 160px;
}

.videoImage.default {
    background-size: auto;
    background-repeat: no-repeat;
}

/*.videoImage:before {
    position: absolute;
    content: "\f16a";
    font-family: 'fontAwesome';
    font-size: 36px;
    color: $global_color_white;
    margin: 45px 0px 0px 153px;
    padding: 0px;
    top: -2px;
    left: -18px;
    text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
    opacity: .75;
}*/

.height2 .videoImage:before {
    margin: 55px 0px 0px 153px;
}

.width2.height2:not(.slick-slide) .videoImage:before {
    font-size: 60px;
    margin: 105px 0px 0px 320px;
}

a:hover .videoImage:before {
    opacity: 1;
    font-size: 48px;
    margin: 38px 0px 0px 148px;
    transition: all .2s;
}

.height2 a:hover .videoImage:before {
    margin: 48px 0px 0px 148px;
}

.width2.height2:not(.slick-slide) a:hover .videoImage:before {
    font-size: 72px;
    margin: 95px 0px 0px 315px;
}

a:hover .videoDescription * {
    text-decoration: underline;
}

/*Service Tiles
______________________________________________________________________*/
#featuredTileContainer .featuredService, .WhiteonBlue {
    background-color: $global_color_primaryBlue;
    color: $global_color_white;
}

#featuredTileContainer .nonFeaturedService {
    background-color: $global_color_white;
}

#featuredTileContainer .featuredService h3,
#featuredTileContainer .featuredService h3 a,
#featuredTileContainer .nonFeaturedService h3,
#featuredTileContainer .nonFeaturedService h3 a {
    margin-top: 0px;
    margin-bottom: 15px;
}

#featuredTileContainer h3.featuredVideoTitle,
#featuredTileContainer .featuredService h3,
#featuredTileContainer .nonFeaturedService h3,
#featuredTileContainer .featuredService h3 a,
#featuredTileContainer .nonFeaturedService h3 a, #featuredTileContainer .programTile h3 a, #featuredTileContainer .programTile h3 {
    color: $global_color_white;
    font-size: 18px;
    font-family: $global_fontFamily_body;
    line-height: 20px;
}

#featuredTileContainer .nonFeaturedService h3,
#featuredTileContainer .nonFeaturedService h3 a {
    color: $global_color_title;
}

#featuredTileContainer h3.featuredVideoTitle {
    margin-top: 15px;
    color: $global_color_black;
}

#featuredTileContainer .programTile h3 a, #featuredTileContainer .programTile h3 {
    font-size: 21px;
    line-height: 22px;
    padding-bottom: 2px;
}
/*Popular Service Tiles*/
#topicServices .popularService {
    background-color: #f3f7ff;
}

/*.popularService .tileTitle:after,
label.popularServicesInput:after {
    content: "\f005";
    font-family: "fontAwesome";
    color: #ffd600;
}*/

.popularService .tileTitle:after {
    display: block;
    margin-top: 10px;
    margin-right: 10px;
    top: 0px;
    right: 0px;
    float: right;
    position: absolute;
}

label.popularServicesInput:after {
    display: inline;
    float: none;
    margin-left: 2px;
    font-size: 18px;
}

.popularService .tileTitle {
    max-width: 266px;
}

.secondaryTiles .primaryContent .tileTitle,
#secondaryItems .primaryContent .tileTitle {
    margin: 0px 0px 15px 0px;
}

.topicList .taxonomyTile,
.secondaryTiles .taxonomyTile,
#secondaryItems .taxonomyTile,
#topicPrograms .taxonomyTile,
#topicServiceContainer .tileService {
    box-shadow: none;
    border: solid 1px $global_color_lightGray;
}

.secondaryList.container {
    padding: 0 5px 20px;
}

.secondaryList.container .secondaryListTitle {
    color: $global_color_white;
}

#additionalFiltersWrap {
    margin: 0 14px 0 5px;

    h3 {
        font-size: 20px;
        font-weight: 700;
        font-family: $global_fontFamily_default;
    }
}

.additionalFilterNoResult {
    margin: 5px 5px 5px 20px;
    color: $global_color_red;
    font-weight: bold;
}

/*Navigation Contact Tiles
______________________________________________________________________*/
.navigationContact .primaryContent {
    height: $tiles_height_default;
    width: $tiles_width_default;
    margin: -15px;
    padding: 15px;
}

.tileContactNavigation .navigationContact h3 {
    color: #235273;
    font-size: 20px;
    margin: 0px;
}

.tileContactNavigation .navigationContact span {
    color: $global_color_black;
}

.tileContactNavigation .navigationContact a:hover {
    text-decoration: none;
}

.tileContactNavigation .navigationContact a:hover .primaryContent {
    border: 5px solid rgba(255,255,255,.75);
    -moz-box-shadow: inset 0 0 20px $global_color_black;
    -webkit-box-shadow: inset 0 0 20px $global_color_black;
    box-shadow: 0px 0px 20px $global_color_black;
    padding: 10px;
}

/*Newsletter Sign Up Tiles --- Inherits styles from footer version
______________________________________________________________________*/
.newsletterSignup.taxonomyTile h3 {
    font-family: $global_fontFamily_default;
    font-size: 20px;
}

.newsletterSignup.taxonomyTile svg:not(:root).svg-inline--fa {
    position: absolute;
    right: 10px;
}

.newsletterSignup.taxonomyTile .listName {
    margin-top: 3px;
    font-size: 17px;
    color: $global_color_black;
    position: absolute;
}

.newsletterSignup.taxonomyTile input,
.newsletterSignup.taxonomyTile button {
    display: block;
    position: absolute;
    bottom: 15px;
}

.newsletterSignup.taxonomyTile input {
    left: 15px;
    height: 42px;
}

.newsletterSignup.taxonomyTile input:placeholder-shown {
    font-size: 13px;
}

.newsletterSignup.taxonomyTile button {
    right: 15px;
    width: 85px;
}

/*Tile Photo Credits
______________________________________________________________________*/
#featuredTileContainer .taxonomyTile:hover .photoCredit {
    display: block;
}

#featuredTileContainer .taxonomyTile .photoCredit {
    position: absolute;
    text-align: right;
    right: 15px;
    top: 15px;
    z-index: 1000;
    display: none;
    background: $global_color_transparent;
    width: 100%;
}

#featuredTileContainer .taxonomyTile .photoCredit a {
    width: 40px;
    height: 40px;
    display: inline-block;
    background: $global_color_transparent;
}

#featuredTileContainer .taxonomyTile .photoCredit i.fa-camera {
    color: $global_color_white;
    font-size: 20px;
    opacity: 0.9;
    text-shadow: 0px 0px 8px rgba(0,0,0, 0.5);
    background: rgba(0,0,0, 0.5);
    border-radius: 25px;
    padding: 10px;
}

#featuredTileContainer .taxonomyTile .photoCredit i.fa-camera:hover {
    opacity: 1;
}

#featuredTileContainer .taxonomyTile .photoCredit .tooltip-inner {
    font-weight: bold;
    font-size: 14px;
    padding: 10px 10px;
}

#featuredTileContainer .taxonomyTile .photoCredit svg.fa-camera {
    color: $global_color_white;
    font-size: 20px;
    opacity: 0.9;
    text-shadow: 0px 0px 8px rgba(0,0,0, 0.5);
    background: rgba(0,0,0, 0.5);
    border-radius: 25px;
    padding: 10px;
}

#featuredTileContainer .taxonomyTile .photoCredit svg.fa-camera:hover {
    opacity: 1;
}
/*Feature Tile Container
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
div#featuredTileContainer {
    padding-left: 5px;
    padding-right: 5px;
    background-color: $global_color_transparent;
    margin-top: 60px;
    min-height: 540px;
}

div#featuredTileContainer.noTile {
    min-height: 405px;
}

div#featuredTileContainer.extendedTileContainer {
    padding-left: 0;
    padding-right: 0;
}

.featureWrapper.emptyFeatureWrapper,
.featureWrapperShort {
    width: 100%;
    height: 350px;
    background-position: center center;
    background-size: cover;
    margin-bottom: -50px;
}

.featureWrapper.emptyFeatureWrapper {
    margin-bottom: 0px;
}

.featureWrapper {
    background-position: center center;
    background-size: cover;
    box-shadow: inset 0 5px 25px -10px rgba(0,0,0,0.25);
}

.featureWrapper > .container {
    background-color: $global_color_transparent;
}
/*Feature Item Extended Carousel*/
.featureWrapper {
    box-shadow: none;
}

.featureWrapper.emptyFeatureWrapper, .featureWrapperShort {
    height: 450px;
}

.colorBlock:not(.SearchCollectionFormContainerComponent):not(.SearchCollectionFormComponent) {
    padding: 20px 40px 0 40px;
}

.container.empty #leftMainRight {
    padding-top: 0;
}

/*Over-write responsive2015 classes*/
/* extended carousel begin*/
.containerFullWidth {
    max-width: 100% !important;
    width: 100% !important;
    padding: 0px;
}

.container.containerFullWidth {
    padding-right: 0;
    padding-left: 0;
}

div#featuredTileContainer.extendedTileContainer {
    height: auto !important;
    min-height: 320px;
}

#extendedCarousel .extendedFeatureTile.featureTile {
    height: 450px;
}

.extendedTileContainer div#featureTileWrapper .carousel.slide {
    margin: 0px 5px;
    /*margin: 0px;*/
}

.FeaturedItemCarousel div#featureTileWrapper .carousel.slide {
    margin: 0px 5px;
}

.extendedTitle {
    position: relative;
    top: 0px;
}

#featuredTileContainer .extendedTitle h3.tileTitle,
#featuredTileContainer [id^='extPrimaryContent_'] h3.tileTitle {
    bottom: -8px;
    position: relative;
    text-align: center;
    max-width: 100%;
    font-size: 44px;
    font-weight: 700;
    line-height: 47px;
    font-family: $global_fontFamily_default;
    color: $global_color_white;
}


#featuredTileContainer .featureTile h3.subTitle,
#featuredTileContainer .extendedTitle h3.subTitle {
    bottom: -10px;
    color: $global_color_white;
    font-size: 22px;
    font-weight: 400;
    line-height: 20px;
}

#featuredTileContainer [id^='extPrimaryContent_'] h3.subTitle {
    bottom: -14px;
}

.extendedFeatureTile {
    background-position: center center;
    background-size: cover;
}

.primaryContent:hover {
    /*pointer-events: none;*/
}

.carousel-control {
    z-index: 1000;
}

/* extended carousel end*/
/* single row tileset tiles begin*/
.disabled {
    pointer-events: none;
}

.SingleRowTilesetComponent .thumbnailImageTile {
    margin-bottom: 0px !important;
}

/* support for Main Column Bottom Color Blocks start*/
.singleRow {
    margin-left: 6px;
}

.moreInformationDiv {
    text-align: center;
    margin: 20px;
    display: block;
}

.thumbnailImageTile {
    background-position: center center;
    background-size: cover;
    height: 150px;
    margin: -15px -15px 0;
    position: relative;
}

.textDescriptionTile {
    width: 100%;
    margin: 5px;
    padding: 15px;
    margin: -1px -15px 0;
    min-width: 310px;
}

.textDescriptionTileReset {
    padding: 15px;
    margin: -16px -15px 0;
}

.textDescriptionTileWider {
    min-width: 405px;
}

.taxonomyTileWider {
    min-width: 410px;
    margin-right: 15px;
}

.taxonomyTileMid {
    min-width: 410px;
    margin-left: 15px;
}

.SingleRowTilesetComponent .indentLeft {
    padding-left: 10px;
}

.SingleRowTilesetComponent .sideBarInsert .taxonomyTile {
    margin-bottom: 10px;
    min-width: 410px;
}

.SingleRowTilesetComponent .sideBarInsert .twitterTile.taxonomyTile {
    height: auto;
}

.SingleRowTilesetComponent .sideBarInsert .tilesWrapper {
    margin-top: -15px;
    overflow-y: hidden;
    max-height: 150px;
}

.SingleRowTilesetComponent.withNav .taxonomyTile.height2 {
    float: left;
}

.SingleRowTilesetComponent.withNav .fullText {
    display: table-row;
    padding-bottom: 30px;
}

/* support for Main Column Bottom Color Blocks end*/
/* support for Main Column Bottom Components start*/
#mainColMain h2 {
    border-bottom: none;
}

#mainColMain div, #mainColMain p {
    margin-bottom: 0px;
}

#mainColMain h3.cpH3 {
    margin-top: 46px;
}

#mainColMain p.cpH3 {
    margin-bottom: 46px;
}

#mainColMain .containerPadTopSides {
    width: 100%;
    margin-left: 0;
}

#mainColMain .secondaryList .secondaryTiles .height2 {
    height: 310px;
    border: solid 1px #ccc;
}

/* support for Main Column Bottom Components end*/
/* Misc updates due to screen width*/
/*Desktop*/
@media (min-width: 992px) and (max-width: 1291px) {
    .singleRow {
        margin-left: 5px;
    }

    .textDescriptionTileWider {
        min-width: 310px;
    }

    .taxonomyTileWider {
        min-width: 310px;
        margin-right: 0px;
    }

    .taxonomyTileMid {
        min-width: 310px;
        margin-left: 0;
    }

    .taxonomyTileMid:nth-child(even) {
        margin-left: 6px;
    }

    .SingleRowTilesetComponent .sideBarInsert {
        margin-left: -9px;
    }

    .SingleRowTilesetComponent .sideBarInsert .taxonomyTile {
        min-width: 310px;
    }

    .SingleRowTilesetComponent .indentLeft {
        padding-left: 20px;
    }
}
/*Tablet*/
@media (min-width: 667px) and (max-width: 991px) {
    .textDescriptionTileWider,
    .SingleRowTilesetComponent .indentLeft .taxonomyTileMid,
    .SingleRowTilesetComponent .sideBarInsert .taxonomyTile {
        min-width: 310px;
    }

    .taxonomyTileWider {
        min-width: 310px;
        margin-right: 10px;
    }

    div#featuredTileContainer .featureTile,
    #extendedCarousel .extendedFeatureTile.featureTile {
        height: 310px;
    }

    #featuredTileContainer .featureTile h3.tileTitle,
    #featuredTileContainer .extendedTitle h3.tileTitle {
        font-size: 26px;
    }

    #featuredTileContainer .featureTile h3.subTitle,
    #featuredTileContainer .extendedTitle h3.tileTitle {
        font-size: 18px;
    }

    /*hide the title div when in tablet mode*/
    #featuredTileContainer .extendedTitle .overlayBox,
    .extendedFeatureTile .primaryContent .overlayBox {
        display: none;
    }

    /*hide the nested #featuredTileContainer's'*/
    #featuredTileContainer.extendedTileContainer #featuredTileContainer,
    #featuredTileContainer.extendedTileContainer #nonSlickWilly {
        display: none;
    }
}
/*Mobile*/
@media (min-width: 286px) and (max-width: 666px) {
    .SingleRowTilesetComponent .taxonomyTile, .taxonomyTileWider, .textDescriptionTileWider,
    .SingleRowTilesetComponent .sideBarInsert .taxonomyTile {
        min-width: 350px;
    }

    .taxonomyTileMid {
        min-width: 350px;
        margin-right: 5px;
    }

    div#featuredTileContainer.extendedTileContainer .featureTile {
        height: 310px !important;
    }

    div#featuredTileContainer .featureTile {
        height: 310px;
    }

    #featuredTileContainer .featureTile h3.tileTitle,
    #featuredTileContainer .extendedTitle h3.tileTitle {
        font-size: 26px;
    }

    #featuredTileContainer .featureTile h3.subTitle,
    #featuredTileContainer .extendedTitle h3.tileTitle {
        font-size: 18px;
    }

    /*hide the title div when in mobile mode*/
    #featuredTileContainer .extendedTitle .overlayBox,
    .extendedFeatureTile .primaryContent .overlayBox {
        display: none;
    }

    /*hide the nested #featuredTileContainer's'*/
    #featuredTileContainer.extendedTileContainer #featuredTileContainer,
    #featuredTileContainer.extendedTileContainer #nonSlickWilly {
        display: none;
    }
}
/* single row tileset tiles end*/
/*Photo Credit*/
.featureWrapper .featuredPhotoCreditContainer {
    position: relative;
}
/* Fontawesome v5 */
.featureWrapper .featuredPhotoCreditContainer .svg-inline--fa {
    cursor: pointer;
}

.featureWrapper .featuredPhotoCreditContainer a {
    color: $global_color_white;
    text-decoration: none;
}

.featureWrapper .featuredPhotoCreditContainer a:hover {
    text-decoration: underline;
}

.featureWrapper.emptyFeatureWrapper .container {
    height: 350px;
    position: relative;
}

.featureWrapper.emptyFeatureWrapper .featuredPhotoCreditContainer {
    position: static;
    margin-top: 350px;
}

.featureWrapper .featuredPhotoCredit {
    background-color: rgba(0,0,0,0.9);
    position: absolute;
    right: 12px;
    bottom: 10px;
    color: $global_color_white;
/*    opacity: .7;*/
    padding: 10px;
}

.featureWrapper .featuredPhotoCredit .featuredPhotoCreditText {
    display: none;
}

.featureWrapper .featuredPhotoCredit .closePhotoIcon {
    float: right;
    margin-left: 10px;
}

.featuredPhotoCreditContainer .featuredPhotoCredit #openPhotoCredit,
.featuredPhotoCreditContainer .featuredPhotoCredit #closePhotoCredit {
    background-color: transparent;
    color: #fff;
    border: none;
}

/* Contact tiles - departmentagency and pios */
.departmentContacts {
    .departmentAgency {
        margin-right: 30px;
        width: 435px;
        border-style: solid;
        border-width: 1px;
        border-color: $global_color_lightGray;
        box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
        padding: 12px 14px 10px 14px;
        margin-bottom: 20px;
        height: 200px !important;
    }

    .departmentAgency.pios {
        height: 180px !important;
    }

    .departmentIcon {
        width: 26px !important;
        vertical-align: bottom;
        margin-right: 9px;
    }

    .tileTitle svg {
        color: $global_color_darkGray;
        font-size: 26px !important;
        margin-right: 9px
    }

    .departmentAgency.pios .email,
    .departmentAgency.pios .phonemain,
    .departmentAgency .phonemain {
        font-weight: bold;
        font-size: 16px;
    }

    .departmentAgency .phonemain {
        margin-top: 9px;
    }

    .departmentAgency.pios .phonemain {
        margin-top: 9px;
    }

    .departmentAgency.pios .email {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .externalLink svg {
        margin-left: 9px;
        width: 14px;
    }
}

#home {

    &.home {
        .colorBlock {
            padding: 20px 0;
        } 
    }

}

/* Portal Services */
.PortalServices, .MajorEvent, .department, #home, .topTier, .CityWideDetail {

    #mainColMain {

        .colorBlock {
            &.MainColumnInsertComponent {
                padding: 0 !important;

                & > .colorBlock {
                    padding: 0 !important;
                }
            }
        }

        .CardContainerComponent {
            // margin: 0 20px;
            margin-top: 52px;
            width: 100%;

            & > .container {
                padding: 0 20px;
            }

            h2 {
                border-bottom: none;
                padding-left: 10px;
            }

            .card {
                margin-left: 0;
            }
        }
    }

    @media (max-width: 575px) {

        #rightCol {
            margin-left: 10px;
        }

        h2 {
            border-bottom: none !important;

            &.cardTitle {
                margin-left: 10px;

                &.cardContainer {
                    padding-left: 20px;
                }
            }
        }

        .CardContainerComponent {

            &.colorBlock {
                padding: 40px 0 0 0 !important;
            }

            .container {

                &:first-child {
                    width: 100%;
                }
            }

            .card {

                svg {
                    &:after {
                        right: 0;
                        background-image: linear-gradient(to left, rgba(255,255,255,0), #fff 85%);
                    }
                }


                &.active, &.slick-current {
                    opacity: 1.0 !important;
                }

                &.cardwithicon {
                    .content {
                        width: 310px;
                    }
                }

                &.slick-list {
                    padding-left: 20px;
                }

                &.opactiy1 {
                    opacity: 1.0;
                }

                &.opacity05 {
                    opacity: 0.5;
                }
            }

            .gradient {

                &:hover:after {
                    background-image: none;
                }

                &:after {
                    content: "";
                    width: 245px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    background-image: linear-gradient(to right, rgba(255,255,255,0), #fff 58%);
                    cursor: pointer;
                }
            }

            &.LightGrayCardContainer {
                .gradient {
                    &:hover:after {
                        background-image: none;
                    }

                    &:after {
                        background-image: linear-gradient(to right, rgba(255,255,255,0), #f1f1f1 90%);
                    }
                }
            }

            &.DarkGrayCardContainer {
                .gradient {
                    &:hover:after {
                        background-image: none;
                    }

                    &:after {
                        background-image: linear-gradient(to right, rgba(255,255,255,0), #515151 58%);
                    }
                }
            }

            &.LightBlueCardContainer {
                .gradient {
                    &:hover:after {
                        background-image: none;
                    }

                    &:after {
                        background-image: linear-gradient(to right, rgba(255,255,255,0), #E1F5FE 58%);
                    }
                }
            }
        }
    }

    .ScrollSpyContainerComponent, .MainColumnTabListComponent {

        &.colorBlock {
            padding-bottom: 0 !important;
        }

        .servicesSection {
            padding: 60px 0 54px 0;

            &:is(.MainColumnTabListComponent *) {
                padding: 30px 0 30px 0;
            }

            &.filteredContainer {
                background-color: $global_color_white;
                padding: 0 0 60px 0;
            }

            &:nth-child(odd):not(.filteredContainer) {
                background-color: $global_color_lightWhite;
            }

            .leftWrapper {
                padding: 0;

                .titleContainer {
                    width: 100%;
                    float: left;
                    padding-bottom: 10px;

                    h2, h3 {
                        .borderBottom {
                            border-bottom: solid 1px #dadada;
                            width: 100%;
                        }

                        &.withIcon {
                            //width: 95%;
                        }
                    }

                    h2.services {
                        margin: -4px 0 6px 0;
                    }

                    svg, h2, h3 {
                        float: left;
                    }

                    svg {
                        font-size: 45px;
                        margin: -10px 23px 0 0;


                        &.Green {
                            color: #007749;
                        }

                        &.Blue {
                            color: #0046AD;
                        }

                        &.Gold, &.Bronze {
                            color: $global_color_bronze;
                        }

                        &.Gray {
                            color: #565A5C;
                        }
                    }
                }

                .padRight {
                    //padding-right: 50px;
                }

                .excerpt {
                    float: left;
                    padding-bottom: 27px;
                    max-width: 850px;

                    a {
                        font-weight: bold;
                        color: $global_color_primaryBlue;
                        text-decoration: none;

                        &:hover {
                            background: $global_color_primaryBlue;
                            color: $global_color_white;
                        }
                    }
                }

                .servicesWrapper {
                    float: left;
                    width: 100%;

                    .serviceList {
                        width: 100%;
                        float: left;
                        margin-bottom: 0;

                        &.filtered {
                            display: flex;
                            height: 100% !important;

                            .card, .taxonomyTile {
                                width: 300px;
                                transform: none !important;
                                transition-property: none !important;

                                .highlight {
                                    font-weight: bold;
                                    color: #000;
                                    background-color: #FFEBEE;
                                }
                            }
                        }

                        .taxonomyTileLinkWrapper {
                            position: relative;

                            .taxonomyTile {
                                width: 300px;

                                .iconWrapper {
                                    bottom: 0;
                                    right: 0;

                                    svg {
                                        position: absolute;
                                        bottom: 20px;
                                        right: 10px;
                                    }
                                }
                            }
                        }
                    }
                }

                .cardContainer {
                    display: table;

                    .row {
                        position: absolute;
                        width: 100%;

                        .card {
                            width: 300px;

                            .content {
                                width: 301px;
                            }

                            &.cardwithicon {
                                .content {
                                    width: 301px;
                                    margin: 0;
                                    padding: 15px;

                                    .cardTitle {
                                        position: relative;
                                        top: 8px;
                                    }
                                }
                            }
                        }
                    }
                }

                .opactiy1 {
                    opacity: 1.0 !important;
                }

                .opacity05 {
                    opacity: 0.5 !important;
                }

                .tileCounter {
                    display: none;
                }

                .rightWrapper {
                    float: left;

                    h2 {
                        font-size: 24px;
                        margin: 30px 0 10px;
                        border-bottom: solid 1px $global_color_lightGray;
                    }

                    div[id^='sidebar_'] {
                        margin-bottom: 60px;
                    }

                    .mainColLinkList {
                        list-style-type: none;
                        margin-left: -40px;

                        li {
                            padding: 10px 0;

                            a {
                                font-weight: bold;
                            }
                        }
                    }

                    .contactTileContentBlock {
                        margin-left: 0;
                    }
                }
            }

            .rightWrapper {

                h2 {
                    font-size: 1.5rem;
                }
            }
        }

        .tabsContainer {

            .servicesSection {
                background-color: $global_color_white !important;

                .tileCounter {
                    display: none;
                }
            }
        }

        @media (max-width: 575px) {

            .padRight {
                padding-right: 0;
            }

            .rightWrapper {
                padding: 0 10px;
                float: left;
                width: 100%;

                div[id^='sidebar_'] {
                    margin: 60px 0 0 0 !important;
                }
            }

            .servicesWrapper {
                position: relative;
                width: 100%;
                float: left;

                .serviceList {

                    &.filtered {
                        display: table;

                        .taxonomyTileLinkWrapper {
                            display: block !important;
                            opacity: 1.0 !important;
                            position: relative !important;
                            margin-bottom: 10px;

                            &.noMatch {
                                display: none !important;
                            }
                        }
                    }

                    &.cardContainer {
                        padding-left: 10px;
                        overflow-x: hidden;

                        .slick-slider {
                            margin-bottom: 0;
                        }
                    }

                    .slick-track {
                        min-height: 210px;
                    }

                    .slick-slide {
                        height: auto;
                    }

                    .taxonomyTileLinkWrapper {
                        margin-right: 10px;
                        opacity: 0.5;

                        &.active, &.slick-current {
                            opacity: 1.0 !important;
                        }

                        .taxonomyTile {
                            margin: 5px;
                            white-space: normal;
                            width: 310px !important;
                        }
                    }

                    .card {
                        width: 310px !important;

                        &.noMatch {
                            display: none !important;
                        }

                        &.active, &.slick-current {
                            opacity: 1.0 !important;
                        }

                        &.cardwithicon {
                            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
                            margin-right: 15px;

                            .content {
                                width: 310px;
                            }
                        }
                    }
                }

                .tileCounter {
                    display: block !important;
                    width: auto;
                    float: left;
                    padding-top: 20px;
                    position: relative;

                    .currentTile {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .MainColumnAccordionListComponent {
        .accords {
            a {
                font-weight: bold;
                color: $global_color_primaryBlue;
                text-decoration: none;

                &:hover {
                    background: $global_color_primaryBlue;
                    color: $global_color_white;
                }
            }
        }
    }

    .MainColumnTwoColumnComponent {
        &.colorBlock {
            .twitterTile {
                &.taxonomyTile {
                    max-width: 430px !important;
                }
            }
        }
    }

    #rightCol {
        h2 {
            border-bottom: none !important;
            border-bottom-color: transparent !important;
        }
    }
}

/* Major Events */
.MajorEvent {
    #skipToDiv {
        margin: auto;
        width: 100%;
        //padding: 0 20px;
    }
    .MainColumnXHTMLCopyComponent {
        &.colorBlock {
            .row {
                padding: 0 20px !important;

                p {
                    max-width: 850px;
                }
            }
        }
    }

    .CardContainerComponent {
        padding: 0 20px;

        .cardwithlargeicon {
            h2 {
                padding: 0 20px;
            }
        }
    }
}

.MajorEvent .ScrollSpyContainerComponent .servicesSection {
    margin-top: 0px !important;
}

.MajorEvent, .topTier, .CityWideDetail {
    #mainColMain {

        .ServicesSectionComponent {
            margin-bottom: 0;

            .servicesSection {

                margin-top: 0px !important;

                .container {
                    width: auto;
                    max-width: auto;
                }

                .container, .leftWrapper {
                    margin-bottom: 0;

                    .titleContainer {
                        width: 100%;
                        float: left;
                        margin-bottom: 0;
                        display:flex;
                        flex-direction: row;

                        svg, h2 {
                            float: left;                           
                        }

                        h2 {
                            border-bottom: none;
                            margin-top: 17px;
                        }

                        svg {
                            font-size: 35px;
                            margin: 16px 10px 0 0;

                            &.Green {
                                color: #007749;
                            }

                            &.Blue {
                                color: #0046AD;
                            }

                            &.Gold, &.Bronze {
                                color: $global_color_bronze;
                            }

                            &.Gray {
                                color: #565A5C;
                            }
                        }
                    }

                    .excerpt {
                        width: 100%;
                        float: left;
                        margin-bottom: 0;
                    }

                    .servicesWrapper {
                        margin-bottom: 0;

                        .tileCounter {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

/* Mobile swipe adjustments for Portal Services layout */
.PortalServices {    
    @media (max-width: 575px) {
        .mobile__swipe {
            width: 100% !important;
            min-width: 100% !important;
        }
        #mainColMain {
            padding-right: 0px;
        }
        .scrollSpyContainer .container {
            padding-right: 0px;
        }
        .ScrollSpyContainerComponent > .container:nth-of-type(2) {
            padding: 0px;
        }
    }
}