/*Super Old Phones
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
@media (max-width:320px){
    /*SLICKED*/
    #slickWilly{
        padding:5px 0px 0px 5px;
    }
    #slickWilly .container{
        padding:0px 0px !important;
    }
    #slickWilly .taxonomyTile{
        width:280px;
        height:135px;
    }
    #slickWilly .taxonomyTile.height2{
        height:280px;
    }
    #slickWilly .taxonomyTile .overlayBox{
        width:280px;
    }
    #featuredTileContainer #slickWilly .taxonomyTile h3,
    #slickWilly .taxonomyTile h3{
        font-size:19px;
        line-height:21px;
    }
    #slickWilly .taxonomyTile .photoCredit{
        width:255px;
        top:10px;
    }
    /*NOT SLICKED*/
    #nonSlickWilly .container{
        width:305px;
    }
}