/*Location Page Styles
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/

/*Location General Styles
______________________________________________________________________*/
.locationPage .featureWrapper,
.parkDetailPage .featureWrapper {
    position: relative;
}

.locationPage h1.pageTitle,
.parkDetailPage h1.pageTitle {
    position: absolute;
    bottom: 0;
    left: 0;
    background: $global_color_black;
    color: $global_color_white;
    display: inline-block;
    padding: 5px 20px 4px 20px;
    font-size: 2.3rem;
    line-height: 40px;
    margin-bottom: 0;
    margin-top: 0px;
    max-width: 95%;
}

.locationPage #featuredTileContainer + h1.pageTitle,
.parkDetailPage #featuredTileContainer + h1.pageTitle {
    position: relative;
}

.locationPage .col-md-6.amenities,
.locationPage .col-md-6.bodyText {
    max-width: 580px;
}


/*Location Hours & Contact Bar
______________________________________________________________________*/
#locationHoursAndContact,
#parkHoursAndContact {
    color: $global_color_black;
    font-weight: bold;
    border-bottom: 2px solid $global_color_black;
    padding: 15px 0;
}

div#locationHoursAndContact div,
div#parkHoursAndContact div {
    margin-bottom: 0px;
}

#locationHoursAndContact .svg-inline--fa,
#parkHoursAndContact .svg-inline--fa {
    font-size: 28px;
    float: left;
    margin-right: 10px;
    line-height: 20px;
}

    #locationHoursAndContact a,
    #locationHoursAndContact button,
    #locationHourPopover,
    .parkDetailPage #parkHoursAndContact a,
    .parkDetailPage #parkHoursAndContact button,
    #parkHourPopover {
        color: $global_color_black;
        text-decoration: none;
    }

    #locationHoursAndContact a:hover,
    #locationHoursAndContact button:hover,
    .parkDetailPage #parkHoursAndContact a:hover,
    .parkDetailPage #parkHoursAndContact button:hover {
        color: $global_color_primaryBlue;
        background-color: $global_color_transparent;
        background: none;
    }

    #locationHoursAndContact a:hover .fa,
    .parkDetailPage #parkHoursAndContact a:hover .fa {
        color: $global_color_primaryBlue;
    }
    /*Location Alerts
______________________________________________________________________*/
    .locationPage .alertTextContainer,
    .parkDetailPage .alertTextContainer {
        position: relative;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        text-align: center;
        padding: 20px 10px;
        background: rgba(225, 225, 225, .75);
        font-weight: bold;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
    }

    .locationPage .alertTextContainer .fa-exclamation-triangle,
    .parkDetailPage .alertTextContainer .fa-exclamation-triangle {
        color: $global_color_alert;
        font-size: 20px;
        margin-right: 5px;
    }
    /*Modal*/
    #pageAlertModalBanner,
    .locationPage #pageAlertModal {
    }

    #pageAlertModalBanner .modal-content,
    .locationPage #pageAlertModal .modal-content {
        border-radius: 0px;
    }

    #pageAlertModalBanner .modal-body,
    .locationPage #pageAlertModal .modal-body {
        padding: 25px;
        text-align: left;
        font-weight: initial;
    }
    /* Fontawesome v5 */
    /*#pageAlertModalBanner .fa-xmark,
.locationPage #pageAlertModal .fa-xmark {
    border:0px;
    height:40px;
    width:40px;
    font-size:24px;
    background: $global_color_mediumGray;
    color: $global_color_white;
    margin:-25px -25px 0px 0px;
}
*/
    /* Fontawesome v5 */
    #pageAlertModalBanner .fa-xmark:hover,
    .locationPage #pageAlertModal .fa-xmark:hover {
        background: $global_color_black;
    }

    #pageAlertModalBanner .modal-body a,
    .locationPage #pageAlertModal .modal-body a {
        font-weight: bold;
    }

    #pageAlertModalBanner .modal-body a:hover,
    .locationPage #pageAlertModal .modal-body a:hover {
        background: $global_color_primaryBlue;
        color: $global_color_white;
        text-decoration: none;
    }
    /*Location A to Z Lists*/
    .aToZ .paginationContainer {
        max-width: 950px;
    }

    .aToZ .pageNumberWrapper strong:after {
        content: ' results';
        display: inline;
    }

    .aToZ .typeDescriptor {
        display: none;
    }
    /*Contact Popover
______________________________________________________________________*/
    #parkHoursAndContact .contactTile .popover {
        margin-top: -10px;
        border-radius: 0px;
        padding: 0px;
        border-top: 8px solid $global_color_black;
    }

    #parkHoursAndContact .contactTile .popover-content {
        padding: 20px 10px;
    }

    #parkHoursAndContact .contactTile .closeParkContact {
        width: 40px;
        height: 40px;
        padding-left: 8px;
        margin-top: -10px;
        margin-right: -10px;
        margin-left: 15px;
    }

    #parkHoursAndContact .contactTile .contactName {
        padding-bottom: 10px;
    }
    /*Image Gallery
______________________________________________________________________*/
    .imageGallery .imageGalleryImage {
        height: 100px;
        width: 100px;
        overflow: hidden;
        border: 2px $global_color_white solid;
        padding: 0;
        margin: 0 10px 0 0;
        transition: all .1s;
    }

    .imageGallery .imageGalleryImage:hover {
        border: 5px $global_color_black solid;
    }

    .imageGallery .imageGalleryImage img {
        min-height: 100%;
        min-width: 100%;
        object-fit: cover;
    }
