/*Homepage
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
.homeElectedNavigationWrapper {
    background-color: $global_color_darkGray;
    padding: 20px 0 30px;
}

.homeElectedNavigationWrapper h2,
.homeElectedNavigationWrapper h3 a {
    color: $global_color_white;
}

.homeElectedNavigationWrapper h3 i {
    font-size: $global_fontSize_default;
    margin-left: 5px;
}


//2021 home page
.home2021 .cardTileSetContainer {
    padding-top: 25px;
    padding-bottom: 65px;
    background-color: #f1f1f1;

    .homePageFeature:not(:last-child) {
        margin-bottom: 60px;
    }

    hr {
        width: 50px;
        margin: 0px;
        background-color: #515151;
        height: 5px;
        border-width: 0px;
        text-align: left;
    }

    .tileSetHeader {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 5px;
        padding-top: 5px;
    }

    .grid {
        display: -ms-grid;
        -ms-grid-columns: 762px 10px 492px;
        -ms-grid-rows: 285px 10px 100px;
        display: grid;
        grid-template-columns: 762px 492px;
        grid-template-rows: 285px 100px;
        grid-gap: 1em 1em;
    }
    //hero tile ie fix
    .grid > *:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
    }
    //second tile ie fix
    .grid > *:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
    }
    //third tile ie fix
    .grid > *:nth-child(3) {
        -ms-grid-row: 3;
        -ms-grid-column: 3;
    }
}

.heroTileContainer {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    grid-column: 1;
    grid-row: 1 / 3
}

.secondaryTileContainer {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-column: 2;
    grid-row: 1 / 2;

    .grid-container {
        display: -ms-grid;
        -ms-grid-columns: 312px 180px;
        -ms-grid-rows: 285px;
        display: grid;
        grid-template-columns: 312px 180px;
        grid-template-rows: 285px;
        position: relative;

        .grid-item1 {
            -ms-grid-column: 1;
            grid-column: 1;
            padding: 20px;
            top: 15%;
            width: 312px;
            position: absolute;
        }
        //icon
        .grid-item2 {
            -ms-grid-column: 2;
            grid-column: 2;
            background-color: #F0AB00;
            text-align: center;
        }
    }
}

.thirdTileContainer {
    -ms-grid-column: 2;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-column: 2;
    grid-row: 2 / 3;
}

//hero tile
.heroTileContainer, .secondaryTileContainer, .thirdTileContainer {
    border: $global_color_lightWhite 1px solid;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
    background-color: #fff;
}

.herotile .parentContainer {
    height: 405px;
    width: 315px;
    float: left;
    position: relative;
}

.herotile .childContainer {
    width: 315px;
    padding: 20px;
    position: absolute;
    top: 15%;
}

.herotile .childContainer .gridInsideHeroTile {
    display: block;
}

.herotile .imageContainer {
    width: 440px;
    height: 405px;
    float: right;
}

.herotile .imageContainer img {
    width: 440px;
    height: 400px;
    object-fit: cover;
}

.herotile .heroTitle {
    padding-left: 15px;
    width: 285px;
}

.herotile .smallicon {
    display: block;
    float: left;
    color: white;
    background: #0046ad;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 10px;
    margin-right: 8px;
    font-size: 20px;
}
//hero and secondary tile

.herotile .heroTitle, .secondarytile .heroTitle {
    color: #333;
    font-weight: bold;
    font-size: 20px;
}

.herotile .heroExcerpt, .secondarytile .heroExcerpt {
    color: #333;
    margin-top: 20px;
}

.secondarytile, .herotile {
    .linkTitle {
        font-weight: bold;
        margin-top: 20px;
    }

    .linkTitle:hover {
        text-decoration: underline;
    }

    a:hover {
        text-decoration: none !important;
    }
}

//secondary tile
.secondarytile {
    height: 285px;
    width: 492px;
    padding: 0px
}

.secondarytile img {
    width: 185px;
    height: 285px;
}

.secondarytile .secondaryTileIcon svg {
    margin-top: 80px;
    font-size: 90px;
    color: white;
}

.secondarytile .linkTitle svg {
    margin-top: 0px;
    font-size: 20px;
}

.secondarytile .heroExcerpt svg {
    display: none;
}

.thirdtile .grid-container {
    height: 100px;
    width: 492px;
    padding: 20px;
    display: -ms-grid;
    -ms-grid-columns: 90% 1em 10%;
    -ms-grid-rows: 1fr 1em 1fr;
    display: grid;
    grid-template-columns: 90% 10%;
    grid-template-rows: 1fr 1fr;
    grid-gap: 1em 1em;
}
//title
.thirdtile .grid-container > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
//excerpt
.thirdtile .grid-container > *:nth-child(2) {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
}
//icon > icon fix
.thirdtile .grid-container > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    -ms-grid-row-span: 2;
}

.thirdTileTitle {
    font-size: 20px;
    font-weight: bold;
    grid-column: 1;
    grid-row: 1 / 2;
}

.thirdTileExcerpt {
    font-size: 20px;
    grid-column: 1;
    grid-row: 2 / 3;
    margin-top: -15px;
}

.thirdTileIcon {
    font-size: 32px;
    font-weight: bold;
    grid-column: 2;
    grid-row: 1 / 3;
    text-align: center;
    margin-top: 10px;
}

.cardTileSetContainer .thirdtile a {
    text-decoration: none;
    color: #333 !important;

    &:hover {
        text-decoration: underline
    }
}

.heroTileContainer:hover,
.secondaryTileContainer:hover,
.thirdTileContainer:hover {
    /*background: #F7F7F7;*/
    box-shadow: 2px 2px 5px 2px rgba(0,0,0,0.2);
}

.heroTileContainer,
.secondaryTileContainer,
.thirdTileContainer {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px
}

.thirdTileTitle svg {
    display: none;
}

@media all\0 {
    .thirdTileExcerpt {
        margin-top: 0px !important;
    }

    .home2021 .callToActionColorBlockWrapper {
        height: auto !important;
    }
}

// desktop style for new homepage
.home2021 {
    //rss cards inside tab
    .MainColumnTabListComponent .viewTabs .tabContent {
        padding: 0px !important;
    }

    .MainColumnTabListComponent .viewTabs .tabContent .rightWrapper {
        padding-left: 40px;
    }

    .feedContainer .grid-container {
        /*display: -ms-grid !important;
        -ms-grid-column: 310px 310px 310px;
        -ms-grid-gap: 10px;
        display: grid !important;
        grid-template-columns: 310px 310px 310px;
        grid-gap: 10px 10px;*/
        display: flex;
        flex-flow: row wrap;
        width: 990px;

        .feedcard {
            width: 310px !important;
            margin-right: 10px;
            margin-bottom: 10px;
            //height: 176px !important;

            .card-body {
                width: 310px !important;
                height: 140px !important; //140 current
            }

            .cardthumbnail {
                display: none;
            }
        }
    }

    .CardContainerComponent h2 {
        margin-left: 10px;
    }
    //elected official cards
    .card.cardwiththumbnail {
        width: 258px !important;
        margin-left: 30px;
        margin-right: 30px;
    }

    .card.cardwiththumbnail img {
        width: 155px !important;
        height: 155px !important;
        object-fit: contain;
        background-color: #515151;
    }
    //remove duplicate icon
    .cardContainer .externalLink .fa-file-arrow-down,
    .cardContainer .externalLink .fa-arrow-up-right-from-square {
        display: none;
    }

    .cardwithlink .btmleft {
        width: 90%;
    }

    .tab {
        &.tabsWrapper {
            padding-bottom: 80px;
        }
    }

    .MainColumnTabListComponent.colorBlock {
        padding-bottom: 75px !important;
    }

    .CardContainerComponent .cardContainerLink {
        margin-bottom: 55px;
    }

    .callToActionColorBlockWrapper .moreInformationLink {
        margin-bottom: 35px !important;
    }

    .card.cardwithlargeicon {
        height: 200px;
    }

    .cardContainerBodyCopy {
        display: none;
    }
}

//hide counter
.home2021 .MainColumnTabListComponent .tabContent .tileCounter {
    font-size: 0px;
    padding: 0px !important;
}

.home2021 .tabsContainer .tabContent {
    min-height: 250px !important;
}

//small desktops
@media (min-width: 992px) and (max-width: 1291px) {
    //main grid for the card tileset
    .home2021 .cardTileSetContainer .grid {
        display: -ms-grid;
        -ms-grid-columns: 550px 10px 387px;
        -ms-grid-rows: 300px 10px 90px;
        display: grid;
        grid-template-columns: 550px 387px;
        grid-template-rows: 300px 90px;
        grid-gap: 1em 1em;
    }
    //hero tile ie fix
    .grid > *:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
    }
    //second tile ie fix
    .grid > *:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
    }
    //third tile ie fix
    .grid > *:nth-child(3) {
        -ms-grid-row: 3;
        -ms-grid-column: 3;
    }
    //hero tile
    .heroTileContainer {
        grid-column: 1;
        grid-row: 1 / 3;

        .parentContainer {
            height: 405px;
            width: 285px !important;
            float: left
        }

        .childContainer {
            padding: 20px;
            width: 285px !important;
        }

        .herotile .imageContainer {
            width: 260px !important;
            height: 405px;
            float: right;
        }

        .herotile .imageContainer img {
            width: 260px !important;
            height: 404px;
            object-fit: cover;
        }

        .herotile .heroTitle{
            width: 260px !important;
        }
    }
    //secondary tile with icon
    .secondaryTileContainer {
        grid-column: 2;
        grid-row: 1 / 2;

        .grid-container {
            display: -ms-grid;
            -ms-grid-columns: 252px 135px !important;
            -ms-grid-rows: 300px !important;
            display: grid;
            grid-template-columns: 252px 135px !important;
            grid-template-rows: 300px !important;

            .grid-item1 {
                width: 252px;
            }
        }

        .secondarytile {
            height: 300px !important;
            width: 387px !important;
        }

        .secondarytile img {
            height: 300px !important;
            width: 135px !important;
        }
    }
    //third tile
    .thirdTileExcerpt {
        display: none;
    }

    .thirdTileTitle svg {
        display: block;
    }

    .thirdtile .grid-container {
        height: 90px;
        width: 387px !important;
        padding: 20px;
        grid-template-columns: 90% 10% !important;
        grid-template-rows: 1fr;

        .thirdTileTitle {
            grid-row: 1/3 !important;
        }
    }
    //rss cards inside tab - small desktop
    .home2021 .feedContainer .grid-container {
        /*grid-template-columns: 310px 310px !important;*/
        width: 660px !important;
    }
}

//tablet
@media (min-width: 768px) and (max-width: 991px) {


    .home2021 .cardTileSetContainer .grid {
        display: -ms-grid;
        -ms-grid-columns: 335px 10px 387px;
        -ms-grid-crows: 300px 10px 90px;
        display: grid;
        grid-template-columns: 335px 387px;
        grid-template-rows: 300px 90px;
        grid-gap: 1em 1em;
    }

    //hero tile ie fix
    .grid > *:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
    }
    //second tile ie fix
    .grid > *:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
    }
    //third tile ie fix
    .grid > *:nth-child(3) {
        -ms-grid-row: 3;
        -ms-grid-column: 3;
    }

    //hero tile
    .heroTileContainer {
        grid-column: 1 !important;
        grid-row: 1/3 !important;
    }


    //inside the herotile - image on top text in the bottom
    .heroTileCard {
        display: -ms-grid;
        -ms-grid-columns: 335px !important;
        -ms-grid-rows: 205px 200px !important;
        display: grid;
        grid-template-columns: 335px !important;
        grid-template-rows: 205px 200px !important;

        .parentContainer {
            -ms-grid-row: 2;
            -ms-grid-column: 1;
            grid-column: 1 !important;
            grid-row: 2/3 !important;
            height: 205px !important;
            width: 335px !important;
        }

        .imageContainer {
            -ms-grid-row: 1;
            -ms-grid-column: 1;
            grid-column: 1 !important;
            grid-row: 1/2 !important;
            width: 335px !important;
            height: 200px !important;
        }

        .imageContainer img {
            width: 335px !important;
            height: 200px !important;
        }
    }

    .herotile .childContainer {
        top: 0%;
        width: 335px;
    }

    .herotile .heroTitle{
        width: 295px;
    }

    //*************secondary tile with icon*************

    .secondaryTileContainer {

        .grid-container {
            display: -ms-grid;
            -ms-grid-columns: 252px 135px;
            -ms-grid-rows: 285px;
            display: grid;
            grid-template-columns: 252px 135px;
            grid-template-rows: 300px;


            .grid-item1 {
                -ms-grid-column: 1;
                grid-column: 1;
                padding: 20px;
                width: 252px;
            }
            //icon
            .grid-item2 {
                -ms-grid-column: 2;
                grid-column: 2;
                background-color: #F0AB00;
                text-align: center;
            }
        }
    }

    secondaryTileCard {
        height: 300px !important;
        width: 387px;
        padding: 0px
    }
    //secondary tile
    .secondaryTileIcon {
        display: block;
    }

    .secondarytile img {
        height: 300px;
    }

    .secondarytile svg {
        margin-top: 80px;
        font-size: 90px;
        color: white;
    }

    //third tile
    .thirdTileExcerpt {
        display: none;
    }

    .thirdTileTitle svg {
        display: block;
    }
    //thirdtile - hide the excerpt
    .thirdtile .grid-container {
        height: 90px;
        width: 387px !important;
        padding: 20px;
        display: -ms-grid;
        -ms-grid-columns: 85% 20px 15%;
        -ms-grid-rows: 1fr;
        grid-template-columns: 85% 15% !important;
        grid-gap: 20px;
        grid-template-rows: 1fr;

        .thirdTileTitle {
            grid-row: 1/3 !important;
            -ms-grid-row: 1;
        }

        svg {
            -ms-grid-column: 3;
        }
    }

    //rss cards inside tab - tablet
    .home2021 .feedContainer .grid-container {
        /*grid-template-columns: 310px 310px !important;*/
        width: 660px !important;
    }

    .home2021 .cardwithlargeicon {
        height: 200px !important;
    }

    //right sidebar fix
    .home2021 .leftWrapper.padRight {
        float: left;
        margin-bottom: 40px;
        width: 100%;
    }
}


//mobile
@media (max-width: 767px) {

    //display only 3 cards for presentation type cardwithlargeicon
    .home2021 .MainColumnTabListComponent .feedcard {
        display: none;
    }

    .home2021 .MainColumnTabListComponent .feedcard:nth-last-child(-n+3) {
        display: block;
    }

    //hero tile on top
    //secondary tile middle
    //third tile bottom
    .cardTileSetContainer .grid {
        display: -ms-grid;
        -ms-grid-columns: 335px !important;
        -ms-grid-rows: 455px 10px 180px 10px 100px !important;
        display: grid;
        grid-template-columns: 335px !important;
        grid-template-rows: 455px 180px 100px !important;
    }

    .heroTileContainer {
        -ms-grid-column: 1 !important;
        -ms-grid-row: 1 !important;
        -ms-grid-row-span: 1;
        grid-column: 1 !important;
        grid-row: 1/2 !important;
    }

    .secondaryTileContainer {
        -ms-grid-column: 1 !important;
        -ms-grid-row: 3 !important;
        -ms-grid-row-span: 1;
        grid-column: 1 !important;
        grid-row: 2/3 !important;

        .grid-container {
            .grid-item1 {
                top: 0%;
                position: relative;
            }
        }
    }

    .thirdTileContainer {
        -ms-grid-column: 1 !important;
        -ms-grid-row: 5 !important;
        -ms-grid-row-span: 1;
        grid-column: 1 !important;
        grid-row: 3/4 !important;
    }

    //hero tile
    .herotile .imageContainer {
        width: 335px !important;
        height: 200px !important;
    }

    .herotile .parentContainer {
        height: 250px !important;
        width: 335px !important;
    }

    .herotile .childContainer {
        top: 0px;
        width: 335px;
    }

    .herotile .heroTitle {
        width: 295px;
    }

    //inside the herotile - image on top text in the bottom
    .heroTileCard {
        display: -ms-grid;
        -ms-grid-rows: 205px 250px !important;
        -ms-grid-columns: 335px !important;
        display: grid;
        grid-template-columns: 335px !important;
        grid-template-rows: 205px 250px !important;

        .parentContainer {
            -ms-grid-column: 1 !important;
            -ms-grid-row: 2 !important;
            grid-column: 1 !important;
            grid-row: 2/3 !important;
        }

        .imageContainer {
            -ms-grid-column: 1;
            -ms-grid-row: 1;
            grid-column: 1 !important;
            grid-row: 1/2 !important;
        }

        .imageContainer img {
            width: 335px !important;
            height: 200px !important;
        }
    }

    .secondaryTileContainer .grid-container {
        -ms-grid-columns: 335px;
        -ms-grid-rows: 180px;
        grid-template-columns: 335px;
        grid-template-rows: 180px;
    }

    //*************secondary tile without icon*************
    .secondarytile {
        height: 180px !important;
        width: 335px;
        padding: 0px
    }
    //secondary tile - hide the icon
    .secondarytile .linkTitle, .secondaryTileIcon {
        display: none;
    }

    .secondarytile .heroExcerpt svg {
        display: inline-block;
    }

    //**********third tile*************
    .thirdTileExcerpt {
        display: none;
    }

    .thirdTileTitle svg {
        display: inline-block;
    }
    //thirdtile - hide the excerpt
    .thirdtile .grid-container {
        height: 96px;
        width: 335px !important;
        padding: 20px;
        grid-template-columns: 85% 15% !important;
        grid-template-rows: 1fr;

        .thirdTileTitle {
            grid-row: 1/3 !important;
        }
    }



    .home2021 .card.cardwiththumbnail {
        width: 167px !important;
        height: 280px !important;
        padding: 5px !important;
        margin-left: auto;
        margin-right: auto;

        .cardTitle {
            width: 167px;
        }
    }

    .home2021 .card.cardwithlargeiconexcerpt {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .home2021 .viewTabs.container {
        padding-left: 10px !important;
        margin-left: 0px !important;
    }

    .home2021 .tabContent .leftWrapper .servicesWrapper {
        margin-bottom: 35px !important;
    }

    //rss cards inside tab - mobile
    .home2021 .feedContainer .grid-container {
        width: 330px !important;
    }

    .home2021 .MainColumnTabListComponent.colorBlock {
        width: 95%;
    }

    .home2021 .cardTileSetContainer .container {
        display: grid;
        justify-content: center;
    }

    .home2021 .cardTileSetContainer .container h2 {
        width: 335px;
    }

    .home2021 .cardwithlargeicon .row {
        display: grid;
        justify-content: center;
    }

    .home2021 .cardwithlargeicon .row h2 {
        width: 335px;
    }

    .home2021 .cardwithlargeiconexcerpt .row,
    .home2021 .cardwiththumbnail .row {
        justify-content: center;
        display: grid;
        grid-template-columns: 167px 167px;
    }

    .home2021 .cardwithlargeiconexcerpt .row::before {
        display: none;
    }

    .home2021 .cardwithlargeiconexcerpt .row h2,
    .home2021 .cardwiththumbnail .row h2 {
        width: 335px;
        grid-column: span 2;
    }
}

@media (max-width: 335px) {
    .home2021 .card.cardwiththumbnail, .home2021 .card.cardwithlargeiconexcerpt {
        margin-left: auto;
        margin-right: auto;
        float: none;
    }
}
// ***** end of mobile view *****
