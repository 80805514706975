/* SUPER GLOBAL STYLES
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
body {
    font-family: $global_fontFamily_body;
    overflow-x: hidden;
    font-size: $global_fontSize_default;
}
h1, h2, h3, h4, h5 {
    font-family: $global_fontFamily_deptTitle;
    font-weight: bold;
    color: $global_color_black;
    
    /*adjusting line-height and padding for Open Sans font descender letters*/
    line-height: 1.2;
    padding-bottom: 0.1em;
}
a, .btn-link {
    /*color: $global_color_title;*/
    color: $global_color_darkBlue;
}       
p {
    line-height: 22px;
}
html {
    font-size: $global_fontSize_default;
}