//START: styles for banners

.container.banner {
    margin-top: 30px;
}

.bannerHomeFullImage {
    height: 250px;
}

.bannerHomeHalfImage {
    height: 250px;
}

.bannerHomeNoImage {
    height: 250px;
}

.bannerDarkGrayTransparent .bannerTextWithButton svg path {
    fill: rgba(71, 71, 71, 1) !important;
}

.bannerDarkGray .bannerTextWithButton svg path {
    fill: rgba(71, 71, 71, 1) !important;
}

.bannerLightGrayTransparent .bannerTextWithButton svg path {
    fill: rgba(241, 241, 241, 1) !important;
}

.bannerLightGray .bannerTextWithButton svg path {
    fill: rgba(241, 241, 241, 1) !important;
}

.bannerLightBlueTransparent .bannerTextWithButton svg path {
    fill: rgba(225, 245, 254, 1) !important;
}

.bannerLightBlue .bannerTextWithButton svg path {
    fill: rgba(225, 245, 254, 1) !important;
}

.bannerLightGrayTransparent .bannerTextWithButton,
.bannerLightGray .bannerTextWithButton,
.bannerLightBlueTransparent .bannerTextWithButton,
.bannerLightBlue .bannerTextWithButton {
    color: $global_color_darkGray;

    .btn {
        border-radius: 2px;
        border: none;
        height: 40px;
        min-height: 40px !important;
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
        border: solid $global_color_primaryBlue 1px;
    }

    .btn:hover {
        background: rgb(51,51,51);
        color: #f1f1f1;
        border: solid rgb(51,51,51) 1px;
    }

    .fa-arrow-up-right-from-square,
    .fa-file-arrow-down {
        font-size: 20px;
        margin-left: 10px;
    }
}


.bannerTextWithButton {
    width: 600px;
    height: 250px;
    float: left;
    display: block;
    padding-left: 0px; /*from 20px nflores*/
    padding-bottom: 20px;

    .bannertextcontent {
        height: 170px;
        /*overflow: hidden;*/
        padding-right: 20px;
    }
}

.bannerHomeHalfImage .bannerTextWithButton {
    padding-left: 0px; /*from 20px nflores*/
}
//***********portal banner - start
.portalBanner {
    height: 250px;
    width: 100%;

    .bannerImage {
        width: 50%;
        display: block;
        float: left;
        height: 250px;
        overflow: hidden;
    }
}

@media (max-width: 767px) {
    .portalBanner {
        height: 100%;

        .bannerImage {
            height: 0;

            img {
                height: 0;
            }
        }
    }
}

.bannerDarkGray {
    background-color: rgba(71,71,71,1);
    color: $global_color_white;

    h1 {
        color: $global_color_white;
        margin-bottom: 20px;
        margin-top: 0px;
    }
}

.bannerLightGray {
    background-color: rgba(241,241,241, 1);
    color: $global_color_darkGray;

    h1 {
        color: $global_color_darkGray;
        margin-bottom: 20px;
        margin-top: 0px;
    }
}

.bannerLightBlue {
    background-color: rgba(225,245,254,1);
    color: $global_color_darkGray;

    h1 {
        color: $global_color_darkGray;
        margin-bottom: 20px;
        margin-top: 0px;
    }
}

.leftsidebanner {
    float: left;
    display: block;
    width: 50%;
}

.centerVertically {
    vertical-align: middle;
    display: table-cell;
    height: 240px;
}

.centerVertically.withImage {
    width: 50%;
    padding-right: 20px;
}

.centerVertically.noImage {
    width: 770px;
}


// *************portal banner - end

.bannerDarkGrayTransparent .bannerTextWithButton,
.bannerDarkGray .bannerTextWithButton {
    color: $global_color_white;

    h2 {
        color: $global_color_white;
    }

    .btn {
        border-radius: 2px;
        border: none;
        height: 40px;
        font-weight: bold;
        font-size: 16px;
        background: #FFFFFF;
        color: #515151;
        margin-top: 20px;
        padding-top: 9px;
        border: solid #FFFFFF 1px;
    }

    .btn:hover {
        background: rgb(51,51,51);
        color: #f1f1f1;
        border: solid #FFFFFF 1px;
        path {
            fill: rgba(241, 241, 241, 1) !important;
        }
    }

    .fa-arrow-up-right-from-square,
    .fa-file-arrow-down {
        font-size: 20px;
        margin-left: 10px;
    }
}

.bannerHomeHalfImage {
    .bannerHalfImage {
        background-position: center center;
        background-size: cover;
        height: 250px;
        float: left;
        display: block;
        width: 730px;
        position: absolute;
        margin-left: 545px; /*from 565px nflores*/
    }

    .bannerTextWithButton svg {
        position: absolute;
        z-index: 2;
    }

    .bannerTextWithButton {
        position: relative;
    }

    .bannertextcontent {
        position: absolute;
        z-index: 3;
        margin-top: 0px !important;
    }

    .bannerTextWithButton .btn {
        position: absolute;
        z-index: 3;
        margin-top: 190px;
    }
}

.bannerImage {
    .img-responsive {
        height: 250px;
        width: 100%;
        /*max-width: 635px;*/
    }
}

.aligncenter {
    vertical-align: middle;
    display: table-cell;
    height: 180px;
}

@media (min-width:768px) {
    //home page banner
    .bannerHomeFullImage .bannerTextWithButton .bannertextcontent,
    .bannerHomeHalfImage .bannerTextWithButton .bannertextcontent {
        margin-top: -250px;
        float: left;
        display: block;
    }

    .bannerHomeNoImage .bannerTextWithButton .bannertextcontent {
        margin-top: 0px;
        float: left;
        display: block;
        margin-bottom: 20px;
    }

    .bannerHomeFullImage .bannerTextWithButton .btn {
        margin-top: -95px !important;
        padding-top: 8px;
    }

    .bannerHomeNoImage .bannerTextWithButton .btn {
        margin-top: 0px !important;
    }

    .bannerTextWithButton svg {
        float: left;
    }

    .bannerTextWithButton {
        background-color: none !important;
    }

    .bannerLightBlue .bannerTextWithButton {
        background-color: none !important;
    }

    .bannerHomeHalfImage .bannerTextWithButton .btn:has(.fa-arrow-up-right-from-square, .fa-file-arrow-down) {
        padding: 9px 35px 0px 9px;
    }
}


@media (max-width:767px) {

    .leftsidebanner {
        width: 100%;
    }

    .bannerTextWithButton svg {
        display: none;
    }

    .bannerLightBlue .bannerTextWithButton,
    .bannerLightBlueTransparent .bannerTextWithButton {
        background-color: rgba(225,245,254,1);
    }

    .bannerLightGray .bannerTextWithButton,
    .bannerLightGrayTransparent .bannerTextWithButton {
        background-color: rgba(241,241,241, 1);
    }

    .bannerDarkGray .bannerTextWithButton,
    .bannerDarkGrayTransparent .bannerTextWithButton {
        background-color: rgba(71,71,71, 1);
    }

    .bannerTextWithButton {
        width: 100%;
        height: auto !important;
        min-height: 250px;

        .bannertextcontent {
            overflow: visible !important;
            height: auto !important;
            min-height: 170px;
        }
    }

    .portalBanner .bannertextcontent {
        width: 100% !important;
    }

    .portalBanner .bannerImage {
        width: 0% !important;
    }

    .centerVertically {
        height: auto !important;
        padding-top: 20px;
        padding-bottom: 20px;
        overflow: visible;
        min-height: 250px;
    }
}

@media (min-width:768px) and (max-width:991px) {

    .department .featureWrapper.bannerHomeFullImage {
        margin-bottom: 0px;
    }

    .portalBanner .withImage {
        .bannerTitle {
            max-height: 90px;
            display: inline;
            /*overflow: hidden;*/
        }

        .bannerExcerpt {
            max-height: 115px;
            /*overflow: hidden;*/
        }
    }
    .bannerImage {
        .img-responsive {
            height: 250px;
            width: auto;
            object-fit:cover;
            /*max-width: 635px;*/
        }
    }
}

@media (max-width: 410px) {

    .bannerTextWithButton svg {
        display: none;
    }

    //home page banner
    .bannerHomeFullImage,
    .bannerHomeHalfImage {
        height: auto !important;
    }

    .bannerTextWithButton {
        width: 100%;
        height: auto !important;
    }

    //portal banner
    .portalBanner {
        height: auto !important;
    }

    .centerVertically {
        height: auto !important;
        padding-top: 20px;
        padding-bottom: 20px;
        overflow: visible;
    }

    .centerVertically.withImage {
        padding-right: 10px;
    }

    //both
    .bannertextcontent {
        width: auto !important;
    }
}

@media (min-width:992px) and (max-width:1291px) {

    .bannerHomeHalfImage .bannerHalfImage {
        width: 405px;
    }
    .bannerImage {
        .img-responsive {
            height: 250px;
            width: auto;
            max-width: 635px;
        }
    }
}

@media (max-width:991px) {

    .bannerHomeHalfImage .bannerHalfImage {
        margin: 0px !important;
        position: relative;
        background-image: none !important;
        height: auto;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .bannerHomeHalfImage {
        .bannerTextWithButton {
            .bannertextcontent {
                position: relative;
            }
            .btn{
                position: relative;
                margin-top: 20px;
            }
        }
    }
}
//***********department banner - start
.departmentBanner {
    width: 100%;
    height: 250px;
    position: relative;

    &.image {
        .wrap {
            .container {
                .rightOverLay {
                    display: block;
                    height: 100%;
                    width: 100%;
                    background-color: $global_color_white;
                    position: absolute;
                    right: 0;
                }
            }
        }
    }

    .wrap {
        height: 120px;
        width: 100%;
        position: relative;
        top: 50% !important;
        transform: translateY(-50%);

        .container {
            height: 100%;
    
            .bannerTitle {
                position: relative;
                margin-bottom: 0;
                top: 50% !important;
                transform: translateY(-50%);
                margin-top: 4px;
                float: left;
                z-index: 10;
            }

            .bannerImage {
                margin-top: -65px;
                z-index: 10;
        
                img {
                    width: 250px;
                    height: 250px; 
                    border-radius: 50%;
                }
            }

            .rightOverLay {
                display: none;
            }
        }
    }

    @media (max-width: 991px) {
        &.image {
            .wrap {
                .container {
                    .rightOverLay {
                        &.ipad {
                            display: none;
                        }
                    }
                }
            }
        }
    }
 
    @media (max-width: 767px) {
        .wrap {
            .container {
                .bannerImage, .rightOverLay {
                    display: none !important; 
                } 
            }
        }
    }
} 