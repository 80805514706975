/*Responsive2015.scss*/
@import "partials/global-variables";
@import "partials/responsive2015-toc";
@import "partials/responsive2015-extendBootstrap";
@import "partials/responsive2015-global";
@import "partials/responsive2015-tiles";
@import "partials/responsive2015-globalNav";
@import "partials/responsive2015-portalPages";
@import "partials/responsive2015-homePage";
@import "partials/responsive2015-topicPages";
@import "partials/responsive2015-body";
@import "partials/responsive2015-departmentPages";
@import "partials/responsive2015-locationPages";
@import "partials/responsive2015-components";
@import "partials/responsive2015-pagination";
@import "partials/responsive2015-colorBlocks";
@import "partials/responsive2015-searchResults";
@import "partials/responsive2015-unsorted";
@import "partials/responsive2015-mobileTablet";
@import "partials/responsive2015-legacyPhones";
@import "partials/responsive2015-desktops";
@import "partials/responsive2015-macFixes";
@import "partials/responsive2015-campaignPage";
@import "partials/responsive2015-card";
@import "partials/responsive2015-banner";
@import "partials/responsive2015-links";
@import "partials/responsive2015-programPage";
/*@import "partials/responsive2015-restivejs.scss";*/
