/*Body Styles - General
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
 

/*Lightboxes
______________________________________________________________________*/
.ekko-lightbox .modal-content{
    background: $global_color_black;
    border:0px;
    border-radius:0px;
    padding:10px;
}
.ekko-lightbox .modal-header {
    border:0px;
    padding-bottom:0px;
}
.ekko-lightbox .modal-header h4{
    color: $global_color_white;
    font-family: $global_fontFamily_default;
}
.ekko-lightbox .modal-header .close{
    color: $global_color_white;
    opacity:1;
    font-size:40px;
    margin-top:-10px;
}
.ekko-lightbox .glyphicon-chevron-left,
.ekko-lightbox .glyphicon-chevron-right {
    opacity:.7;
    font-size:40px;
    padding-top:0px;
    text-decoration: none;
    transition:all .2s;
}

.ekko-lightbox .glyphicon-chevron-left:hover,
.ekko-lightbox .glyphicon-chevron-right:hover {
    font-size:70px;
    background:transparent;
}
.ekko-lightbox .glyphicon-chevron-left:hover{
    margin-left:-20px;
}
.ekko-lightbox .glyphicon-chevron-right:hover{
    margin-right:-20px;
}



/*Body Styles - Main Content
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
#mainContent .bodycopy,
.mainColWrapper{
    padding-bottom:60px;
}

/*Headings
______________________________________________________________________*/
h1.pageTitle { 
    font-size:2.375rem;
    margin-top:20px;
    line-height:1.2; 
    font-family: $global_fontFamily_deptTitle;
}
#portalpage h1.pageTitle,
#mainColMain h1.pageTitle {
    color: $global_color_black;
    display: inline-block;
    line-height: 40px;
    margin-top: -40px;
} 
#portalpage h1.pageTitle{
    margin-top:25px;
    margin-bottom: 40px;
}

/*Readability Limiter
______________________________________________________________________*/
.mainColWrapper span,
.mainColWrapper p,
.mainColWrapper ol,
.mainColWrapper ul,
.mainColWrapper h1,
.mainColWrapper h2,
.mainColWrapper h3,
.mainColWrapper h4,
.topTier .bodycopy h2,
.topTier .bodycopy h3,
.topTier .bodycopy h4,
.topTier .bodycopy p,
.topTier .bodycopy span,
#centerColumn #trumbacal,
#mainColMain.col-md-8 table,
#mainColMain.col-md-8 .MapComponent,
#mainColMain.col-md-8 .TrumbaCalendarComponent,
#mainColMain.col-md-8 .form-group,
#mainColMain .VideoComponent,
#mainColMain .col-md-8  div.well,
#mainColMain .col-md-8 table,
#mainColMain .col-md-8 hr,
.ui-accordion-content,
.feedContainer,
#mainColMain h1,
#mainColMain h2,
#mainColMain h3,
#mainColMain h4,
#mainColMain h5,
#mainColMain h6,
#mainColMain hr,
#mainColMain a,
#mainColMain span,
#mainColMain p,
#mainColMain ul,
#mainColMain ol,
#mainColMain dl,
#mainColMain dt,
#mainColMain dd,
#mainColMain .jumbotron,
#mainColMain .panel,
#mainColMain .lead,
#mainColMain .Excerpt, 
#mainColMain .titleExcerptText {
    max-width: 820px; 
}

#mainColMain h2.accordion-toggle {
    max-width: 100%;
    border-bottom: 1px solid $global_color_lightWhite;
}

#mainColMain {
    h2, h3 {
        overflow: visible;
    }
}

/*Links
______________________________________________________________________*/

/*.bodycopy a,
#mainColMain a, #leftMainRight a, button.btn-link {
    font-weight: bold;
}*/

button.btn-link {
    font-weight: bold;
}
 
#mainColContent, .mainColWrapper, .colorBlock .container,
    .bodyTileWrapper .departmentAgency h3 {
    a.externalLink {

        svg {
            margin-left: 5px;

            &.fa-arrow-up-right-from-square {
                font-size: 18px !important;
            }
        }
    }

    li {
        a.externalLink {
            svg {
                margin-left: 5px;
            }
        }
    } 
}

#mainColContent {
    #mainColMain, #rightCol {
        a:not(.btn):not(.tab) { 
            padding: 1px 0;
        }
    }
}

.notificationContainer .seagovBanner {
    a.externalLink {
        svg {
            margin-left: 5px;
            float: none;
            font-size: 13px;
        }
    }
}

.bodycopy, .MainColumnInsertComponent {
    a.externalLink {
        svg {
            margin-left: 4px;
        }
    }
} 
 
#mainColMain a:hover, button.btn-link:hover {
    background: $global_color_primaryBlue;
    color: $global_color_white;
    text-decoration: none;
}

#mainColMain .span a:visited,
#mainColMain .MainColumnXHTMLCopyComponent a:visited,
.SidebarXHTMLCopyComponent a:visited,
.MainColumnLinkListComponent a:visited,
.SidebarLinkListComponent a:visited {
    color: $global_color_darkModerateViolet;
}

#mainColMain .btn:visited,
.SidebarXHTMLCopyComponent .btn:visited,
.MainColumnXHTMLCopyComponent .btn:visited {
    color: $global_color_white !important;
}

#mainColMain a:hover,
#mainColMain .span a:hover,
#mainColMain .MainColumnXHTMLCopyComponent a:hover,
.MainColumnLinkListComponent a:hover,
.SidebarXHTMLCopyComponent a:hover,
.SidebarLinkListComponent a:hover {
    background: #003da5;
    color: $global_color_white;
    text-decoration: none;
}

/*yellow buttons*/
#mainColMain .btn-warning:visited,
.SidebarXHTMLCopyComponent .btn-warning:visited,
.MainColumnXHTMLCopyComponent .btn-warning:visited {
    color: $global_color_black !important;
}

#mainColMain .btn-warning:hover,
.SidebarXHTMLCopyComponent .btn-warning:hover,
.MainColumnXHTMLCopyComponent .btn-warning:hover {
    color: $global_color_white !important;
}
/*Paragraphs
______________________________________________________________________*/
#mainColMain div,
#mainColMain p {
    margin-bottom: 30px;
}
#mainColMain div#map div {
    margin-bottom:auto;
}
#mainColContent p,
#mainColMain p,
#mainContent p {
    line-height: 1.5em;
}

/*HRs
______________________________________________________________________*/
#mainColContent hr,
#mainColMain hr{
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    border: 0;
    border-top: 1px solid $global_color_lightGray;
    text-align: left;
}

/*Tables
______________________________________________________________________*/
/*Captions*/
#mainColMain table caption {
    color: $global_color_black;
    font-family: $global_fontFamily_deptTitle;
    font-weight: bold;
    border-bottom: 1px solid $global_color_lightGray;
    font-size: 28px;
    margin-bottom: 10px;
    margin-top: 20px;
    padding:0px;
    max-width:580px;
}

/*Unordered Lists
______________________________________________________________________*/
#mainColMain ul {
    margin:0px 0px 0px 40px;
    padding:0px 0px 20px 0px;
}
#mainColMain ul li,
.colorBlock.MainColumnTwoColumnComponent ul li {
    margin-bottom: 20px;
}
#mainColMain ul ul:last-child {
    padding-bottom: 0;
}
#mainColMain ul li:last-child {
    margin-bottom: 0;
}
/*Nested List Styles*/
#mainColContent ul > li,
#mainColMain ul > li,
.colorBlock.MainColumnTwoColumnComponent ul > li{
    list-style: square;
}
#mainColContent ul > li,
#mainColMain ul > li, .colorBlock ul > li {
    padding: 1px 0;
}
#mainColContent ul>li>ul>li,
#mainColMain ul>li>ul>li{
    list-style:circle;
}
#mainColContent ul>li>ul>li>ul>li,
#mainColMain ul>li>ul>li>ul>li{
    list-style:disc;
}
/*Children Element Margins*/
#mainColContent .well *+ul,
#mainColMain .well *+ul{
    margin-top:10px;
}
#mainColContent .well ul,
#mainColMain .well ul{
    margin-left:30px;
}
#mainColContent h2+ul,
#mainColMain h2+ul,
#mainColContent h3+ul,
#mainColMain h3+ul,
#mainColContent h4+ul,
#mainColMain h4+ul,
#mainColContent h5+ul,
#mainColMain h5+ul{
    margin-top:30px; 
}
#mainColContent strong+ul,
#mainColMain strong+ul{
    margin-top:20px;
}
/*Hidden List Styles*/
#mainColContent ul.jumpLinks>li,
#mainColMain ul.jumpLinks>li,
#mainColContent ul.list-unstyled>li,
#mainColMain ul.list-unstyled>li,
#mainColContent .tabs li,
#mainColMain .tabs li{
    list-style:none;
}
/*Ordered Lists Basic
______________________________________________________________________*/
#centerColumn ol,
#mainColMain ol {
    /* counter-reset: li;  NFLORES - Bug 1583 might need to remove */
    margin: 0;
    padding: 0 0 0 20px;
}
#centerColumn ol>li,
#mainColMain ol>li{
    margin: 10px 0;
    position: relative; 
}
#centerColumn ol>li::marker,
#mainColMain ol>li::marker {
    font-weight: bold;
}

/*Ordered Lists Extended
______________________________________________________________________*/
#centerColumn ul>li>ol,
#centerColumn ol>li>ol,
#mainColContent ul>li>ol,
#mainColContent ol>li>ol,
#mainColMain ul>li>ol,
#mainColMain ol>li>ol{
    margin: 10px 0; 
}
#centerColumn ol>li>ol>li,
#mainColContent ol>li>ol>li,
#mainColMain ol>li>ol>li {
    margin: 0;
    padding: 0 0 0 20px;
}
#centerColumn ol > li > ol > li:before,
#mainColContent ol > li > ol > li:before,
#mainColMain ol > li > ol > li:before {
    /*content: counter(li, lower-latin) '. '; NFLORES - Bug 1583 */
}
#centerColumn ol>li>ul,
#centerColumn ol>li>ul>li>ul>li,
#centerColumn ol>li>ol>li>ul,
#mainColContent ol>li>ul,
#mainColContent ol>li>ul>li>ul>li,
#mainColContent ol>li>ol>li>ul,
#mainColMain ol>li>ul,
#mainColMain ol>li>ul>li>ul>li,
#mainColMain ol>li>ol>li>ul{
    margin: 0px 0px 0px 40px;
    padding: 20px 0px;
}
#centerColumn ol>li>ol>li>ol,
#mainColContent ol>li>ol>li>ol,
#mainColMain ol>li>ol>li>ol {
    margin: 10px 0;
}
#centerColumn ol>li>ol>li>ol>li:before,
#mainColContent ol>li>ol>li>ol>li:before,
#mainColMain ol>li>ol>li>ol>li:before {
    content: counter(li, lower-roman) '. '; 
}
#centerColumn ol>li>ol>li>ol>li>ol,
#mainColContent ol>li>ol>li>ol>li>ol,
#mainColMain ol>li>ol>li>ol>li>ol {
    margin: 7px 0;
}
#centerColumn ol>li>ol>li>ol>li>ol>li:before,
#mainColContent ol>li>ol>li>ol>li>ol>li:before,
#mainColMain ol>li>ol>li>ol>li>ol>li:before {
    content: counter(li) '. '; 
}
#centerColumn ol>li>ol>li>ol>li>ol>li>ol>li:before,
#mainColContent ol>li>ol>li>ol>li>ol>li>ol>li:before,
#mainColMain ol>li>ol>li>ol>li>ol>li>ol>li:before {
    content: counter(li, lower-latin) '. '; 
}

.SidebarXHTMLCopyComponent ul li a,
ul.navlist li a,
.SidebarXHTMLCopyComponent ul li,
ul.navlist li {
    padding-bottom: 3px;
}

#mainContent {
    ul {
        li {
            padding: 1px 0;
        }
    }
}
#mainColMain {
    ul {
        &.pagination {
            li {
                padding: 10px;
                float: left;
            }
        }

        li {
            a {
                padding: 1px 0;
            }

            ul {
                li:first-child {
                    margin-top: 20px;
                }
            }
        }
    }
}
/*Definitions
______________________________________________________________________*/
#mainColMain dt {
    font-size:18px; 
    font-weight:700;
    padding-bottom:4px;
    margin-bottom:4px;
    border-bottom:1px solid $global_color_lightGray;
}
#mainColMain dd {
    margin-bottom:40px; 
    padding:10px 20px;
}

/*Accordions
______________________________________________________________________*/
.ui-accordion-header{
    border-radius:0px;
    border:0px;
    font-weight:bold;
    font-size:20px;
    padding:15px 10px 15px 20px !important;
}

h3.ui-accordion-header a {
    max-width: 95% !important; 
    display:inline-block;
}
.ui-accordion-header.ui-state-default{
    background: $mainColMain_color_accordion;
}
#mainColMain .ui-accordion-header button{
    border:0px;
    background: $global_color_transparent;
    font-family: $global_fontFamily_deptTitle;
    font-size:18px;
    font-weight:bold;
    text-align: left;
}
.ui-accordion-header.ui-state-hover,
#mainColMain .ui-accordion-header.ui-state-hover a:hover,
#mainColMain .ui-accordion-header.ui-state-hover button:hover{
    background: $global_color_lightGray;
    color: $global_color_black;
    border:0px;
}
.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
    position: absolute;
    left:inherit;
    right: .5em !important;
    top: 50%;
    margin-top: -8px;
    margin-right:10px;
}
.ui-accordion-content.ui-widget-content {
    border-bottom: 3px solid $mainColMain_color_accordion;
    border-left: 3px solid $mainColMain_color_accordion;
    border-right: 3px solid $mainColMain_color_accordion;
    background: $global_color_white;
    color: $global_color_black;
    padding:30px 35px;
    border-radius: 0px;
}

/*Tabs
______________________________________________________________________*/
#mainColMain .ui-tabs{
    padding:0px;
    margin:0px;
    border:0px;
    background: $global_color_transparent;
    font-family: $global_fontFamily_default;
}
#mainColMain .ui-tabs-nav,
#mainColMain .ui-tabs-nav *{
    padding:0px;
    margin:0;
    margin-bottom: 5px !important;
    border:0px;
    background: $global_color_transparent;
    font-family: $global_fontFamily_default;
}
#mainColMain .ui-tabs-nav {
    margin:0px 0px 80px 0px; 
    max-width:100%;
}
#mainColMain .ui-tabs-nav:after{
    width:100%;
    border-bottom:2px solid$global_color_black; 
    bottom: -5px;
    position: relative;
}
#mainColMain .ui-tabs-nav li a{
    border:2px solid $global_color_black;
    padding:8px 20px;  
    margin-right:10px;
}
#mainColMain .ui-tabs-nav li.ui-state-active a {
    background: $global_color_black;
}
#mainColMain .ui-tabs-nav li.ui-state-active *,
#mainColMain .ui-tabs-nav li a:hover *{
    color: $global_color_white;
}
#mainColMain .ui-tabs-nav li:not(.ui-state-active) a:hover{
    border:2px solid $global_color_primaryBlue;
}
#mainColMain .ui-tabs-panel {
    padding:0px;

    li {
        list-style: square;
    }
}

/*Panels
______________________________________________________________________*/

#mainColMain .panel,
#mainColMain .panel * {
    border-radius:0px;
}
.panel .panel-heading,
.panel .panel-heading * {
    background: $global_color_white;
    color: $global_color_black;
    padding-bottom:5px;
}
.panel .panel-heading{
    border-bottom:1px dashed #999;
}
#mainColMain .panel .panel-heading {
    padding:25px 30px 20px 30px; 
    margin-bottom:0px;
}
#mainColMain .panel-heading h2,
#mainColMain .panel-heading h3{
    margin-top:3px;
    font-family: $global_fontFamily_default;
    border:0px;
    }
#mainColMain .panel-body {
    padding:30px;
}
#mainColMain div.panel div.panel-body :last-child:not(.btn){
    margin-bottom:0px;
    padding-bottom:0px;
}

/*Panel Colors*/

/*Primary (Default Black) */
.panel-primary {
    border:1px solid $global_color_black;
}
.panel-primary .panel-heading,
.panel-primary .panel-heading *{
    background: $global_color_black;
    color: $global_color_white;
    border :0px;
}

/*Success (Green) */
.panel-success,
.panel-green {
    border: 1px solid $global_color_green;
}
.panel-success .panel-heading,
.panel-green .panel-heading{
    border-top: 10px solid $global_color_green;
}

/*Info (Blue)*/
.panel-info,
.panel-blue {
    border:1px solid $mainColMain_color_buttonInfo;
}
.panel-info .panel-heading,
.panel-blue .panel-blue{
    border-top:10px solid $mainColMain_color_buttonInfo;
}

/*Warning (Yellow)*/
.panel-warning,
.panel-yellow  {
    border:1px solid $mainColMain_color_panelWarning;
}
.panel-warning .panel-heading,
.panel-yellow .panel-heading{
    border-top:10px solid $mainColMain_color_panelWarning;
}

/*Danger (Red)*/
.panel-danger,
.panel-red {
    border:1px solid $mainColMain_color_danger;
}
.panel-danger .panel-heading,
.panel-red .panel-heading{
    border-top:10px solid $mainColMain_color_danger;
}

/*Jumbotrons
______________________________________________________________________*/

#mainColMain div.jumbotron .col-sm-3, #mainColMain div.jumbotron .col-sm-4, #mainColMain div.jumbotron .col-sm-6, #mainColMain div.jumbotron .col-sm-6, #mainColMain div.jumbotron .col-sm-8,
#mainColMain div.jumbotron .col-md-3, #mainColMain div.jumbotron .col-md-4, #mainColMain div.jumbotron .col-md-6, #mainColMain div.jumbotron .col-md-6, #mainColMain div.jumbotron .col-md-8
/*#mainColMain div.jumbotron :last-child*/{
    margin-bottom:5px;
}
div.jumbotron:after{
    content:'';
    display:block;
    clear:both;
}

/*Lead Texts
______________________________________________________________________*/
#mainColMain p.lead,
#mainColMain span.lead{
    font-size:24px;
}

/*Wells
______________________________________________________________________*/

#mainColContent .well,
#mainColContent .well *,
#mainColMain .well,
#mainColMain .well * {
    border-radius: 0px;
    margin:0px;
    border:0px;
}
#mainColContent .well,
#mainColMain .well {
    padding: 30px;
    background-color: $global_color_white;
    border: 1px solid $global_color_lightGray;
    border-bottom:5px solid $global_color_black;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom:30px;
}

#mainColContent .well .list-group-item,
#mainColMain .well .list-group-item{
    border-bottom:1px solid #aaa;
}
#mainColContent .well .list-group-item.active,
#mainColMain .well .list-group-item.active{
    background: $global_color_black;
}
#mainColContent .well .list-group-item:last-child,
#mainColMain .well .list-group-item:last-child{
    border:0px;
}

/*Buttons
______________________________________________________________________*/
#leftCol .btn,
#mainColMain .btn,
#mainColContent .btn {
    font-weight:600;
}

#mainColContent br+.btn,
#mainColMain br+.btn{
    margin-top:20px;
}

.btn {
    border-radius: 2px;
}
.btn-link {
    padding: 0px;
}

.department a .btn,
.department .btn{
    background-color: $global_color_primaryBlue;
    color: $global_color_white;
}

#leftCol .btn-primary,
#mainColContent .btn-primary {
    color: $global_color_white;
    background-color: $global_color_primaryBlue;
    border-color: $global_color_primaryBlue;
    text-decoration:none;
    border-radius: 2px;
    font-weight:600;
}
#leftCol .btn-default,
#mainColContent .btn-default {
    background: $global_color_white;
    color: $global_color_primaryBlue;
    /*Hot Fix, Needs Overriding CSS to be reordered*/
    border:1px solid !important;
    border-color: $global_color_primaryBlue;
    text-decoration:none;
    border-radius: 2px;
    font-weight:600;
}

#leftCol .btn:hover,
#leftCol .btn-default:hover,
#leftCol .btn-primary:hover,
#mainColContent .btn:hover,
#mainColContent .btn-default:hover,
#mainColContent .btn-primary:hover,
#mainColMain .btn:hover {
    color: $global_color_white;
    background-color: $global_color_black;
    border-color: $global_color_black;
}

/*btn focus states*/
#leftCol .btn-primary:focus,
#mainColContent .btn-primary:focus,
.department #rightCol .btn-primary:focus {
    border: 1px solid $global_color_red;
}

/*btn active states*/
#leftCol .btn-primary.active,
#mainColContent .btn-primary.active,
#mainColMain .btn:active {
    background-color: $global_color_primaryBlue;
    border-color: $global_color_primaryBlue;
    box-shadow: inset 0px 2px 10px rgba(0,0,0,.7);
}

#leftCol .btn-default.active,
#mainColContent .btn-default.active{
    color: $global_color_primaryBlue;
    border-color: $global_color_primaryBlue;
    background: $global_color_white;
    box-shadow: inset 0px 1px 6px rgba(0,61,165,.7);
}
#mainColMain .moreInformationDiv {
    text-align: center;
    margin: 20px;
    display: block;
}
#leftCol .btn-primary.disabled,
#leftCol  .btn-primary:disabled,
#mainColContent .btn-primary.disabled,
#mainColContent .btn-primary:disabled{
    background-color:#2f57a5;
}
/*btn padding*/
#leftCol .btn, 
#mainColContent .btn {
    padding:7px 20px;
}
#leftCol .btn-lg, 
#mainColContent .btn-lg {
    padding:15px 50px;
}
#leftCol .btn-sm, 
#mainColContent .btn-sm {
    padding:3px 10px; font-weight:700;
}
#leftCol .btn-xs, 
#mainColContent .btn-xs {
    padding:0px 5px;
}

/*Button Colors*/
#leftCol .btn-success,
#mainColContent .btn-success,
#mainColMain .btn-success {
    background-color: $global_color_green;
    border-color: $global_color_green;
    color: $global_color_white;
}
#leftCol .btn-info,
#mainColContent .btn-info,
#mainColMain .btn-info {
    background-color: $mainColMain_color_buttonInfo;
    border-color: $mainColMain_color_buttonInfo;
    color: $global_color_black;
}
#leftCol .btn-warning,
#mainColContent .btn-warning,
#mainColMain .btn-warning {
    background-color: $mainColMain_color_panelWarning;
    border-color: $mainColMain_color_panelWarning;
    color: $global_color_black;
}
#leftCol .btn-danger,
#mainColContent .btn-danger,
#mainColMain .btn-danger {
    background-color: $mainColMain_color_danger;
    border-color: $mainColMain_color_danger;
    color: $global_color_white;
}

/* popovers */
.popover {
    font-size: $global_fontSize_default;
    max-width: 310px;
}

/*Links as Buttons*/
#mainColMain a.btn, #leftMainRight a.btn {
    font-weight: 700;
}
#mainColMain a.btn:hover, #leftMainRight a.btn:hover {
    border:1px solid $global_color_black;

    &.externalLink {
        border: none;
    }
}

/*Read More Expander
______________________________________________________________________*/
.collapsibleText.truncated{
    height: 90px;
    overflow:hidden;
}
.fadeEffect{
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, $global_color_white 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%, $global_color_white 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%, $global_color_white 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#fff',GradientType=0 ); /* IE6-9 */

    display:block;
    width:100%;
    height:30px;
    margin-top:-58px;
    border-bottom:dashed 1px #eee ;
    z-index:2;
    position:absolute;
}

// Replacing the button styling with Text
.buttonToText{
    cursor: pointer;
    color: rgb(0, 70, 173);
    margin-top: -19px;
    widows: intrinsic; /*Safari/WebKit uses a non-standard name*/
    width: -moz-max-content; /*Firefox/Gecko*/
    width: -webkit-max-content; /*Chrome*/ }

    /*Main Column Link List
______________________________________________________________________*/
.componentTitle{
    margin: 0 0 30px 0;
}

.issueItem h3.issueTitle {
    font-size: $global_fontSize_default;
    font-family: $global_fontFamily_default;
    line-height: 20px;
}

.issueItem h3.issueTitle a {
    color: $global_color_black;
}

.issueItem .issueThumbContainer{
    width: 140px !important;
    height: 140px;
    border: 1px solid #999;
}


.issueItem img.issueThumb {
    padding: 20px;
    max-width: 140px !important;
    max-height: 140px;
    width: 100%;
    height: 100%;
}

.issueItem div.issueThumb {
    background: #999;
    margin: 20px;
    width: 100px;
    height: 100px;
}

/*Main Column Contact Group Page 
______________________________________________________________________*/
#mainColMain .departmentContactGroup h2
{
    border-bottom: none;
}
#mainColMain .departmentContactGroup .jumpToOptions
{
    border: 1px $global_color_black solid;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    margin-bottom: 30px;
}
#mainColMain .departmentContactGroup .contactGroupTable
{
    border-bottom: 1px #ddd solid;
}
#mainColMain .departmentContactGroup .contactGroupTable td
{
    width: 50%;
}
#mainColMain .departmentContactGroup .contactGroupTable div
{
    margin-bottom: 0;
    -ms-word-break: break-word;
    word-break: break-word;
}
#mainColMain table.contactGroupTable caption{
    border-bottom:0px;
} 
/*SEO Publish Date 
______________________________________________________________________*/
#seoGroupPublishDate.hidden {
    display: none;
    padding: 0;
}

#seoGroupPublishDate.visible {
    float: right;
    display: block;
    padding: 10px;
}

/* Updates to Accordion styling and converting jQuery UI icons to Font Awesome. 
_________________________________________________________________________________*/

.MainColumnAccordionListComponent {
    width: 100%;
    display: table;
}

.accordsFa {
    .accordion-toggle {
        cursor: pointer;
        min-height: 60px;
        padding-top: 14px;
        margin-bottom: 0;
        margin-top: 15px;
        border-radius: 2px;
        border-left: 1px solid $global_color_lightWhite;
        border-right: 1px solid $global_color_lightWhite;
        border-top: 1px solid $global_color_lightWhite;
        border-bottom: 1px solid $global_color_lightWhite;
        box-shadow: inset 0px 1px 1px rgba(0,0,0,0.2), 0px 1px 1px rgba(0,0,0,0.2);

        &:hover {
            background-color: $mainColMain_color_accordion_tab;
        }

        &.expanded {
            margin-bottom: 0;
            display: block;

            &:closest .content {
                border-bottom: 2px solid $global_color_lightWhite;
            }
        }

        &:first-child {
            margin-bottom: 0 !important;
        }

        .fa-plus, .fa-minus {
            color: #0046ad;
            font-size: 30px;
            position: absolute;
            margin: 1px 0px 0px 15px;
        }

        .toggleBtn {
            border: 0px;
            background: rgba(0,0,0,0);
            font-family: $global_fontFamily_deptTitle;
            font-size: 18px;
            font-weight: bold;
            text-align: left;
            color: #555555;
            padding-left: 53px;
            margin-top: 7px;
            padding-bottom: 17px;
            display: block;
        }
    }

    .accordionWrapper {
        padding: 0 35px;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        margin-bottom: -4px;
        border-left: 2px solid $global_color_lightWhite;
        border-right: 2px solid $global_color_lightWhite;
        border-bottom: 2px solid $global_color_lightWhite;

        &.collapsed {
            display: none;
        }

        .content {
            padding-top: 30px;
        }
    }

    .ui-accordion-header-active.ui-state-active.ui-corner-top.plus:hover,
    .ui-accordion-header-active.ui-state-active.ui-corner-top.minus {
        background: $mainColMain_color_accordion_tab;
    }

    .ui-accordion-header-active.ui-state-active.ui-corner-top.plus {
        background: none;
    }
}


@media (max-width: 666px) {
    .accordsFa {
        .accordion-toggle {
            .toggleBtn {
                padding-bottom: 16px;
                padding-top: 0px;
            }
        }
    }
}



