//START: styles for all cards
// desktop style for card with large icon and excerpt
.cardwithlargeiconexcerpt .content .iconTitle .newIcon {
    display: block;
    width: 66px;
    height: 66px;
    margin-left: 90px;
    font-size: 70px;


    .Green {
        color: #007749;
    }

    .Blue {
        color: #0046AD;
    }

    .Gold, .Bronze {
        color: $global_color_bronze;
    }

    .Gray {
        color: #565A5C;
    }
}

//icon w no background color cards
.card.cardwithlargeiconexcerpt {
    width: 258px !important;
    margin-left: 30px;
    margin-right: 30px;
}

.calendartext {
    font-size: 12px;
    float: left;
    margin-top: 2px;
    font-weight: normal !important;
}

.CardContainerComponent.WhiteCardContainer {
    background: $global_color_white;
}

.CardContainerComponent.DarkGrayCardContainer {
    background: #515151;

    h2, .cardContainerBodyCopy {
        color: #fff;
    }

    .cardContainerLink a,
    .cardContainerLink a:hover {
        color: #fff;
    }
    /*
    .cardContainerBodyCopy a {
        color: #FFF;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 1px;
    }

    .cardContainerBodyCopy a:hover {
        text-decoration: underline;
        text-underline-offset: 3px;
        text-decoration-thickness: 1px;
        background-color: none !important;
        color: #FFF !important;
    }

    .cardContainerBodyCopy a:visited {
        color: #DBD5E9;
    }
    */
}

.CardContainerComponent.LightGrayCardContainer {
    background: $global_color_lightWhite;
}

.CardContainerComponent.LightBlueCardContainer {
    background: #E1F5FE;
}

.card.landscapewithicon:hover,
.card.cardwithlink.default:hover,
.card.cardwithlink.extlink:hover,
.card.cardwithicon.default:hover,
.card.cardwithicon.extlink:hover,
.card.landscapewiththumbnail:hover,
.card.cardwiththumbnailexcerpt:hover {
    background: #F7F7F7;
    box-shadow: 2px 2px 5px 2px rgba(0,0,0,0.2);
}

.CardContainerComponent .externalLink .fa-file-arrow-down,
.CardContainerComponent .externalLink .fa-arrow-up-right-from-square {
    display: none;
}

.CardContainerComponent .externalLink .btmright .fa-file-arrow-down,
.CardContainerComponent .externalLink .btmright .fa-arrow-up-right-from-square {
    display: block;
}


.CardContainerComponent .cardContainerLink {
    clear: both;
    width: 100%;
    text-align: right;
    margin-top: 20px;
    font-weight: bold;
    margin-right: 10px;

    :hover {
        color: $global_color_darkBlue;
    }
}

.card {
    /*position: relative;*/
    .cardTitle {
        font-weight: bold;
    }

    .cardExcerpt {
        padding-top: 12px;
        padding-bottom: 20px;
    }

    .btmright {
        display: block;
        float: right;
        width: 20px;
        height: 20px;
        /*position: absolute;
        bottom: 10px;
        left: 280px;*/
    }

    .btmleft {
        display: block;
        float: left;
        margin-bottom: 20px;
    }

    .ellipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 260px;
        display: block;
    }

    .topSection {
        min-height: 120px;
    }

    .fa-arrow-up-right-from-square,
    .fa-file-arrow-down,
    .fa-calendar-days {
        font-size: 20px;
        color: #515151;
    }

    .fa-calendar-days,
    .fa-circle-dollar {
        margin-right: 5px;
    }
    .fa-circle-dollar {
        color: #007749;
        font-size: 20px;
    }
}
//END: styles for all cards


// START: styles for default card (text, description, link)
.card.cardwithlink {
    margin-top: 5px !important;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px !important;
    display: block;
    float: left;
    border: $global_color_lightWhite 1px solid;
    box-shadow: 0px 1px 2px 0px $global_color_lightWhite;

    .content {
        padding: 20px 10px 20px 15px;
        font-size: 16px;
        font-family: "Open Sans";
        width: 310px;
        font-style: normal;
    }

    a {
        color: rgb(51, 51, 51);
        text-decoration: none;
    }

    .btmright {
        margin-bottom: 20px;
    }
}

.card.cardwithlink {
    background: $global_color_white;
}

.card.cardwithlink.extactionlink:hover,
.card.cardwithlink.actionlink:hover {
    a {
        text-decoration: underline;
    }
}

.card.cardwithlink.actionlink,
.card.cardwithlink.extactionlink {
    a {
        color: $global_color_darkBlue;
        text-decoration: none;
        font-weight: bold;
    }
}

.DarkGrayCardContainer .cardwithlink .fa-arrow-up-right-from-square,
.DarkGrayCardContainer .cardwithlink .fa-file-arrow-down,
.DarkGrayCardContainer .cardwithlink .fa-calendar-days,
.DarkGrayCardContainer .cardwithicon .fa-arrow-up-right-from-square,
.DarkGrayCardContainer .cardwithicon .fa-file-arrow-down,
.DarkGrayCardContainer .cardwithicon .fa-calendar-days,
.DarkGrayCardContainer .landscapewiththumbnail .fa-arrow-up-right-from-square,
.DarkGrayCardContainer .landscapewiththumbnail .fa-file-arrow-down,
.DarkGrayCardContainer .landscapewiththumbnail .fa-calendar-days,
.DarkGrayCardContainer .landscapewithicon .fa-arrow-up-right-from-square,
.DarkGrayCardContainer .landscapewithicon .fa-file-arrow-down,
.DarkGrayCardContainer .landscapewithicon .fa-calendar-days {
    color: #515151 !important;
}
// END: styles for default card (text, description, link)


// START - styles for card component with thumbnail (thumbnail, title, description, link)*/
.card.cardwiththumbnail {
    margin-top: 5px !important;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px !important;
    display: block;
    float: left;
    padding: 20px 0px 20px 0px;
    font-size: 16px;
    font-family: "Open Sans";
    width: 245px;
    height: 233px;
    color: #0046ad;

    img {
        display: block !important;
        width: 98px;
        height: 98px !important;
        border-radius: 50%;
    }

    .center {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .cardTitle {
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        width: 245px;
        text-align: center;
    }

    a:hover {
        color: $global_color_darkBlue;
    }
}

.card.cardwiththumbnail:hover {
    img {
        box-shadow: 3px 5px 6px 2px rgba(0,0,0,0.2);
    }
}

.CardContainerComponent.DarkGrayCardContainer .card.cardwiththumbnail a,
.CardContainerComponent.DarkGrayCardContainer .card.cardwiththumbnail a:hover {
    color: $global_color_white;
}
// END: styles for card with thumbnail (thumbnail, text, description, link)


// START: styles for thumbnail with excerpt card (B tile)

.card.cardwiththumbnailexcerpt {
    margin-top: 5px !important;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px !important;
    display: block;
    float: left;
    border: $global_color_lightWhite 1px solid;
    box-shadow: 0px 1px 2px 0px $global_color_lightWhite;
    background: $global_color_white;

    .content {
        padding: 20px 10px 20px 15px;
        font-size: 16px;
        font-family: "Open Sans";
        width: 310px;
        font-style: normal;
        min-height: 150px;
    }

    a {
        color: rgb(51, 51, 51);
        text-decoration: none;
    }

    .btmright {
        margin-bottom: 20px;
    }

    img {
        min-height: 160px;
        min-width: 100%;
        width: auto;
    }

    .cardExcerpt {
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    .imgContainer {
        height: 160px !important;
        width: 310px;
        overflow: hidden;
    }

    .card.cardwiththumbnailexcerpt {
        background: $global_color_white;
    }

    .toprow {
        min-height: 90px;
    }

    .iconrow {
        margin-top: 10px;
    }
}

.scrollSpyContainer .card.cardwiththumbnailexcerpt .imgContainer{
    width: 298px;
}
// END: styles for thumbnail with excerpt card


// START - styles for card component with icon (icon, title, description, link)*/
.card.cardwithicon {
    margin-top: 5px !important;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px !important;
    display: block;
    float: left;
    border: $global_color_lightWhite 1px solid;
    box-shadow: 0px 1px 2px 0px $global_color_lightWhite;
    background: $global_color_white;

    .content {
        padding: 20px 10px 20px 15px;
        font-size: 16px;
        font-family: "Open Sans";
        width: 310px;
        min-height: 175px;
        font-style: normal;
    }

    a {
        color: rgb(51, 51, 51);
        text-decoration: none;
    }

    .cardTitle {
        display: inline;
    }
    // styling for external icon
    .smallicon {
        display: block;
        float: left;
        color: white;
        background: #0046ad;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 10px;
        margin-right: 8px;
    }
    // styling for external link icon.
    .pull-right {
        font-size: 19px;
        color: #515151;
        margin-right: 4px;
        margin: 0px 4px 0px 0px;
    }
    /*.iconBackground {
        border-radius: 50%;
        background: #0046ad; //default is blue
        width: 40px;
        height: 40px;
    }

    .iconBackground.Green {
        background: #007749;
    }

    .iconBackground.Blue {
        background: #0046AD;
    }

    .iconBackground.Gold {
        background: $global_color_bronze;
    }

    .iconBackground.Gray {
        background: #565A5C;
    }*/
    .smallicon.Green {
        background: #007749;
    }

    .smallicon.Blue {
        background: #0046AD;
    }

    .smallicon.Gold, .smallicon.Bronze {
        background: $global_color_bronze;
    }

    .smallicon.Gray {
        background: #565A5C;
    }
}

// Style for Card title and excerpt(default, actionlink, extlink, extactionlink).
.card.cardwithicon.actionlink,
.card.cardwithicon.default,
.card.cardwithicon.extlink,
.card.cardwithicon.extactionlink {
    .content {

        .topTitle {
            min-height: 36px;
        }

        .topExcerpt {
            float: left;
            width: 100%;
            min-height: 77px;
        }
    }
}

// styling for default's external icon.
.card.cardwithicon.default {
    .content {
        .fa-arrow-up-right-from-square {
            font-size: 19px;
            color: #515151;
            margin: 0px 4px 0px 0px;
            margin-left: 259px;
        }
    }
}

// Style for Card title and excerpt for action link with icon and action link.
.card.cardwithicon.extactionlink,
.card.cardwithicon.actionlink {
    .content {
        .bottomContent {
            float: left;
            width: 260px;
        }
    }
}

// hover over action link and extactionlink
.card.cardwithicon.actionlink:hover,
.card.cardwithicon.extactionlink:hover {
    .bottomContent {
        text-decoration: underline;
        color: $global_color_darkBlue;
    }
}

.card.cardwithicon.actionlink,
.card.cardwithicon.extactionlink {
    a {
        color: $global_color_darkBlue;
        font-weight: bold;
    }
}

// END - styles for card component with icon (icon, title, description, link)


// START  - styles for card component with LARGE icon (icon, title, description, link)*/
.card.cardwithlargeiconexcerpt {
    .cardExcerpt {
        text-decoration: none !important;
        color: #333;
        text-align: center;
        padding-top: 0px;
    }

    .mycardTitle {
        font-size: 32px !important;
        color: #333 !important;
        font-weight: bold;
        text-align: center;
        margin-top: 15px;
    }

    .linkTitle {
        font-weight: bold;
        text-align: center;
    }
}

.card.cardwithlargeiconexcerpt .mycardTitle {
    text-decoration: none;
}

.card.cardwithlargeiconexcerpt .cardExcerpt:hover,
.card.cardwithlargeiconexcerpt .mycardTitle:hover {
    text-decoration: none !important;
}

.card.cardwithlargeiconexcerpt .linkTitle:hover {
    text-decoration: underline;
}

.card.cardwithlargeiconexcerpt a:hover {
    text-decoration: none;
}

// START  - styles for card component with LARGE icon (icon,  link)*/
.card.cardwithlargeicon {
    width: 245px;
    //height: 233px;
    height: 192px;
    margin-left: 5px;
    margin-right: 5px;

    .iconTitle {
        border-radius: 50%;
        width: 98px;
        height: 98px;
        margin: auto;
    }
}

.card.cardwithlargeicon, .card.cardwithlargeiconexcerpt {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    display: block;
    float: left;
    padding: 10px 0px 10px 0px;
    font-size: 16px;
    font-family: "Open Sans";
    // styling for Large icon
    .largeicon {
        display: block;
        color: white;
        width: 98px;
        height: 98px;
        padding: 28px;
        margin-left: 0px !important;
    }

    .iconTitle.Green {
        background: #007749;
    }

    .iconTitle.Blue {
        background: #0046AD;
    }

    .iconTitle.Gold, .iconTitle.Bronze
    {
        background: $global_color_bronze;
    }

    .iconTitle.Gray {
        background: #565A5C;
    }
}



// Hover style on links.
.card.cardwithlargeicon:hover, .card.cardwithlargeiconexcerpt:hover {
    .iconTitle.Green,
    .iconTitle.Blue,
    .iconTitle.Gold,
    .iconTitle.Bronze,
    .iconTitle.Gray {
        opacity: 0.95;
    }

    .cardTitle {
        text-decoration: underline;
    }
}

.card.cardwithlargeicon:hover {
    .iconTitle {
        box-shadow: 3px 5px 6px 2px rgba(0,0,0,0.2);
    }
}


// truncating
.card.cardwithlargeicon, .card.cardwithlargeiconexcerpt {
    .content {
        .topTitle {
            .cardTitle {
                padding-left: 10px;
                padding-right: 10px;
                color: $global_color_darkBlue;
                padding-top: 20px;
                width: 245px;
                text-align: center;
            }
        }

        .iconTitle {
            cursor: pointer;
        }
    }
}
//hide the ext link added by js
.card.cardwithlargeicon,
.card.cardwiththumbnail,
.card.cardwithlargeiconexcerpt {
    .fa-arrow-up-right-from-square {
        display: none;
    }

    .fa-file-arrow-down {
        display: none;
    }
}
//display the ext link at the end of the title
.card.cardwithlargeicon .cardTitle,
.card.cardwiththumbnail .cardTitle,
.card.cardwithlargeiconexcerpt .cardTitle {
    .fa-arrow-up-right-from-square {
        display: inline;
        color: #0047ba;
        font-size: 16px !important;
    }

    .fa-file-arrow-down {
        display: inline;
        color: #0047ba;
        font-size: 16px !important;
    }
}

.DarkGrayCardContainer {
    .fa-arrow-up-right-from-square {
        color: #fff !important;
    }

    .fa-file-arrow-down {
        color: #fff !important;
    }
}

.cardwithcategory .cardTitle,
.cardwithcategory .dropdown-menu {
    .fa-file-arrow-down {
        color: #0046AD !important;
        font-size: 16px !important;
        display: inline !important;
    }

    .fa-arrow-up-right-from-square {
        color: #0046AD !important;
        font-size: 16px !important;
        display: inline !important;
    }
}

.cardwithcategory .dropdown-menu .externalLink .fa-arrow-up-right-from-square,
.cardwithcategory .dropdown-menu .externalLink .fa-file-arrow-down {
    display: none !important;
}

.CardContainerComponent.DarkGrayCardContainer .card.cardwithlargeicon .cardTitle,
.CardContainerComponent.DarkGrayCardContainer .card.cardwithlargeicon a,
.CardContainerComponent.DarkGrayCardContainer .card.cardwithlargeicon a:hover,
.CardContainerComponent.DarkGrayCardContainer .card.cardwithlargeiconexcerpt .cardTitle,
.CardContainerComponent.DarkGrayCardContainer .card.cardwithlargeiconexcerpt a,
.CardContainerComponent.DarkGrayCardContainer .card.cardwithlargeiconexcerpt a:hover {
    color: $global_color_white !important;
}
// END  - styles for card component with LARGE icon (icon, title, description, link)*/
// START - styles for landscape card with thumbnail
.card.landscapewiththumbnail {
    background: $global_color_white;
    display: block;
    float: left;
    font-size: 16px;
    font-family: "Open Sans";
    margin-right: 10px;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    box-shadow: 0px 1px 2px 0px $global_color_lightWhite;
    border: $global_color_lightWhite 1px solid;

    img {
        width: 140px;
        height: 140px !important;
    }

    .rightsection {
        display: block;
        float: left;
        padding: 10px 15px 0px 15px;
        /*overflow: hidden;*/
    }

    .leftsection {
        display: block;
        float: left;
        width: 140px;
    }

    .content {
        width: 630px;
        height: 140px;
    }

    a {
        color: rgb(51, 51, 51);
    }

    .upperright {
        width: 450px;
        height: 102px;
        /*overflow: hidden;*/
    }

    .iconrow {
        margin-top: -20px;
    }
}
// END - styles for landscape card with thumbnail
// START - styles for landscape card with icon
.card.landscapewithicon {
    display: block;
    float: left;
    font-size: 16px;
    font-family: "Open Sans";
    background: $global_color_white;
    margin-right: 10px;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    box-shadow: 0px 1px 2px 0px $global_color_lightWhite;
    border: $global_color_lightWhite 1px solid;

    .landscapeicon {
        width: 140px;
        height: 140px;
        padding-top: 35px;
        padding-bottom: 35px;
        color: $global_color_white;
    }

    .landscapeicon.Green {
        background: #007749;
    }

    .landscapeicon.Blue {
        background: #0046AD;
    }

    .landscapeicon.Gold, .landscapeicon.Bronze {
        background: $global_color_bronze;
    }

    .landscapeicon.Gray {
        background: #565A5C;
    }

    .rightsection {
        display: block;
        float: left;
        padding: 10px 15px 0px 15px;
        /*overflow: hidden;*/
    }

    .leftsection {
        display: block;
        float: left;
        width: 140px;
        height: 140px;
    }

    .content {
        width: 630px;
        height: 140px;
    }

    a {
        color: rgb(51, 51, 51);
    }

    .upperright {
        width: 450px;
        height: 102px;
        /*overflow: hidden;*/
    }

    .iconrow {
        margin-top: -20px;
    }
}
// END - styles for landscape card with icon


// START - card with category (accordion)

.card.cardwithcategory .cardExcerpt:hover {
    text-decoration: none;
}

.card.cardwithcategory {
    background: $global_color_white;
    display: block;
    float: left;
    font-size: 16px;
    font-family: "Open Sans";
    margin-right: 10px;
    width: 310px;
    min-height: 175px;
    border: $global_color_lightWhite 1px solid;
    box-shadow: 0px 1px 2px 0px $global_color_lightWhite;
    margin-bottom: 5px !important;
    margin-top: 5px !important;

    .catTopSection {
        padding: 10px;
        min-height: 125px;
    }

    .fa-chevron-up, .fa-chevron-down {
        float: right;
    }

    ul {
        margin-left: 0px !important;
        padding-left: 0px !important;
    }

    li {
        margin-left: 30px !important;
        margin-right: 10px !important;
        margin-bottom: 0px !important;
    }

    .collapsed {
        display: none;
    }

    .expanded {
        display: block;
    }

    .cardExcerpt, .cardExcerpt:hover {
        text-decoration: none;
        color: #333;
    }
    /*
    .toptaskHeader {
        background: $global_color_lightWhite;
        height: 50px;
        padding: 10px 20px 10px 10px;
        font-weight: bold;
        cursor: pointer;
    }

    .toptaskHeader:hover {
        background: #DAD9D6;
    }
   .listoftasks {
        background: $global_color_white;
        height: 150px;
        padding-top: 10px;
        border: $global_color_lightWhite 1px solid;
        box-shadow: 0px 1px 2px 0px $global_color_lightWhite;
        position: absolute;
        min-width: 310px;
        z-index: 1;
    }
    .listoftasks a {
        font-weight: normal;
        color: #0046AD !important;
    }

    .listoftasks a:hover {
        background: none;
        color: #0046AD !important;
        text-decoration: underline !important;
    }*/
}

.card.cardwithcategory.with-card-title-disabled {
    .catTopSection > a:first-child{
        text-decoration: none;
    }
    .cardTitle {
        color: $global_color_black;
    }
}

// END - styles for card with category


//styles for card components when added to main column top section or main column btm section
#mainColMain .CardContainerComponent div {
    margin-bottom: 0px; //this is important - resets the div margin btm
}

#mainColMain .CardContainerComponent a:hover {
    color: #333;
}

#mainColMain .card.actionlink a:hover,
#mainColMain .card.extactionlink a:hover {
    background: none;
    color: $global_color_darkBlue;
    text-decoration: underline;
}

#mainColMain .listoftasks a {
    font-weight: normal;
    color: $global_color_darkBlue;
}

#mainColMain .listoftasks a:hover {
    background: none;
    color: $global_color_darkBlue !important;
    text-decoration: underline;
}

#mainColMain .CardContainerComponent .cardExcerpt {
    font-weight: normal !important;
}

//display 2 landscape card when there's only 1 sidebar
.noLeftBorder .card.landscapewiththumbnail,
.noLeftBorder .card.landscapewithicon,
.noRightBorder .card.landscapewiththumbnail,
.noRightBorder .card.landscapewithicon {
    width: 470px;

    .content {
        width: 470px;
    }

    .upperright {
        width: 290px;
    }
}


//landscape cards inside maincol2col
//with left and right sidebar
.threeColRow {
    .card.landscapewithicon,
    .card.landscapewiththumbnail {
        .content {
            width: 310px;
        }

        .leftsection {
            display: none;
        }

        .upperright {
            width: 280px;
        }
    }
}

//landscape cards inside maincol2col with no sidebar
@media (max-width: 320px) {
    .card.landscapewithicon,
    .card.landscapewiththumbnail {
        .content {
            width: 310px;
        }

        .leftsection {
            display: none;
        }

        .upperright {
            width: 280px;
        }
    }
}

//phones
@media (min-width:321px) and (max-width:740px) {
    .card.landscapewithicon,
    .card.landscapewiththumbnail {
        .content {
            width: 360px;
        }

        .leftsection {
            display: none;
        }

        .upperright {
            width: 330px;
        }
    }

    .container.cardwithcategory {
        height: 100%;
    }
}

//tablets
@media (min-width:741px) and (max-width:960px) {
    .card.landscapewithicon,
    .card.landscapewiththumbnail {
        .content {
            width: 470px;
        }

        .upperright {
            width: 290px;
        }
    }
}


//START: center the cards on smaller desktops
@media (min-width: 992px) and (max-width: 1291px) {
    .CardContainerComponent .container,
    #mainColMain .CardContainerComponent {
        width: auto;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 767px) {
    .CardContainerComponent .container,
    #mainColMain .CardContainerComponent {
        width: 320px;
        margin-left: auto;
        margin-right: auto;
    }

    .CardContainerComponent .container,
    #mainColMain .CardContainerComponent {
        width: 320px;
        margin-left: auto;
        margin-right: auto;
    }
}
//END: center the cards on smaller devices

//styles for 3 cards
.noRightBorder .widerCard,
.noLeftBorder .widerCard {
    min-width: 310px !important;
    margin: 5px !important;
}

.DepartmentPortal .threeColRow .widerCard,
.DepartmentDetail .threeColRow .widerCard,
.DepartmentHome .threeColRow .widerCard {
    min-width: 310px !important;
    margin: 5px !important;
    width: 310px !important;

    .content {
        width: auto !important;
    }
}

//large desktops
@media (min-width: 1292px) {
    .card.cardwithcategory.widerCard .listoftasks {
        width: 409px;
    }

    .cardwithlink .widerCard, .cardwithcategory .widerCard, .cardwithicon .widerCard, .cardwiththumbnailexcerpt .widerCard {
        min-width: 410px;
        margin-right: 5px !important;
        margin-left: 5px !important;
        margin-bottom: 5px !important;
        margin-top: 5px !important;

        .content {
            width: 405px !important;
        }
    }

    .card.cardwiththumbnailexcerpt.widerCard .imgContainer {
        min-width: 408px !important;
    }

    .noRightBorder .widerCard,
    .noLeftBorder .widerCard,
    .threeColRow .widerCard {
        min-width: 310px !important;
        margin: 5px !important;

        .content {
            width: 310px !important;
        }

        .listoftasks {
            width: 309px !important;
        }
    }

    .cardwithcategory.widerCard .topRequest.dropDown .btn {
        width: 410px;
    }

    .cardwithcategory.widerCard .dropdown-menu {
        width: 410px;
    }
}


// start - card with top task using dropdown
.cardwithcategory .topRequest.dropDown .btn {
    min-width: 308px;
    text-align: left;
    cursor: pointer;
    background: #f1f1f1;
    height: 50px;
    color: rgb(51,51,51) !important;
    font-size: 16px;
    box-shadow: none !important;

    &:hover {
        background: #DAD9D6 !important;
        border: none !important;
        font-weight: bold !important;
    }

    &:focus {
        background: #DAD9D6 !important;
        font-weight: bold !important;
    }

    &.hasList {
        svg {
            position: relative;
            top: 2px;
            float: right;
            font-size: 20px;
            color: #0046AD;
        }
    }
}

.cardwithcategory .dropdown-menu {
    min-width: 308px;
    font-size: 16px;
    font-weight: bold !important;
    margin-top: 0px;
    border: #f1f1f1 1px solid;
    box-shadow: 0px 1px 2px 0px #f1f1f1;
}

.noRightBorder .widerCard .dropDown,
.noLeftBorder .widerCard .dropDown,
.threeColRow .widerCard .dropDown,
.noRightBorder .widerCard .dropdown-menu,
.noLeftBorder .widerCard .dropdown-menu,
.threeColRow .widerCard .dropdown-menu,
.noRightBorder .widerCard .topRequest.dropDown .btn,
.noLeftBorder .widerCard .topRequest.dropDown .btn,
.threeColRow .widerCard .topRequest.dropDown .btn {
    width: 308px !important;
}

.cardwithcategory .dropdown-menu a {
    font-weight: normal !important;
    color: $global_color_darkBlue !important;

    &:hover {
        background: none !important;
        color: $global_color_darkBlue !important;
        text-decoration: underline !important;
    }
}

.cardwithcategory .dropdown-menu ul {
    list-style: disc !important;
}

.cardwithcategory .dropdown-menu ul > li {
    list-style: disc !important;
}
// end - card with top task using dropdown


.CardContainerComponent .cardContainerBodyCopy {
    max-width: 850px;
    padding-bottom: 10px;
    margin-left: 10px;
}

.CardContainerComponent.colorBlock .cardContainerBodyCopy {
    margin-left: 0px;
}


// parks cards no links
#x144298 .catTopSection a {
    color: #333 !important;
}

//mobile
@media (max-width: 767px) {
    .CardContainerComponent {
        h2 {
            margin-left: 10px;
        }
    }

    .card.cardwithlargeicon {
        margin-left: 50px;
    }

    //mobile view for cardwithlargeiconexcerpt
    //width 167 width 280 height 20px gap
    //2 cards per row

    .cardwithlargeiconexcerpt {
        /*width: 167px !important;
        height: 280px !important;*/
        .content {
            width: 167px !important;
            height: 330px !important;
        }
    }

    .card.cardwithlargeiconexcerpt {
        width: 167px !important;
        height: 330px !important;
        padding: 5px !important;
        margin-left: 10px;
        margin-right: 10px;
        overflow: hidden;
    }

    .cardwithlargeiconexcerpt:not(.card.cardwithlargeiconexcerpt) {
        /* Styles */
        /*margin-left: -20px !important; Nflores -removed*/
        padding-bottom: 20px;
    }

    .card.cardwithlargeiconexcerpt .mycardTitle {
        font-size: 28px !important;
    }

    .card.cardwithlargeiconexcerpt .content {
        padding: 5px !important;
    }

    //icon on cardwithlargeiconexcerpt
    .newIcon {
        display: block;
        width: 66px;
        height: 66px;
        margin-left: 50px !important;
        font-size: 66px !important;
    }

    .CardContainerComponent .cardContainerLink {
        margin-bottom: 35px;
    }

    .CardContainerComponent .cardContainerBodyCopy {
        padding-left: 10px;
        padding-right: 10px;
    }

    /*.card.landscapewiththumbnail .iconrow {
        margin-top: -40px;
        margin-bottom: 40px !important;
    }

    .card.landscapewiththumbnail .upperright {
        width: 450px;
        min-height: 102px;
        max-height: 167px;
    }*/

    .CardContainerComponent.mobile__swipe {
        .mobile__swipeInner .cardContainerBodyCopy{
            display: none;
        }
    }
}

@media (max-width: 575px) {

    .PortalServices {

        #mainColContent {

            .CardContainerComponent {

                section {

                    &.cardwithlargeicon {
                        overflow-x: hidden;
                    }
                }
            }
        }
    }
}
