/********* MAC FIXES *********/
body.mac.notFF #deptBreadcrumbsContainer {
    margin-top: -7px;
}
body.mac.notFF #featuredTileContainer .featureTile h3.tileTitle {
    margin-bottom:11px;
}

/* Secondary Navigation in Footer */
body.mac.notFF #deptFootLinksWrapperShort.aboutNav ul li:first-child {
    padding-bottom: 12px;

    a {
        line-height: 34px;
        top: 4px;
        padding: 2px 10px 8px 10px;
    }
}