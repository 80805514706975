/*All Desktops
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
@media (min-width: 992px) {

    h1 {
        font-size: 2.375rem;
        font-weight: 700;
        font-family: $global_fontFamily_default;
    }

    h2 {
        font-size: 1.875rem;
        font-weight: 700;
        font-family: $global_fontFamily_default;
    }

    h3 {
        font-size: 1.375rem;
        font-weight: 700;
        font-family: $global_fontFamily_default;
    }

    h4 {
        font-size: 1.125rem;
        font-weight: 700;
        font-family: $global_fontFamily_default;
    }

    h5 {
        font-size: 0.875rem;
        font-weight: 700;
        font-family: $global_fontFamily_default;
    }

    /* Featured Container */
    #featuredTileContainer .mobileServices .halfHeight.taxonomyTile, .nestedTileWrapper > .taxonomyTile {
        display: none;
    }    
    #featuredTileContainer .taxonomyTile .seattleAlert .photoCredit {
        right: 75px;
    }
    /*DEPARTMENT LAYOUT FIXES*/
    .department #leftMainRight{
        padding-top:100px;
    }
    .department .col-md-9.col-sm-9.col-sm-push-3 {
        padding-left: 10px;
        padding-right: 10px;
    }
    /*Location Pages*/
    .parkHoursAndContactContainer{
        width:initial;
        margin-right:80px;
    }
    .parkHoursAndContactContainer .popover{
        width: $tiles_width_default;
    }
    .department #rightCol {
        padding-left: 20px !important;
        padding-right: 0px !important;
    }
}

/*Small Desktops
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
@media (min-width: 992px) and (max-width: 1291px) {
    .container, .parallaxCopy, #inputWrapper {
        width: 970px;
        max-width: 970px;
    }
    .bodyTileWrapper {
        width: 67%; 
    }
    /*Topic Pages*/
    #topicSidebar {
        width: 33%;
    }
    .TopicFilterComponent.colorBlock{
        background: $media_backgroundImage_smallDesktop;
        background-position:center;
        padding-bottom: 100px;
    }
    /*Featured Container*/
    .featureWrapper.emptyFeatureWrapper,
    .featureWrapperShort {
        padding-bottom:21%;
        margin-bottom: -100px;
    }
    .featureWrapper.emptyFeatureWrapper{margin-bottom: 0px;}
    .featureWrapper {
        background-size: cover;
    }
    div#featuredTileContainer {
        margin-top:60px;
        height:700px !important;
    }

    /*Breadcrumbs*/
    div#deptBreadcrumbsContainer {
        padding-bottom:15px;
    }

    /*Department Footer Links*/
    #departmentFooterLinksNewsWrapper .deptFootLinkCol a{
        display: block;
        padding: 10px 0px 8px;
        color: $global_color_black;
        font-size:14px;
        line-height:16px;
    }
    #deptFootNewsWrapper{
        margin-left:0px;
        padding-left:10px;
        text-align:right;
    }
    #deptFootNewsWrapper h3 {
        display: inline-block; 
        width:100%;
    }
    .deptFootDeptNameNewsSubscribe {
        color: $global_color_mediumGray;
        display: block;
        font-family: $global_fontFamily_default;
        font-size: 18px;
        font-weight: 300;
        margin-top:-3px;
    }
    #deptFootNewsWrapper i.fa {
        display:none;
    }
    #deptFootNewsWrapper input[name="email"] {
        width: 200px;
    }
    /*Trumba Calendar*/
    #centerColumn .trumbacal{
        max-width: 400px;
    }
    /*Feeds*/
    main .rssItem:last-child,
    main .issueItem:last-child,
    main .videoItem:last-child{
        display:none;
    }
    /* SearchFormCollection */
    .featureWrapperSlides .slick-prev{
        left:20px;
    }
    .featureWrapperSlides .slick-next{
        right:20px;
    }
    .featureWrapperSlides .slick-prev:before,
    .featureWrapperSlides .slick-next:before{
        font-size: 60px;
    }
}

/*Large Desktops
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
@media (min-width: 1292px) {
    .container, #siteFooter, .colorBox, #footer2, #seafooter, #seafooter15, #footerBottom15, .parallaxCopy, #mainColTop, #mainColBottom, #inputWrapper {
        max-width: 1292px;
        width: 1292px;
        padding-right: 10px;
        padding-left: 10px;
    }
    .TopicFilterComponent.colorBlock {
        background: $media_backgroundImage_largeDesktop; 
        background-position:center;
        padding-bottom: 100px;
    }
    .locationPage .col-xs-12.bodyText,
    .locationPage .col-xs-12.amenities
    {
        margin-right: 50px;
    }     
}