/*Components
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/

/*Video Component
______________________________________________________________________*/

/* jwplayer right click, hide the about this video link*/
.jw-info-overlay-item {
    display: none !important;
}

#mainColMain .VideoComponent .jwplayer {
    margin-bottom: 0px;
    outline: auto;

    /*embedded videos in seattle.gov*/
    &.jw-reset {
        margin-bottom: 0px !important;

        &.jw-tab-focus, &.jw-state-playing {
            &:focus {
                outline: solid 2px #ff0000;
            }
        }
    }
}

#mainColMain .VideoComponent .overlayBox {
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.01) 1%, rgba(0,0,0,0.95) 80%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.01) 1%,rgba(0,0,0,0.95) 80%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.01) 1%,rgba(0,0,0,0.95) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#f2000000',GradientType=0 ); /* IE6-9 */
    box-sizing: border-box;
    height: 60px;
    padding: 0px 10px;
    position: relative;
    margin-top: -60px;
}

#mainColMain .VideoComponent .overlayBox .videoTitle {
    color: $global_color_white;
    font-size: 20px;
    font-weight: 700;
    font-family: $global_fontFamily_default;
    position: absolute;
    bottom: 0px;
    margin: 0px 5px 10px 5px;
}

/*Blog Feed Component
______________________________________________________________________*/
/*All Feeds*/
.feedContainer h2 a {
    color: $global_color_black;
}

.feedContainer .fa-feed {
    color: $global_color_orange;
    font-size: 24px;
}

.rssItem {
    min-height: 40px
}

.rssDate,
aside .feedContainer > ol > li > span {
    font-weight: 700;
    font-size: 14px;
    width: 50px;
    height: 50px;
    padding-top: 12px;
    text-align: center;
    border: 1px solid $global_color_black;
    border-top: 5px solid $global_color_orange;
    position: absolute;
}

.rssDate.longDate {
    padding-top: 2px;
}

.rssThumbnail {
    display: none;
}

.rssTitle {
    font-size: 20px;
    line-height: 22px;
    padding-left: 70px;
    margin-bottom: 15px;
    font-weight: bold;
}

.feedContainer.title .rssTitle {
    font-size: 18px;
}

.rssTitle a {
    color: $global_color_black;
}

.rssDescription {
    padding-left: 70px;
}

.feedLinks .fa-rss {
    color: $global_color_orange;
}
/*Main Column Feeds*/
#mainColMain .feedLinks {
    height: 40px;
    margin: 0px;
    margin-top: 20px;
}

#mainColMain .feedContainer .feedTitleIconWrapper h2, #mainColMain .feedContainer .feedTitleIconWrapper h3, #mainColMain .feedContainer .feedTitleIconWrapper div, #mainColMain .feedContainer .feedTitleIconWrapper i, #mainColMain .feedContainer .feedTitleIconWrapper a, .councilVideos h2 {
    display: inline;
    position: relative;
    float: none;
}

#mainColMain .feedContainer .feedTitleIconWrapper i,
aside .feedContainer .feedTitleIconWrapper i {
    font-size: 24px;
    margin-left: 10px;
}

#mainColMain .feedContainer {
    padding-bottom: 20px;
}

.department #mainColMain .CityLinkRSSComponent:nth-child(n+2) {
    margin: 30px 0px 0px 0px;
}

#mainColMain .feedContainer h2 a {
    padding-bottom: 0px !important;
}

#mainColMain .twoColLeft > .padTop:first-child h2:first-child,
#mainColMain .twoColRight > .padTop:first-child h2:first-child {
    margin-top: 6px;
    margin-bottom: 25px;
}

#mainColMain .twoColLeft .feedTitleIconWrapper,
#mainColMain .twoColRight .feedTitleIconWrapper {
    border-bottom: 1px solid $global_color_lightGray;
    margin-bottom: 10px;
}

#mainColMain .twoColLeft .feedTitleIconWrapper h2,
#mainColMain .twoColRight .feedTitleIconWrapper h2 {
    border-bottom: none;
}
/*Sidebar Feeds*/
aside #rightCol .feedContainer {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    padding: 20px;
}

aside .feedContainer h3 {
    margin: 0px 0px 20px 0px;
    width: 90%;
}

aside .feedContainer h3 a {
    color: $global_color_black;
}

aside .feedContainer .feedLinks {
    width: 10%;
    text-align: right;
}

aside .feedContainer > ol,
.feedContainer > ol > li {
    margin: 0px;
    padding: 0px;
}

aside .feedContainer > div,
aside .feedContainer > ol {
    list-style: none;
    clear: both;
}

aside .feedContainer > ol > li {
    padding-left: 70px;
    padding-bottom: 15px;
    min-height: 65px;
}

aside .feedContainer > ol > li > a {
    color: $global_color_black;
    font-weight: 500 !important;
    font-size: 15px;
    line-height: 20px
}

aside .feedContainer > ol > li > span {
    margin-left: -70px;
}

aside .feedContainer.title_excerpt .rssItem {
    padding-bottom: 30px;
}

aside .feedContainer.title_excerpt .rssDescription {
    font-size: 15px;
}

aside .feedContainer.title_excerpt .rssBody {
    font-size: 18px;
}




//NFlores - old feed style*

// color block: 2 (630px) cards per row image 260px
// one sidebar: 2 (470px) cards per row  image 210px
// two sidebars: 1 (630px) card per row
// inside left/right sidebar card:  290px card
// mobile view: 290px card

#mainColMain.col-xs-12 {
    .feedContainer.title_excerpt_thumbnail {
        width: 100% !important;

        .grid-container {
            display: grid;
            //grid-gap: 5px; //that's optional, for the space between your cards
            grid-template-columns: 1fr 1fr;
        }
    }
}

.CityLinkRSSComponent.colorBlock{
    .feedContainer.title_excerpt_thumbnail {
        .grid-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }
}

//2 col layout no sidebars
#mainColMain.col-xs-12 .mainColumnTwoColumn .feedContainer.title_excerpt_thumbnail .grid-container {
    display: grid;
    grid-template-columns: 1fr !important;     
}

.feedContainer .feedTitleIconWrapper h2 a {
    /*color: #0047ba;*/
    color: $global_color_darkBlue;
}
.feedContainer .feedTitleIconWrapper h2 a:hover {
    background: #003da5;
    color: #fff;
    text-decoration: none;
}


//remove external icon and rss feed icon in the title
.feedContainer.title_excerpt_thumbnail {
    .feedTitleIconWrapper svg {
        display: none;
    }

    .feedTitleIconWrapper h2 {
        border-bottom: none !important;
    }
}

.feedcard {
    //clear: both;
    font-size: 16px;
    height: 140px;
    width: 630px;
    //width: 470px when there is a left or right
    background: #fff;
    margin-right: 10px;
    margin-bottom: 10px !important;
    box-shadow: 0px 1px 2px 0px #f1f1f1;
    border: #f1f1f1 1px solid;

    a {
        width: 100%;
        height: 100%;
        display: block;

        &:focus {
            outline: solid;
            color: $global_color_linkBlue;
        } 
    }

    &:hover {
        background: #F7F7F7;
        box-shadow: 2px 2px 5px 2px rgba(0,0,0,0.2);
    }

    div, p {
        margin-bottom: 0px !important;
    }

    .card-body {
        width: 368px; //368  --478
        height: 140px;
        float: left;
        margin-bottom: 0px;
        padding-top: 15px;
        color: #333;

        .topleftsection {
            height: 90px !important;
            padding-left: 15px;
            padding-right: 15px;
        }


        .card-title {
            font-weight: bold;
        }

        .card-text {
            font-weight: normal;
        }

        svg {
            color: #ea5300;
            margin-right: 10px;
            font-size: 14px;
            margin-left: 15px !important;
        }

        .card-date {
            font-size: 12px;
        }
    }

    .card-body.noimage {
        width: 629px;
        height: 140px
    }

    .cardthumbnail {
        width: 140px; //260  --150
        height: 140px;
        overflow: hidden;
        border-left: #f1f1f1 1px solid;
        border-right: #f1f1f1 1px solid;

        img {
            height: 140px;
            width: 140px; //260 --150
            object-fit: cover;
        }
    }

    .card-date {
        font-weight: normal;
        color: #333;
    }

    .fa-arrow-up-right-from-square {
        display: none;
    }
}
//no left sidebar or no right sidebar, display 2 cards
.noRightBorder, noLeftBorder {
    .feedcard {
        width: 470px;

        .card-body {
            width: 310px; //258  --318
        }

        .cardthumbnail {
            width: 140px;
        }

        .card-body.noimage {
            width: 468px;
        }
    }
}
//right side bar or left side bar feed card
//card is smaller than mobile feedcard 290px
#rightCol, #leftCol {
    .feedContainer.title_excerpt_thumbnail {
        padding: 0;
        box-shadow: none;

        .feedTitleIconWrapper h2 {
            border-bottom: 1px solid #dad9d6 !important;
            font-size: 24px !important;
            line-height: 26px;
            width: 290px;
        }
    }

    .feedcard {
        width: 290px !important;

        .card-body.noimage {
            width: 290px !important;
            height: 140px
        }
    }

    .feedContainer .feedTitleIconWrapper h2 a {
        width: 290px;
        /*color: #0047ba;*/
        color: $global_color_darkBlue;
    }

    .feedContainer .feedTitleIconWrapper h2 a:hover {
        background: #003da5;
        color: #fff;
        text-decoration: none;
    }
}

@media (max-width: 1291px) {
    //hide the thumbnail if left and right sidebars are visible
    .feedContainer {
        width: 100% !important;

        .grid-container {
            display: grid;
            grid-gap: 5px; /*that's optional, for the space between your cards*/
            grid-template-columns: 1fr !important;
        }
    }

    .threeColRow {
        .feedcard {
            width: 360px;

            .card-body {
                width: 360px;
            }

            .cardthumbnail {
                display: none;
            }
        }
    }

    #mainColMain.col-xs-12 .mainColumnTwoColumn .feedContainer.title_excerpt_thumbnail {

        .feedcard {
            width: 470px;

            .card-body {
                width: 258px; //258  --318
            }

            .cardthumbnail {
                width: 140px;
            }

            .card-body.noimage {
                width: 468px;
            }
        }
    }
}


@media (min-width: 992px) and (max-width: 1291px) {
    //968 breakpoint
    // full width: 2 630px > 2 (470px) cards per row image 210px, replaced with 1 630px card per row image 260px
    // one sidebar: 2 470px > 1 630px card per row image 260px
    // two sidebars: 290px card
    //no left sidebar or no right sidebar, display 2 cards
    .noRightBorder, noLeftBorder {
        .feedcard {
            width: 630px !important;
            //card with image
            .card-body {
                width: 490px !important;
            }
            //image
            .cardthumbnail {
                width: 140px !important;
            }
            //card with no image
            .card-body.noimage {
                width: 629px !important;
            }
        }
    }

    .mainColumnTwoColumn {
        .feedcard {
            width: 360px !important;
            margin: 0px !important;

            .card-body {
                width: 360px !important;
                height: 140px;
            }
            //no image on mobile
            .cardthumbnail {
                display: none;
            }
        }
    }
}

@media (max-width: 991px) { //767px or 967px

    .feedcard {
        width: 360px !important;
        margin: 0px !important;

        .card-body {
            width: 360px !important;
            height: 140px;
        }

        .cardthumbnail {
            display: none;
        }
    }
}
//end of old feed style



//start -- rss card style with thumbnail plugin 6/6/2023
//desktop view - 630px width (490 text + 140 thumbnail)
//desktop view no sidebar - 2 630px card per row
//desktop view one sidebar or 2 sidebars - 1 630px card per row
//desktop view inside maincol2col - 1 630px card per row

//tablet view - full width or one sidebar use 630px
//tablet view - two sidebars or inside a maincol2col use 310px hide thumbnail
//mobile view - 310px width hide thumbnail

#mainColMain .feedcard a:hover, .colorBlock .feedcard a:hover, .feedcard a:hover {
    background: none;
}

//when there is one sidebar just display 1 630px card per row
.noRightBorder #mainColMain.col-xs-12, .noLeftBorder #mainColMain.col-xs-12 {
    .feedContainer.title_excerpt_thumbnail {
        width: 100% !important;

        .grid-container.rsscard_ff {
            display: grid;
            grid-template-columns: 1fr;
        }
    }
}

.feedcard.rsscard_ff {
    width: 630px !important;

    .feedCardGridContainer {
        display: grid;
        grid-template-columns: 490px 140px;

        .card-body {
            width: 100%;
        }

        .cardthumbnail svg {
            height: 100px;
            color: #fff;
            padding-top: 34px;
        }

        .cardthumbnail {
            background-color: #949494;
            text-align: center;
            display: block;
            border: #f1f1f1 1px solid;
        }
    }

    .feedCardGridContainer.noThumbnail {
        grid-template-columns: 630px;
    }
}

//one sidebar
.noRightBorder, .noLeftBorder {
    .feedcard.rsscard_ff {
        .feedCardGridContainer {
            grid-template-columns: 490px 140px;

            .cardthumbnail {
                display: block;
            }
        }
    }

    .feedcard.rsscard_ff {
        .feedCardGridContainer.noThumbnail {
            grid-template-columns: 630px;

            .cardthumbnail {
                display: none;
            }
        }
    }
    //cards in maincol2col change to mobile view
    .mainColumnTwoColumn .feedcard.rsscard_ff {
        width: 310px !important;

        .feedCardGridContainer {
            display: grid;
            grid-template-columns: 310px;

            .card-body {
                width: 310px !important;
            }

            .cardthumbnail {
                display: none;
            }
        }
    }
}



// 2 sidebars
.threeColRow {
    .mainColumnTwoColumn .feedcard.rsscard_ff {
        width: 310px !important;

        .feedCardGridContainer {
            display: grid;
            grid-template-columns: 310px;

            .cardthumbnail {
                display: none;
            }

            .card-body {
                width: 310px !important;
            }
        }
    }
}


@media (min-width: 992px) and (max-width: 1291px) {

    //rss cards


    //cards inside maincol2col without sidebars change width to 450px
    .mainColumnTwoColumn, .threeColRow {
        .feedcard.rsscard_ff .feedCardGridContainer {
            display: grid;
            grid-template-columns: 310px 140px;
        }

        .feedcard.rsscard_ff .card-body {
            width: 310px !important;
            height: 140px;
        }

        .feedcard.rsscard_ff {
            width: 450px !important;
        }

        .feedcard.rsscard_ff .feedCardGridContainer.noThumbnail {
            display: grid;
            grid-template-columns: 450px;
        }

        .feedcard.rsscard_ff .noThumbnail .card-body {
            width: 450px !important;
        }
    }


    //cards inside maincol2col with two sidebars - change width to 310px (mobile)
    .threeColRow {
        .mainColumnTwoColumn .feedcard.rsscard_ff {
            width: 310px !important;

            .feedCardGridContainer {
                display: grid;
                grid-template-columns: 310px;

                .cardthumbnail {
                    display: none;
                }

                .card-body {
                    width: 310px !important;
                }
            }
        }
    }

    // small desktop inside maincol2col with one side bar change the card size to mobile size
    .noLeftBorder .mainColumnTwoColumn .feedcard.rsscard_ff, .noRightBorder .mainColumnTwoColumn .feedcard.rsscard_ff {
        width: 310px !important;

        .feedCardGridContainer {
            display: grid;
            grid-template-columns: 310px;

            .card-body {
                width: 310px !important;
            }

            .cardthumbnail {
                display: none;
            }
        }
    }
}

//tablet - display all in full width since the sidebars stacked and maincol2col stacks
@media (min-width: 768px) and (max-width: 991px) {

    // tablet inside maincol2col with one side bar change the card size to full size since maincoltwocol turns to 2 rows
    .feedcard.rsscard_ff,
    .noLeftBorder .mainColumnTwoColumn .feedcard.rsscard_ff,
    .noRightBorder .mainColumnTwoColumn .feedcard.rsscard_ff,
    .threeColRow .mainColumnTwoColumn .feedcard.rsscard_ff,
    .mainColumnTwoColumn .feedcard.rsscard_ff {
        width: 630px !important;

        .feedCardGridContainer {
            display: grid;
            grid-template-columns: 490px 140px;

            .cardthumbnail {
                display: block;
            }

            .card-body {
                width: 490px !important;
                height: 140px;
            }
        }

        .feedCardGridContainer.noThumbnail {
            grid-template-columns: 630px;

            .cardthumbnail {
                display: none;
            }

            .card-body {
                width: 630px !important;
            }
        }
    }
}


//mobile display 310px width rss card

@media only screen and (max-width: 767px) {

    .feedcard.rsscard_ff, .noRightBorder .feedcard.rsscard_ff, .noLeftBorder .feedcard.rsscard_ff {
        width: 310px !important;

        .feedCardGridContainer {
            display: grid;
            grid-template-columns: 310px;

            .card-body {
                width: 310px !important;
            }

            .cardthumbnail {
                display: none;
            }
        }
    }


    //cards inside maincol2col with sidebars - change width to 310px (mobile)
    .noRightBorder, .noLeftBorder, .threeColRow {
        .mainColumnTwoColumn .feedcard.rsscard_ff {
            width: 310px !important;

            .feedCardGridContainer {
                display: grid;
                grid-template-columns: 310px;

                .cardthumbnail {
                    display: none;
                }

                .card-body {
                    width: 310px !important;
                }
            }
        }
    }
} /*End of new rss style with thumbnail plugin*/











/*Board Member Component  
______________________________________________________________________*/
.boardMember {
    margin-bottom: 10px;
}

.boardMemberProfTitle {
    font-weight: normal;
}

.boardMember div.boardMemberContent h4.boardMemberName {
    margin-bottom: 8px;
}

.boardMember div.boardMemberContent .memberBio {
    display: none;
}

.boardMember div.boardMemberContent .toggleBio {
    cursor: pointer;
    color: $global_color_primaryBlue;
    display: block;
    font-weight: bold;
    clear: both;
}

.boardMember div.boardMemberContent .toggleBio:hover {
    color: $global_color_white;
    background: $global_color_black;
}

.boardMember div.boardMemberContent .toggleBio span.arrowUp {
    display: none;
}

.boardMember .pronouns{
    display: block;
    margin-top: 10px;
    float: left;
    font-family: $global_fontFamily_default;
    padding-left: 3px;
    font-size: 14px;
}

.headingPronouns, subheadingPronouns {
    font-weight: normal;
}

main .headingPronouns{
       font-size: 1.5rem;
}

aside .headingPronouns{
    font-size: 18px;
}


h4.boardMemberName {
    display: block;
    float: left;
    margin-right: 2px;
    font-size: 20px !important;
}
.boardMember {
    .boardMemberProfTitle,
    .BoardMemberPos,
    .boardMemberAppointedBy,
    .boardMemberTerm,
    .pronouns,
    .boardMemberName {
        margin-bottom: 4px !important;
    }

    .BoardMemberPos,
    .boardMemberProfTitle,
    .boardMemberAppointedBy,
    .boardMemberTerm {
        display: block;
        clear: both;
    }

    .toggleBio {
        margin-top: 20px;
    }

    .boardMemberPhoto img {
        height: 150px !important;
        width: 150px;
        border-radius: 50%;
    }
}





#accordion .boardMember div.boardMemberContent .toggleBio {
    display: none;
}

#accordion .boardMember div.boardMemberContent .memberBio {
    display: block;
}

/* Map Component  
______________________________________________________________________*/
#map #mainColMain #addressContainer {
    margin-bottom: 10px;
}

#map #mainColMain #addressContainer div {
    margin-bottom: 0px;
}

#map #map_zoom_slider,
#map #BasemapToggle .toggleButton.satellite {
    border: 0px;
    border-radius: 0px;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.25);
}

#map #map_zoom_slider .esriSimpleSliderIncrementButton,
#map #map_zoom_slider .esriSimpleSliderDecrementButton {
    width: 50px;
    height: 50px;
    border-radius: 0px;
    padding-top: 10px;
    transition: background .2s;
    color: $global_color_black;
}

#map #map_zoom_slider .esriSimpleSliderIncrementButton:hover,
#map #map_zoom_slider .esriSimpleSliderDecrementButton:hover {
    background: $global_color_primaryBlue;
    color: $global_color_white;
    font-weight: bold;
}

#map #BasemapToggle {
    top: 20px;
    right: 20px;
    width: 55px;
}

#map #BasemapToggle .toggleButton.satellite {
    border: 0px;
    border-radius: 0px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.75);
    padding: 0px 0px 10px 0px;
}

#map #BasemapToggle .toggleButton.satellite:hover,
#map #BasemapToggle .toggleButton.satellite:hover .basemapTitle {
    background: $global_color_primaryBlue;
    color: $global_color_white;
}

#map #BasemapToggle .basemapImage {
    width: 100%;
    padding-bottom: 10px;
}

#map #BasemapToggle .basemapTitle {
    font-weight: 600;
    font-size: 11px;
    color: $global_color_black;
}

#map .logo-med,
#map .esriAttribution {
    display: none;
}
/*Point Pop Ups*/
#map .esriPopupWrapper,
#map .esriPopupWrapper .titlePane,
#map .esriPopupWrapper .actionsPane {
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    -o-border-radius: 0px;
}

#map .esriPopupWrapper .titlePane {
    padding: 0px;
}

#map .esriPopupWrapper .titlePane .title {
    padding: 10px 10px 10px 50px;
}

#map .esriPopupWrapper .titlePane .titleButton.close {
    background: $global_color_transparent;
    opacity: 1;
    left: 0px;
    top: 0px;
}

/*#map .esriPopupWrapper .titlePane .titleButton.close:before {
    content: '\f00d';
    font-family: 'fontAwesome';
    font-weight: normal;
    color: $global_color_white;
    width: 40px;
    height: 40px;
    padding-top: 9px;
    border-right: 1px solid $global_color_mediumGray;
    border-bottom: 1px solid $global_color_mediumGray;
    text-align: center;
    display: inline-block;
}*/

#map .esriPopupWrapper .titlePane .titleButton.close:hover:before {
    background: $global_color_white;
    color: $global_color_black;
}

#map .action.zoomTo {
    display: none
}

/* datatables  
______________________________________________________________________*/
table.dataTable tbody tr.active, table.dataTable.display tbody tr.active:hover {
    color: $global_color_white;
    background-color: $global_color_primaryBlue;
    border-color: $global_color_primaryBlue;
}

table.dataTable {
    border-top: 8px solid $global_color_darkGray;
}

table.dataTable thead th {
    border-top: 1px solid #ddd !important;
}

table.dataTable thead th {
    border-right: 1px solid #eee;
    border-top: 1px solid #eee;
    font-weight: 600;
    padding-right: 30px;
}

table.table.dataTable thead th:first-child {
    border-left: 1px solid #eee;
}

table.table.dataTable thead th.details-control {
    border-right: 0 none;
    padding-right: 0;
}

table.dataTable tfoot {
    display: none;
}

table.dataTable th, table.table.dataTable td {
    vertical-align: middle;
}

table.dataTable tr + tr:hover td {
    background: $global_color_white none repeat scroll 0 0;
}

table.dataTable.table-bordered {
    border-bottom: 0px;
}

table.dataTable.table-bordered tr th,
table.dataTable.table-bordered tr td {
    border-left: 1px solid $global_color_lightGray;
    border-right: 0px;
    border-bottom: 0px;
}

table.dataTable.table-bordered tr th {
    border-top: 0px;
    border-bottom: 1px solid $global_color_black;
}

table.dataTable.table-bordered tr th:first-child,
table.dataTable.table-bordered tr td:first-child {
    border-left: 0px;
}

table.dataTable.table-bordered tr:last-child td {
    border-bottom: 1px solid $global_color_black;
}

table.dataTable td.details-control {
    text-align: center;
}

#mainColMain .dataTables_length, #mainColMain .dataTables_filter {
    margin-bottom: 10px;
}

.dataTables_length select, .dataTables_filter input {
    border-radius: 0;
}

.dataTables_filter input {
    font-weight: 600;
    margin-left: 5px !important;
}

.dataTables_length::after {
    content: " ";
    left: 0;
    margin: -10px 0 0;
    position: absolute;
    width: 100%;
    z-index: 0;
}

.dtr-title {
    font-weight: bold;
}

.dtr-title::after {
    content: ": ";
}

.dataTables_filter, .dataTables_info {
}

.dataTables_wrapper .dataTables_paginate {
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    background: #eee none repeat scroll 0 0;
    border: 0 none !important;
    border-radius: 0 !important;
    margin-bottom: 6px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
    display: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous, .dataTables_wrapper .dataTables_paginate .paginate_button.next {
    display: none;
}

#mainColMain .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background: $global_color_primaryBlue none repeat scroll 0 0 !important;
    color: $global_color_white !important;
}

//Wrapping table cell content on tablet/mobile.
@media screen and (max-width: 767px) {
    .table-responsive > .table > tbody > tr > td {
        white-space: normal;
    }
}

button.details-control {
    border: none;
    background-color: inherit;
    padding: 10px;
}

td.details-control, 
button.details-control {
    cursor: pointer;
    text-align: left !important;
}

/*td.details-control:before,
tr.shown td.details-control:before, 
button.details-control {
    font-family: 'fontAwesome';
    font-size: 20px;
}

td.details-control:before, 
button.details-control:before {
    content: '\f067';
    color: $global_color_darkGray;
}

tr.shown td.details-control:before,
button[aria-expanded="true"].details-control:before {
    content: '\f068';
}*/

td.details-control .glyphicon {
    display: none;
}

.dataTable .imageGallery .imageGalleryImage {
    display: inline-block;
}

/* SearchFormCollection  
______________________________________________________________________*/

.featureWrapperSlides {
    position: absolute;
    width: 100%;
    height: 350px;
}

.featureWrapperSlides .slide {
    width: 100%;
    height: 350px;
    background-position: center center;
    background-size: cover;
}

.featureWrapperSlides .slick-prev,
.featureWrapperSlides .slick-next {
    width: 100px;
    height: 60px;
    z-index: 1000;
}

.featureWrapperSlides .slick-prev {
    left: 100px;
}

.featureWrapperSlides .slick-next {
    right: 100px;
}

/*.featureWrapperSlides .slick-prev:before,
.featureWrapperSlides .slick-next:before {
    font-size: 60px;
    font-family: 'FontAwesome';
    color: $global_color_white;
    text-shadow: 0px 0px 20px rgba(0,0,0,0.5);
}

.featureWrapperSlides .slick-prev:hover:before,
.featureWrapperSlides .slick-next:hover:before {
    text-shadow: 0px 0px 20px rgba(0,0,0,0.9);
}

.featureWrapperSlides .slick-prev:before {
    content: '\f053';
}

.featureWrapperSlides .slick-next:before {
    content: '\f054';
}*/

.searchFormCollectionWrapper .featureWrapper,
.searchFormCollectionWrapper .featureWrapper.emptyFeatureWrapper {
    margin-bottom: 0px;
    height: 350px;
    transition: all .3s;
}

.searchFormCollectionWrapper .searchFormWrapper {
    width: 630px;
    margin: 70px auto;
}

.findWhat {
    margin-bottom: 20px;
    display: inline-flex;
    width: 100%;
}

.findWhatTitle {
    color: $global_color_white;
    font-size: 46px;
    font-family: $global_fontFamily_default;
    font-weight: bold;
    line-height: 0px;
    position: relative;
}

span.findWhatTitle {
    text-shadow: 0px 0px 75px rgba(0,0,0,0.9);
    min-width: 122px;
    margin-top: 30px;
}

select.findWhatTitle {
    background-color: rgba(0,0,0, .65);
    width: 100%;
    font-style: italic;
    padding: 5px 0px 0px 4px;
    margin: 0px 0px 0px 5px;
    height: 50px;
    border: 1px solid $global_color_white;
    border-radius: 0px;
}

select.findWhatTitle option {
    background: $global_color_black;
    font-size: 24px;
}

.byWrapper {
    color: $global_color_white;
    font-size: 20px;
    font-weight: 600;
    font-style: italic;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.65);
    display: block;
    position: absolute;
    margin-left: -35px;
    margin-top: 10px;
}

.findByWrapper.form-group {
    margin-top: 0px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.5);
}

.findByWrapper .btn-group {
    width: 100%;
}

.findByWrapper .btn-group button {
    width: 25%;
    background-color: rgba(0,0,0,0.75);
    color: $global_color_white;
    border: 1px solid $global_color_white;
    border-radius: 0px;
    font-size: 18px;
    height: 50px;
    transition: all .25s;
}

.findByWrapper .btn-group button.active {
    background-color: $global_color_primaryBlue;
    font-weight: bold;
    box-shadow: none;
    cursor: default;
}

.findByWrapper .btn-group button:hover:not(.active) {
    background-color: $global_color_black;
}

.findByInput {
    position: relative;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    width: 100%;
}

/*.findByIndicator.fa {
    position: absolute;
    font-family: 'FontAwesome';
    font-size: 30px;
    line-height: 0px;
    top: -6px;
    left: 15.5%;
    color: $global_color_white;
    width: 0;
    height: 0;
    text-shadow: 0px -2px 3px rgba(0,0,0,0.5);
    transition: all .5s;
    z-index: 1;
}*/

.svg-inline--fa.fa-caret-up.findByIndicator {
    position: absolute;
    left: 10.5%;
    color: white;
    text-shadow: 0px -2px 3px rgba(0,0,0,0.5);
    transition: all .5s;
    z-index: 1;
    margin-top: -14px;
}

.findByInput input {
    width: 100%;
    height: 50px;
    border: 0px;
    border-radius: 0px;
    padding: 10px;
}

.findByInput button {
    display: block;
    position: absolute;
    /*color:;*/
    top: 5px;
    right: 5px;
    width: 120px;
    height: 40px;
    background: $global_color_primaryBlue;
    border: 0px;
    font-weight: bold;
    font-size: 15px;
    color: $global_color_white;
}

.findByInput button:hover {
    background: $global_color_black;
    color: $global_color_white;
}
/* easy autocomplete */
.easy-autocomplete-container {
    background-color: rgba(255,255,255,.9);
    z-index: 9001;
    position: absolute;
    width: 100%;
    height: 0;
}

.easy-autocomplete-container * {
    color: #444444;
}

.easy-autocomplete-container ul {
    background-color: rgba(255,255,255,.85);
}

.easy-autocomplete-container li {
    padding: 5px 0px;
}
/* Address Helper */
.searchFormWrapper .addressHelper {
    background: rgba(255,255,255,.8);
    padding: 0px 0px 0px 20px;
    display: flex;
}

.searchFormWrapper .addressHelper .fa-circle-exclamation {
    font-size: 28px;
    padding: 15px 0px;
}

.searchFormWrapper .addressHelper .message {
    padding: 10px 15px;
    width: 90%;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
}
/* Fontawesome v5 */
.searchFormWrapper .addressHelper .fa-xmark {
    border: 0px;
    background: $global_color_transparent;
    font-size: 28px;
    margin-right: 0px;
    padding: 15px 20px;
}
/* Fontawesome v5 */
.searchFormWrapper .addressHelper .fa-xmark:hover {
    background: $global_color_black;
    color: $global_color_white;
}
/* Hide Address Submit */
.findByInput input.addressSubmit {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    visibility: hidden;
    background: $global_color_transparent;
}
/*A to Z Link*/
.aToZLink {
    margin-top: 40px;
    text-align: center;
}

.aToZLink a {
    background: rgba(0,0,0,0.75);
    color: $global_color_white;
    padding: 3px 10px;
    font-size: 13px;
    transition: all .25s;
}

.aToZLink a:hover {
    background: $global_color_white;
    color: $global_color_black;
    border-bottom: 1px solid $global_color_black;
    text-decoration: none;
}


/* PhotoGalleryControlComponent
______________________________________________________________________*/

.PhotoGalleryControlComponent, .photoGalleryContainer {

    margin-bottom: 50px !important;

    .lightbox img {
        margin: 0 !important;
    }

    a.lightbox {
        font-size: 0px;
        line-height: 0px;
        display: inline-block;
        margin: 5px 5px 5px 0;
    }
    
    a.lightbox:hover {
        background-color: transparent !important;
    }

    .photoGalleryImages .slick-prev, .flickr-album .slick-prev {
        left: 0px;
    }

    .photoGalleryImages .slick-next, .flickr-album .slick-next {
        right: 0px;
    }
    
    .slick-prev, .slick-next {
        width: 50px;
        height: 50px;
        top: 40%;
    }

    .slick-next:before, .slick-prev:before {
        color: #000;
        font-size: 45px;
    }

    .slick-dots {
        margin: 0 !important;
        padding: 0 !important;
        max-width: none !important;
    }

    .slick-dots li button::before {
        font-size: 16px;
    }

    @media (min-width: 991px) {
        .slick-slider {
            padding: 0 55px;
        }
        
        .slick-dots {
            margin-left: -50px !important;
        }
        
    }

    @media (max-width: 768px) {
        padding: 0px !important;
        margin-bottom: 0px !important;
        
        a.lightbox {
            pointer-events: none;
            cursor: default;
        }
    }
}

.PhotoGalleryControlComponent.colorBlock .photoGalleryContainer {

    max-width: 1292px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 10px 5px !important;
    
    @media (min-width: 992px) and (max-width: 1292px) {
        max-width: 970px;
    }
    
    @media (min-width: 768px) and (max-width: 991px) {
        max-width: 750px;
    }    
}

.colorBlock .PhotoGalleryControlComponent, .colorBlock .photoGalleryContainer {
    min-height: 360px;
    padding: 0 !important;
    margin-bottom: 0px !important;
}

.ekko-lightbox {

    .modal-footer {
        border: 0px;
        color: $global_color_white;
        font-weight: bold;
        text-align: left;
    }

    a, a:visited, a:active {
        color: $global_color_white;
    }

    a {
        text-decoration: underline;
        font-size: 15px;
    }

    a:hover {
        background-color: none;
        color: $global_color_white;
    }

    .ekko-lightbox-nav-overlay a span {
        background-color: $global_color_white;
        color: $global_color_black;
        font-size: 50px;
        text-decoration: none;
    }

    .ekko-lightbox-nav-overlay a:last-child span {
        margin-left: 80%;
    }

    .ekko-lightbox-nav-overlay a:first-child span {
        margin-right: 80%;
    }
}

#flickr-header {
    margin-top: 80px !important;

    @media (max-width: 768px) {
        margin-top: 0px !important;
    }
}

.flickr-header p {
    max-width: none;
}

photoGalleryDisplay-Grid .photoGalleryImage img{
    height: 200px;
    width: 200px;
}

/* start - .photoGallery_FF with new light box */
/* pagination style */
.photoGallery_FF.pageNumbers {
    text-align: center;
    font-size: 18px;

    button.prevItem {
        margin-right: 20px;
    }

    button.nextItem, button.prevItem {
        width: 30px;
        background-color: transparent;
        border: none;
        color: #0046AD;
    }

    button.nextItem:hover, button.prevItem:hover {
        color: #000;
        background-color: #F1F1F1;
    }

    .prevItem:hover svg[data-prefix="far"],
    .prevItem svg[data-prefix="fas"] {
        display: none;
    }

    .prevItem:hover svg[data-prefix="fas"] {
        display: inline;
    }

    .nextItem:hover svg[data-prefix="far"],
    .nextItem svg[data-prefix="fas"] {
        display: none;
    }

    .nextItem:hover svg[data-prefix="fas"] {
        display: inline;
    }

    .photoGalleryPaginator {
        button.btn-link {
            font-size: 16px;
            color: #0046AD;
            font-family: $global_fontFamily_default;
            font-weight: 500;
            margin-right: 20px;
            width: 30px;
        }

        button.btn-link:hover {
            color: #000;
            font-weight: bold;
            background-color: transparent;
        }
    }

    &.hide {
        display: none;
    }
}

.photoGallery_FF.photoGalleryDisplay-Thumbnail.mobileVersion,
.photoGallery_FF.photoGalleryDisplay-Masonry.mobileVersion,
.photoGallery_FF.photoGalleryDisplay-HeroCarousel.mobileVersion {
    display: none;
}

.photoGallery_FF.photoGalleryDisplay-HeroCarousel {
    &.modalPhotoGallery-HeroCarousel {
        .mySlides {
            display: none;
        }

        .mySlides:first-child {
            display: block;
        }
    }
}

.photoGallery_FF.photoGalleryDisplay-Thumbnail.mobileVersion,
.photoGallery_FF.photoGalleryDisplay-Masonry.mobileVersion,
.photoGallery_FF.photoGalleryDisplay-HeroCarousel.mobileVersion {
    width: 302px;

    .column {
        height: auto;
        width: 302px;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
    }

    figure {
        display: none;
    }

    figure:first-child {
        display: block;
    }

    .miniCarousel {
        display: none;
    }

    .miniCarousel:first-child {
        display: block;
    }

    .miniCarousel figure {
        width: 302px; // there is 20px padding-left
    }

    .miniCarousel img {
        width: 302px !important;
        height: 302px !important;
        object-fit: contain;
        background-color: #F1F1F1;
    }

    .miniCarousel a.PhotoGalleryItem__CoverLink:focus{
        outline: none;
    }

    .lightbox__imgtitle, .lightbox__imgdescription {
        background-color: #fff;
        color: #000;
        width: 302px; // there is 20px padding-left
        margin-left: 0px;
    }

}

#rightCol {
    .photoGallery_FF.photoGalleryDisplay-Thumbnail,
    .photoGallery_FF.photoGalleryDisplay-Masonry,
    .photoGallery_FF.photoGalleryDisplay-HeroCarousel {
        display: none;
    }

    .PhotoGalleryControlComponent .pageNumbers {
        display: none;
    }

    .photoGallery_FF.photoGalleryDisplay-Thumbnail.mobileVersion,
    .photoGallery_FF.photoGalleryDisplay-Masonry.mobileVersion,
    .photoGallery_FF.photoGalleryDisplay-HeroCarousel.mobileVersion {
        display: block;
    }

    .PhotoGalleryControlComponent .photoGalleryTitle {
        margin-top: 20px;
    }
}

.noLeftBorder .mainColumnTwoColumn,
.noRightBorder .mainColumnTwoColumn,
.threeColRow .mainColumnTwoColumn{
    .photoGallery_FF.photoGalleryDisplay-Thumbnail,
    .photoGallery_FF.photoGalleryDisplay-Masonry,
    .photoGallery_FF.photoGalleryDisplay-HeroCarousel {
        display: none;
    }

    .PhotoGalleryControlComponent .pageNumbers {
        display: none;
    }

    .photoGallery_FF.photoGalleryDisplay-Thumbnail.mobileVersion,
    .photoGallery_FF.photoGalleryDisplay-Masonry.mobileVersion,
    .photoGallery_FF.photoGalleryDisplay-HeroCarousel.mobileVersion {
        display: block;
    }
}

.photoGallery_FF {
    /* photoGalleryDisplay-Thumbnail */
    &.photoGalleryDisplay-Thumbnail.row img {
        height: 140px !important;
        width: 140px;
        object-fit: cover;
    }
    /* thumbnails in the footer of the modal*/
    .imageFooterThumbnails {
        display: flex;
        justify-content: flex-start;
        column-gap: 18px;
        margin-top: 15px;
        margin-bottom: 0px !important;
        margin-left: 30px;

        a {
            height: 55px;
            width: 55px;
            opacity: 0.4;
        }

        a:hover {
            opacity: 0.9;
        }

        a:focus {
            opacity: 1;
            // outline: 2px auto -webkit-focus-ring-color;
            outline: #000 solid 2px;
            outline-width: 2px;
            outline-offset: unset;

            img {
                height: 55px !important;
                width: 55px !important;
            }
        }

        a:active,
        a.active {
            opacity: 1;
            // border: solid 2px #000;
            outline: #000 solid 2px;
            outline-width: 2px;
            outline-offset: unset;
            // border-width: 2px;

            img {
                height: 55px !important;
                width: 55px !important;
            }
        }


    }
    

    &.modalPhotoGallery {
        
        .imageFooterThumbnails a.active,
        .imageFooterThumbnails a:active{
            outline: #fff solid 2px;
            outline-offset: unset;
            outline-width: 2px;
        }
        .imageFooterThumbnails a:focus{
            outline: #fff solid 2px;
            // outline: 2px auto -webkit-focus-ring-color;
            outline-offset: unset;
            outline-width: 2px;
        }
    }

    .imageFooterThumbnails img {
        height: 55px !important;
        width: 55px !important;
        object-fit: cover;
    }

    .row > .column {
        padding: 0 8px;
    }

    .row:after {
        content: "";
        display: table;
        clear: both;
    }

    .column {
        float: left;
        height: 140px;
        width: 140px;
        margin: 10px;
    }

    .PhotoGalleryItem__CoverLink:focus {
        outline: 2px auto -webkit-focus-ring-color;
        outline-offset: unset;
    }
    /* The Modal (background) */
    &.modalPhotoGallery {
        display: none;
        position: fixed;
        z-index: 1;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(51, 51, 51, 0.7);
        // .fa-chevron-right:hover::before {content: "\f054";}
        // .fa-chevron-left:hover::before {content: "\f053";}
    }

    &.photoGalleryDisplay-HeroCarousel {
        .modalPhotoGallery-content {
            .mySlides img {
                background-color: #D9D9D9;
            }
        }
    }
    /* Modal Content */
    .modalPhotoGallery-content {
        position: relative;
        background-color: #333;
        margin: auto;
        padding-top: 28px; //20+24+14
        padding-bottom: 15px;
        width: 626px;


        .column {
            float: left;

            img {
                height: 55px !important;
                width: 55px !important;
            }
        }

        .mySlides {
            img {
                width: 566px;
                margin-left: 30px;
                /*object-fit: cover;*/
                min-height: 340px;
                margin-left: 30px;
                background-color: #000;
                object-fit: contain;
            }
        }

        .photoGalleryTitle, .photoGalleryDescription, .photoGalleryDate {
            width: 600px;
            color: #f1f1f1;
            padding-left: 30px;
            font-family: 'Open Sans';
        }

        p.photoGalleryDescription {
            margin-bottom: 30px;
            font-size: 16px;
        }

        .photoGalleryTitle {
            margin-top: 0px;
            font-size: 1.5rem;
        }

        .photoGalleryDate {
            font-size: 16px;
            padding-bottom: 15px;
        }

        .prev:hover svg[data-prefix="far"],
        .prev svg[data-prefix="fas"] {
            display: none;
        }

        .prev:hover svg[data-prefix="fas"] {
            display: inline;
        }

        .next:hover svg[data-prefix="far"],
        .next svg[data-prefix="fas"] {
            display: none;
        }

        .next:hover svg[data-prefix="fas"] {
            display: inline;
        }

        .lightbox__imgtitle, .lightbox__imgdescription {
            font-family: 'Open Sans';
            font-size: 16px;
        }

        .lightbox__imgtitle {
            font-weight: bold;
            padding-top: 10px;
        }
    }
    /* The Close Button */
    .closeModal {
        color: white;
        position: absolute;
        top: 20px;
        right: 10px;
    }

    .closeModal:hover,
    .closeModal:focus {
        color: #999;
        text-decoration: none;
        cursor: pointer;
    }

    .mySlides-Thumbnail, .mySlides-HeroCarousel {
        display: none;
    }

    .mySlides-HeroCarousel:first-child {
        display: block;
    }

    &.modalPhotoGallery-HeroCarousel {

        .column {
            float: left;

            img {
                height: 55px !important;
                width: 55px !important;
                margin-right: 20px;
                margin-bottom: 20px;
            }
        }

        .prev, .next {
            color: #0046AD;
            text-decoration: none !important;
            top: 150px;
        }

        .prev:hover,
        .next:hover {
            color: #000 !important;
            background-color: #F1F1F1 !important;
        }

        .modalPhotoGallery-content {
            background-color: #fff; // carousel
            padding-top: 0px;
        }

        .lightbox__imgtitle, .lightbox__imgdescription {
            background-color: #F1F1F1;
            color: #000;
        }
    }

    .cursor {
        cursor: pointer;
    }
    /* Next & previous buttons */
    .prev,
    .next {
        cursor: pointer;
        position: absolute;
        top: 150px;
        width: 30px;
        height: 30px;
        color: #F1F1F1;
        font-weight: bold;
        font-size: 20px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        user-select: none;
        -webkit-user-select: none;
        padding-left: 10px;
        text-decoration: none;
    }

    .modalPhotoGallery-content .arrowContainer {
        position: relative;
    }

    .modalPhotoGallery-content .prev{
        padding-left: 7px;
        padding-right: 4px;
      
    }
    .modalPhotoGallery-content .next {
        margin-left: 568px;
        padding-left: 7px;
        padding-right: 9px;
    }

    .modalPhotoGallery-content .modalPhotoGallery p.photoGalleryDescription {
        margin-bottom: 15px;
    }

    .next {
        right: 0;
        border-radius: 3px 0 0 3px;
    }

    .closeModal {
        // width: 30px;
        color: #F1F1F1;
        font-weight: bold;
        padding: 5px;
        text-decoration: none;

        .fa-xmark {
            font-size: 25px;
        }
    }
    // /* On hover, add a black background color with a little bit see-through */
    .prev:hover,
    .next:hover,
    .closeModal:hover,
    .prev:focus,
    .next:focus,
    .closeModal:focus {
        color: #FFF;
        background-color: #515151 !important;
        text-decoration: none !important;
    }

    .prev:focus,
    .next:focus {
        outline: none;
        outline-offset: unset;
    }

    .lightbox__imgtitle, .lightbox__imgdescription {
        background-color: #000;
        margin-left: 30px;
        width: 566px;
        padding-left: 15px;
        padding-right: 15px;
        color: #fff;
    }

    .lightbox__imgdescription {
        padding-top: 5px;
        padding-bottom: 15px;
    }
    .lightbox__imgdescription.noTitle {
        padding-top: 10px;      
    }

    .lightbox__imgtitle {
        margin-bottom: 0px;
        margin-top: 0px;
        padding-top: 10px;
        padding-bottom: 0px
    }
    // .lightbox__imgdescription{
    //      padding-bottom: 30px;
    // }
    /* Number text (1/3 etc) */
    .numbertext {
        color: #f2f2f2;
        font-size: 12px;
        padding: 8px 12px;
        position: absolute;
        top: 0;
    }

    img.hover-shadow {
        transition: 0.3s;
    }

    .hover-shadow:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    /* photoGalleryDisplay-Masonry */
    &.photoGalleryDisplay-Masonry {

        img {
            max-width: 100%;
            display: block;
        }

        figure {
            margin: 0;
            margin-bottom: 10px;
        }

        &.masonContainer {
            column-count: 4;
            column-gap: 20px;
            background-color: #F1F1F1;
            padding: 20px 20px 20px 20px;
            height: auto;

            figure {
                width: 292px;
            }
        }

        &.mobileVersion figure {
            margin-bottom: 0px;
        }
    }
    /* photoGalleryDisplay-HeroCarousel */
    &.mobileVersion {
        .miniCarousel .pageCounter {
            font-weight: bold;

            .prevRightCol {
                margin-right: 15px;
            }

            .prevRightCol, .nextRightCol {
                height: 30px;
                width: 30px;
                padding: 0px;
                text-decoration: none !important;
            }

            padding: 15px;
            margin-bottom: 0px !important;

            svg {
                color: #0046AD;
            }

            svg:hover {
                color: #000;
                font-weight: bold;
            }

            .prevRightCol:hover svg[data-prefix="far"],
            .prevRightCol svg[data-prefix="fas"] {
                display: none;
            }

            .prevRightCol:hover svg[data-prefix="fas"] {
                display: inline;
            }

            .nextRightCol:hover svg[data-prefix="far"],
            .nextRightCol svg[data-prefix="fas"] {
                display: none;
            }

            .nextRightCol:hover svg[data-prefix="fas"] {
                display: inline;
            }
        }

        .miniCarousel {
            .lightbox__imgtitle, .lightbox__imgdescription {
                padding-left: 15px;
            }
        }
    }

    .lightbox__imgtitle:empty, .photoGalleryTitle:empty, photoGalleryDescription:empty, photoGalleryDate:empty {
        display: none;
    }
}
// end of .photoGallery_FF

//two sidebars
.threeColRow {
    .photoGallery_FF.photoGalleryDisplay-Masonry.masonContainer {
        column-count: 2;
        width: 629px;
        padding: 15px;
        column-gap: 15px;

        figure {
            margin-bottom: 15px;
        }
    }
}

//one sidebar
.noRightBorder, .noLeftBorder {
    .photoGallery_FF.photoGalleryDisplay-Masonry.masonContainer {
        column-count: 3;
    }
}

.mainColumnTwoColumn {
    .photoGallery_FF.photoGalleryDisplay-Masonry.masonContainer {
        column-count: 1;
        width: 332px;
        padding: 20px;

        figure {
            margin-bottom: 20px;
        }
    }
}

.noLeftBorder .mainColumnTwoColumn,
.noRightBorder .mainColumnTwoColumn,
.threeColRow .mainColumnTwoColumn {
    .photoGallery_FF.photoGalleryDisplay-Masonry.masonContainer {
        column-count: 1;
    }
}

@media (min-width: 992px) and (max-width: 1291px) {
    .threeColRow {
        .photoGallery_FF.photoGalleryDisplay-Masonry.masonContainer {
            column-count: 1;
            width: 322px;

            padding: 15px figure {
                margin-bottom: 15px;
            }
        }
        // switch herocarousel to mobile version
        .photoGallery_FF.photoGalleryDisplay-HeroCarousel {
            display: none;
        }

        .photoGallery_FF.photoGalleryDisplay-HeroCarousel.mobileVersion {
            display: block;
        }
    }
}

//tablet style for photo gallery masonry
@media (min-width: 768px) and (max-width: 991px) {
    .photoGallery_FF.photoGalleryDisplay-Masonry.masonContainer {
        column-count: 2;
        padding: 15px 15px 5px 15px;
        column-gap: 15px;

        figure {
            margin-bottom: 15px;
        }

        width: 629px;
    }

    // switch herocarousel to mobile version
    .photoGallery_FF.photoGalleryDisplay-HeroCarousel {
        display: none;
    }

    .photoGallery_FF.photoGalleryDisplay-HeroCarousel.mobileVersion {
        display: block;
    }
}

//display mobile version of photo gallery
@media(max-width: 767px) {
    .photoGallery_FF.photoGalleryDisplay-Thumbnail,
    .photoGallery_FF.photoGalleryDisplay-Masonry,
    .photoGallery_FF.photoGalleryDisplay-HeroCarousel {
        display: none;
    }

    .photoGallery_FF.photoGalleryDisplay-Thumbnail.mobileVersion,
    .photoGallery_FF.photoGalleryDisplay-Masonry.mobileVersion,
    .photoGallery_FF.photoGalleryDisplay-HeroCarousel.mobileVersion,
    #rightCol .photoGallery_FF.photoGalleryDisplay-Thumbnail.mobileVersion,
    #rightCol .photoGallery_FF.photoGalleryDisplay-Masonry.mobileVersion,
    #rightCol .photoGallery_FF.photoGalleryDisplay-HeroCarousel.mobileVersion {
        display: flex;
        // display: block;
        // margin-left: auto;
        // margin-right: auto;
        .miniCarousel {
            display: block;
            margin-right: 20px;
        }

        .prevRightCol, .nextRightCol{
            display: none !important;
        }
    }

    .noLeftBorder .mainColumnTwoColumn,
    .noRightBorder .mainColumnTwoColumn,
    .threeColRow .mainColumnTwoColumn{
        .photoGallery_FF.photoGalleryDisplay-Thumbnail.mobileVersion,
        .photoGallery_FF.photoGalleryDisplay-Masonry.mobileVersion,
        .photoGallery_FF.photoGalleryDisplay-HeroCarousel.mobileVersion {
            display: flex;

            .miniCarousel {
                display: block;
                margin-right: 20px;
            }
    
            .prevRightCol, .nextRightCol{
                display: none !important;
            }
        }
    }

    .photoGallery_FF.modalPhotoGallery {
        display: none;
    }

    .photoGallery_FF.mobileVersion .miniCarousel .pageCounter{
        margin-bottom: 0px !important;
        display: inline-block;
        width:302px;
    }

    .photoGallery_FF.mobileVersion .imageFooterThumbnails{
        display: none;
    }

    .photoGallery_FF.pageNumbers {
        display: none !important;
    }

    .photoGallery_FF.mobileVersion .column{
        margin: 0px;
    }


}


/* Responsive layout - makes a two column-layout instead of four columns */
/*@media screen and (max-width: 800px) {
    .photoGalleryDisplay-Masonry {
        .column {
            flex: 50%;
            max-width: 50%;
        }
    }
}*/
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
/*@media screen and (max-width: 600px) {
    .photoGalleryDisplay-Masonry {
        .column {
            flex: 100%;
            max-width: 100%;
        }
    }
}*/
/* end - photogallery ff with new light box*/

/* Main Column Card Sets 
______________________________________________________________________*/
.mainColumnCardSet {
    margin-bottom: 0 !important;
}
.mainColumnCardRow {
    margin: 0 !important;
}
.mainColumnCardWrapper {
    display: table-cell;
    padding: 0px 10px 10px;
    float: none;
}
.mainColumnCardWrapper h2 {
    margin: 20px 0 10px !important;
}
.mainColumnCardWrapper .mainColLinkList {
    padding: 0 !important;
    margin: 0 0 0 0px !important;
}
.mainColumnCardWrapper .mainColLinkList li {
    list-style-type: none !important;
}

@media(max-width: 767px) {
    .mainColumnCardWrapper {
        border-right: 0px !important;
        border-bottom: solid 10px $global_color_white !important;
        display: block !important;
    }
}

/* Opportunity Details - when displayed in Main Column Nav
______________________________________________________________________*/
.opportunityDetail div {
    margin-bottom: 0 !important;
}
.opportunityDetail .oppDetailGroup {
    margin-bottom: 20px !important;
}
.oppDetailLabel {
    font-weight: bold;
}

/*Chart Component  
______________________________________________________________________*/
.ChartComponent {
    width: 100%;
    float: left;
    padding-bottom: 30px;

    .title {
        margin: 30px 0;
    }

    .description {
        padding-left: 0;
    }

    // line chart
    #LineChart.highCharts {
        .leftHalf {
            float: left; 
            width: 60%;
        }

        .chartText {
            float: left;
            padding-left: 30px;
            margin-top: -18px;
        }
    
        .caption {
            float: left;
            width: 100%;
            padding-top: 30px;
        }
    }
   
    .chartButton {

        height: 35px;
        min-width: 280px;

        a {
            background-color: $global_color_primaryBlue;
            color: $global_color_white;
        }

        a:hover {
            background-color: $global_color_black;
        }

        .btn.external {
            padding-right: 46px;
            border: solid 1px $global_color_primaryBlue;
        }

        svg {
            position: relative;
            right: 38px;
            top: 2px;
            color: $global_color_white;
        }

    }
    
}

#stick-top {
    position: absolute;
}

#scrollNav {
    background-color: $global_color_white;
    font-size: 1.5em;
    font-weight: bold;
    border-radius: 0;
    overflow-x: auto;
    margin-bottom: 0;
    scrollbar-width: thin;

    #navBarWrap {
        position: relative;
        //top: 15px;
        font-size: 16px;
        display: table;
        margin-bottom: 0;

        .tab {
            &:focus {
                outline: solid !important;
                color: $global_color_linkBlue !important;
            } 
        }
    }

    .navbar-nav {
        max-width: 100%;
        white-space: nowrap;
        margin-left: 0;
        padding-bottom: 0;
        overflow: auto;
        scrollbar-width: none;

        .active {
            font-weight: bold;
            border-bottom: solid 4px $global_color_darkGray;
            color: $global_color_darkGray;
            //pointer-events: none;

            a {
                color: $global_color_darkGray;
            }
        }

        #skipTo {
            //top: 13px;
            margin-top: -12px;
            padding-top: 9px;
            pointer-events: none;

            &:hover {
                background-color: transparent;
            }

            &.topBar {
                //top: 14px;
                padding-top: 4px;
                border-top: solid 4px $global_color_black;
            }
        }
    }

    .navbar-nav > li {
        max-width: 100%;
        //display: inline-grid;
        display: inline-table;
        border-bottom: solid 4px transparent;
        margin-bottom: 0;
        list-style-type: none !important;
        float: none;
        //top: 13px;

        &:hover {
            background-color: $notification_backgroundColor_normalAlert;
        }

        a {
            padding: 11px 15px 7px 15px !important;
        }
    }

    .nav > li {
        a {
            &:hover {
                color: $global_color_darkGray;
                background-color: $notification_backgroundColor_normalAlert;
            }

            &:focus {
                background-color: $global_color_white;
                color: $global_color_black;
            }
        }
    }

    &.stick {
        margin-top: 0;
        position: fixed;
        top: 60px;
        z-index: 5;
        min-height: 0;
    }
}
 
.navbar-nav_static {
    margin-left: 20px !important;
    padding-top: 10px;
    list-style: none;

    li {
        display: inline-block;
        list-style-type: none; 
        white-space: nowrap;

        &:not(#skipTo_static):not(.first) {
            &:before {
                content: "•";
            }
        }

        a {
            padding: 10px 5px 10px 5px;
            line-height: 20px;

            &:hover {
                color: $global_color_darkGray;
                background-color: $notification_backgroundColor_normalAlert;
            }

            &:focus {
                background-color: $global_color_white;
                color: $global_color_black;
            }
        }

        &#skipTo_static {
            top: 13px;
            margin-top: -8px;
            color: $global_color_darkGray;
            font-weight: bold;

            &.topBar {
                padding-top: 4px;
                border-top: solid 4px $global_color_black;
            }
        
        }
    } 
}

.scrollNavBorder {
    position: fixed;
    left: 0;
    background-color: $global_color_white;
    z-index: 4;

    &:after {
        // content: "";
        // border-bottom: solid 1px $global_color_darkGray;
        // height: 1px;
        // width: 100%;
        // top: 44px;
        // position: absolute;
        // z-index: 6;
    }
}

@media (max-width: 991px) {
    .chartButton {
        .btn.btn-default.external {
            min-height: 32px !important;
        }
    }
}

@media (max-width: 767px) {

    #scrollNav {
        //scrollbar-width: none;

        .navbar-nav {

            #skipTo {
                top: 0px;

                @supports (-webkit-touch-callout: none) {
                    /* CSS specific to iOS devices */ 
                    top: 12px;
                    padding-top: 6px;
                }
            }
        }
    
        .navbar-nav > li {
            float: none;
            top: 0;
        } 

        &.stick {
            width: 100%;
            left: 0;
            //padding-left: 20px;
            
            #navBarWrap {
                margin-bottom: 0;
            }
        }
    }

    .ChartComponent {

        #LineChart.highCharts {

            .leftHalf {
                width: 100%;
            }

            .chartText {
                padding-left: 0;
                margin: 0 0 30px 0;
            }
        }
    }
}

//.mainColWrapper, #mainColMain {
.PortalServices, .MajorEvent, .department, .topTier, .CityWideDetail {

    .ScrollSpyContainerComponent {

        h2,
        h3,
        p,
        ul {
            max-width: 100%;
        }

        .btn {
            background-color: $global_color_primaryBlue;
            white-space: normal;

            &:hover {
                background-color: $global_color_black;
            }
        }

        .viewReportSummary {
            display: table;
            width: 100%;
            margin-bottom: 60px;

            .date {
                width: 50%;
                float: left;
                padding-left: 0;
            }

            .download {
                width: 50%;
                float: left;

                .docLink {
                    height: 23px;
                    margin-bottom: 0;
                }

                a {
                    position: absolute;
                    right: 30px;
                }
            }
        }

        #servicesFilter {
            margin: 0 0 30px 0;
            float: left;
            position: relative;

            .form-group {
                position: relative;
                margin-bottom: 0 !important;
            }

            .filterLabel {
                float: left;
                font-weight: bold;
                font-size: 28px;
                font-family: $global_fontFamily_default;
                color: #000;
                margin-right: 40px;
                position: relative;
                top: -2px;
            }

            #services_search {
                font-size: 16px;
                height: 34px;
                border-radius: 0;
                width: 340px;
                /*no autocomplete*/
                float: left;
            }

            #clear_button {
                position: absolute;
                right: 44px;
                z-index: 1000;
                top: 3px;
                cursor: pointer;
                display: block;
                float: left;

                svg {
                    font-size: 25px;
                }
            }

            #filter_button {
                color: #fff;
                background-color: #003da5;
                border-radius: 0;
                position: absolute;
                top: 0px;
                padding: 5px;
                right: 0px;
                width: 34px;
                height: 34px;
                display: block;
                float: left;
                z-index: 5;
                cursor: pointer;

                svg {
                    font-size: 19px;
                    top: 3px;
                    left: -2px;
                }

                .fa-magnifying-glass {
                    &:before {
                        top: 4px;
                        position: relative;
                        right: -3px;
                    }
                }
            }

            #no_result {
                margin-top: 30px;

                p {
                    font-size: 18px;
                    font-weight: bold;
                }

                .no_result_back {
                    cursor: pointer;
                }
            }
        }

        #skipToDiv {
            width: 100%;
            float: left;
            //margin-bottom: 40px;
        }

        .reportsSection {
            margin-bottom: 0;
            display: block;
            float: left;
            width: 100%;

            p, ul, li {
                margin-bottom: 10px;
            }

            .subTitle {
                margin: -20px 0 30px 0;
            }

            .charts {

                h2 {
                    border-bottom: solid 1px #ccc;
                    margin-bottom: 40px;
                }
            }

            .reportsRow {
                margin-bottom: 30px;
                float: left;
                width: 100%;

                h2, h3, h4, h5 {
                    margin-top: 0;
                }

                div:not(.shape):not(.community) {
                    margin-bottom: 0px;
                }

                .community {
                    width: 100%;
                    float: left;
                    margin-bottom: 60px;
                }

                .left, .right, .photoLeft, .captionRight {
                    float: left;
                    width: 50%;
                    padding: 0 10px 0 10px;
                }
            }

            .mainColumnTwoColumn {

                .padTop {
                    min-height: 300px;
                }
            }
        }

        .bottomDocumentLinks {
            float: left;
            width: 100%;
            margin-bottom: 60px;
            border-top: solid 1px #ccc;

            .buttonLink {
                float: right;
                margin-left: 10px;
                margin-top: 30px;
            }
        }

        .scrollEnd {
            float: left;
        }

        @media (max-width: 575px) {
            .reportsSection {
                padding: 0 10px;

                .tabsContainer {
                    margin: 0 -10px;

                    .tabContent {
                        overflow-x: hidden;
                    }
                }
            }
        }
    }
}

@media (max-width: 1291px) {

    .ScrollSpyContainerComponent {

        .reportHighlightIconDiv {
            svg {
                width: 100%;
                margin: 0 0 20px 0;
            }
        }
        
        .reportHighlightIconDiv {
            margin-bottom: 60px;
            float: left;
        }
        
        .mainColumnTwoColumn {
            .padTop {
                min-height: 380px;
            }
        }
        
        #scrollNav {
            .navbar-nav {
                overflow: auto;
            }
        }
        
    }

}

@media (max-width: 991px) {
    
    .Reports {

        .col-sm-9 {
            width: 75%;
        }

    }

    .ScrollSpyContainerComponent {

        .col-sm-9 {
            width: 75%;
        }
        
        h2,
        h3,
        p,
        ul {
            max-width: 580px;
        }
        
        .navbar-nav {
            max-width: 100%;
        }
        
    }

}

@media (max-width: 767px) {
    
    .Reports {

        .col-sm-9 {
            width: 100%;
        }

    }

    .ScrollSpyContainerComponent {

        .col-sm-9 {
            width: 100%;
        }
        
        .buttonLink {

            width: 100%;

            a {
                float: right;
                margin-bottom: 20px;
            }
        }

    }

    .navbar-nav {
        padding-left: 10px;
    }

}

@media (min-width: 576px) and (max-width: 767px) {

    .PortalServices, .MajorEvent, .department, .topTier, .CityWideDetail {

        .ScrollSpyContainerComponent {

            #servicesFilter {
                margin-bottom: 30px;
                width: 100%;

                #services_search {
                    width: 70%;
                }

                #clear_button {
                    position: relative;
                    left: -33%;

                    svg {
                        right: 0;
                    }
                }

                #filter_button {
                    position: relative;
                }

                #no_result {
                    margin: 30px 0 0 0;
                    float: left;
                }
            }
        }

        .navbar-nav {
            overflow: hidden !important;
        }
    }
}

@media (max-width: 575px) {

    .PortalServices, .MajorEvent, .department, .topTier, .CityWideDetail {

        .ScrollSpyContainerComponent {

            #servicesFilter {
                padding: 0 10px;
                margin: 0 0 30px 0;
                width: 100%;

                .filterLabel {
                    font-size: 24px;
                }

                #services_search {
                    width: 88%;
                    font-size: 15px;
                }

                #filter_button {
                    position: relative;
                }

                #clear_button {
                    right: 20%;
                    top: 51px;
                    position: absolute;
                }

                #no_result {
                    margin: 30px 0 0 0;
                    float: left;
                }
            }

            .servicesSection, .reportsSection {

                &.paddingTop100 {
                    padding-top: 100px;
                }

                &.paddingTop160 {
                    padding-top: 160px;
                }

                &.paddingTop200 {
                    padding-top: 200px;
                }

                &:first-child {
                    &.removeTopMargin {
                        margin-top: 40px;
                    }
                }

                .titleContainer {
                    h2 {
                        float: left;
                        width: 75%;
                    }
                }
            }

            .reportsSection {
                &:not(:first-child) {
                    .charts {
                        margin-top: 40px;
                    }
                }
            }
        }

        #scrollNav {
            z-index: 104;

            &.stick {
                top: 124px;
            }

            .navbar-nav {
                scrollbar-width: none;
                //padding-left: 18px;
            }
        }
    }
}

/* Number Highlight Component 
______________________________________________________________________*/

/* Number Highlight Layout  */
.numberHighlightComponentList {
    .numberHighlightComponent {
        width: 280px;
        margin: 15px;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        text-align-last: initial;
    }
}

.numberHighlightComponentList.Green .shape {
    background: $global_color_green !important;
}

.numberHighlightComponentList.Blue .shape {
    background: $global_color_primaryBlue !important;
}

.numberHighlightComponentList.Gold .shape,
.numberHighlightComponentList.Bronze .shape {
    background: $global_color_bronze !important;
}

.numberHighlightComponentList.Gray .shape {
    background: $global_color_mediumDarkGray !important;
}

@media(max-width:767px) {
    .numberHighlightComponentList {
        text-align: center;
    }
}

/* Full Width Page */
#leftMainRight > .col-md-12 .numberHighlightComponentList {
    @media(min-width:1292px) {
        &.count-4,
        &.count-3 {
            text-align: justify;
            text-align-last: justify;
        }
    }
}

/* Number Highlight Styles */

.numberHighlightComponent {
    .shape {
        background: $global_color_green;
        width: 180px;
        height: 180px;
        border-radius: 9001px;
        margin: 0px auto 30px auto;
        padding: 5px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        h3, p {
            color: $global_color_white;
            font-family: $global_fontFamily_default;
        }

        h3.number {
            margin: 0px;

            span.subtitle {
                display: block;
                font-size: 13px;
                font-weight: 600;
                line-height: 18px;
                word-break: break-word;
                padding-left: 20px;
                padding-right: 20px;
                font-family: 'Open Sans';
                text-transform: uppercase;
            }

            span.numberTitle {
                font-size: 33px;
                line-height: 48px;
                margin-bottom: 0px;
            }
        }
    }

    .description {
        line-height: 1.5em;
        margin: 0px auto;
        max-width: 300px;
        text-align: left;
        overflow: hidden;
        max-height: calc(6 * 1.5em);

        ul li {
            margin-bottom: 1px !important;
        }
    }
}

#mainColMain .numberHighlightComponent,
.NumberHighlightComponentList.colorBlock {
    .description {
        p {
            height: 125px;
            margin-bottom: 30px;
            line-height: 1.5em;
        }

        ul {
            padding-bottom: 0px;
        }
    }
}

@media only screen and (max-width: 660px) {
    #mainColMain .numberHighlightComponent {
        margin-bottom: 15px;
    }
}


/* Visual Highlight List & Component 
______________________________________________________________________*/
.VisualHighlightComponentList {
    float: left;
    width: 100%;

    .visualListContainer {

        .primaryContent {
            margin: auto;
            width: 50%;

         svg {
                color: #007749;
                width: 19%;
                font-size: 38px;
                margin: 0 0 10px 6px;
            }
        }
    }

    .visualListContainer.multiple {
        .primaryContent {
            width: 100%;

            svg {
                color: #007749;
                width: 19%;
                font-size: 38px;
                margin: 0 0 10px 6px;
            }
        }
    }

}

.mainColumnTwoColumn .visualListContainer {
    //fix main col 2 col visual container overlap issue
    .primaryContent {
        margin: auto;
        width: 100%;
        clear: both;

        svg {
            width: 19%;
            font-size: 38px;
            margin: 0 0 10px 6px;
        }
    }

    .primaryContent .col-md-3 {
        width: 50% !important;
    }

    .primaryContent .col-sm-3 {
        width: 100% !important;
    }
}

.visualListContainer.Green svg {
    color: #007749 !important;
}

.visualListContainer.Blue svg {
    color: #0046AD !important;
}

.visualListContainer.Gold svg {
    color: $global_color_bronze !important;
}

.visualListContainer.Gray svg {
    color: #565A5C !important;
}

@media (max-width: 991px) {
    .VisualHighlightComponentList {
        width: 100%;
    }
}

@media (max-width: 767px) {

    .VisualHighlightComponentList {
        width: 100%;
    }
}

/* Vertical Timeline and Time Period Component 
______________________________________________________________________*/
.timePeriod, .timePeriodBlack {
    .description, .descriptionLast {
        background: $global_color_lightWhite;
        max-width: 580px;
        margin-left: -81px;
        padding: 15px 15px 15px 15px;
        margin-bottom: 70px;
        background: #FFFFFF;
        border: 1px solid #DBDADA;
        color: #000;
    }

    .periodTitle h3 {
        font-size: 22px;
        margin-top: 0px;
        padding-right: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .row {
        margin-bottom: 20px !important;
    }

    .descriptionLast::before {
        background: none;
    }
}

.timePeriod {

    .fa-check {
        border-radius: 15px;
        height: 30px;
        width: 30px;
        color: $global_color_white;
        background-color: #007749;
        padding: 5px;
        margin-left: -30px;
    }
    // vertical line
    .description::before {
        content: '';
        position: absolute;
        height: calc(100% + 30px);
        width: 3px;
        background: #007749;
        margin-top: 31px;
        margin-left: -49px;
    }
}

.timePeriodBlack {

    .fa-circle {
        color: #0046ad;
        margin-left: -23px;
    }
    // vertical line
    .description::before {
        content: '';
        position: absolute;
        height: calc(100% + 47px);
        width: 3px;
        background: black;
        margin-top: 20px;
        margin-left: -49px;
    }
}

//if the background color is gray change the color of the timeline components
.Gray .timePeriod .fa-check,
.Charcoal .timePeriod .fa-check,
.Green .timePeriod .fa-check,
.Navy .timePeriod .fa-check {
    background-color: $global_color_bronze;
}

.Gray .timePeriodBlack .fa-circle {
    color: #e1f5fe;
    background-color: #666;
}

.Charcoal .timePeriodBlack .fa-circle {
    color: #e1f5fe;
    background-color: #333f48;
}

.Green .timePeriodBlack .fa-circle {
    color: #e1f5fe;
    background-color: #063;
}
.Navy .timePeriodBlack .fa-circle {
    color: #e1f5fe;
    background-color: #0D223F;
}

//vertical line - check icon
.Gray .timePeriod .description::before,
.Charcoal .timePeriod .description::before,
.Green .timePeriod .description::before,
.Navy .timePeriod .description::before {
    background: #fff8e1; //light yellow
}

//vertical line - circle icon
.Gray .timePeriodBlack .description::before,
.Charcoal .timePeriodBlack .description::before,
.Green .timePeriodBlack .description::before,
.Navy .timePeriodBlack .description::before {
    background: #e1f5fe; //light blue
}

.noRightBorder .mainColumnTwoColumn .timePeriod .description,
.noRightBorder .mainColumnTwoColumn .timePeriod .descriptionLast,
.noRightBorder .mainColumnTwoColumn .timePeriodBlack .description,
.noRightBorder .mainColumnTwoColumn .timePeriodBlack .descriptionLast,
.noLeftBorder .mainColumnTwoColumn .timePeriod .description,
.noLeftBorder .mainColumnTwoColumn .timePeriod .descriptionLast,
.noLeftBorder .mainColumnTwoColumn .timePeriodBlack .description,
.noLeftBorder .mainColumnTwoColumn .timePeriodBlack .descriptionLast {
    max-width: 320px;
}

#mainColMain.col-md-8 .timePeriod .description,
#mainColMain.col-md-8 .timePeriod .descriptionLast,
#mainColMain.col-md-8 .timePeriodBlack .description,
#mainColMain.col-md-8 .timePeriodBlack .descriptionLast {
    max-width: 480px;
}

.mainColumnTwoColumn .timePeriod .description,
.mainColumnTwoColumn .timePeriod .descriptionLast,
.mainColumnTwoColumn .timePeriodBlack .description,
.mainColumnTwoColumn .timePeriodBlack .descriptionLast {
    max-width: 480px; //180
}

@media (min-width: 992px) and (max-width: 1291px) {
    .timePeriod, .timePeriodBlack {
        .description, .descriptionLast {
            margin-left: -24px;
        }

        .description::before {
            height: calc(100% + 50px);
        }

        .fa-check {
            margin-left: 0px;
        }

        .fa-circle {
            margin-left: 6px;
        }
    }

    .timePeriodBlack {
        .description::before {
            height: calc(100% + 63px);
        }
    }
    .mainColumnTwoColumn .timePeriod .description,
    .mainColumnTwoColumn .timePeriod .descriptionLast,
    .mainColumnTwoColumn .timePeriodBlack .description,
    .mainColumnTwoColumn .timePeriodBlack .descriptionLast {
        max-width: 320px; //110px
    }
    .noRightBorder .mainColumnTwoColumn .timePeriod .description,
    .noRightBorder .mainColumnTwoColumn .timePeriod .descriptionLast,
    .noRightBorder .mainColumnTwoColumn .timePeriodBlack .description,
    .noRightBorder .mainColumnTwoColumn .timePeriodBlack .descriptionLast,
    .noLeftBorder .mainColumnTwoColumn .timePeriod .description,
    .noLeftBorder .mainColumnTwoColumn .timePeriod .descriptionLast,
    .noLeftBorder .mainColumnTwoColumn .timePeriodBlack .description,
    .noLeftBorder .mainColumnTwoColumn .timePeriodBlack .descriptionLast {
        max-width: 220px;
    }
}

@media (max-width: 991px) {
    .timePeriod, .timePeriodBlack {
        .description::before {
            margin-left: -48px;
            margin-top: -5px;
            height: calc(100% + 83px);
        }

        .periodTitle h3 {
            margin-left: 18px;
            font-size: 24px;
            justify-content: flex-start;
        }

        .description {
            margin-bottom: 90px;
            margin-left: 58px;
            width: 65%;
        }

        .descriptionLast {
            margin-bottom: 0px;
            margin-left: 58px;
            width: 65%;
        }

        .fa-check {
            margin-left: 0px;
        }

        .fa-circle {
            margin-left: 8px;
        }
    }

    .timePeriodBlack {
        .description::before {
            margin-top: -15px;
            height: calc(100% + 98px);
        }
    }
    .mainColumnTwoColumn .timePeriod .description,
    .mainColumnTwoColumn .timePeriod .descriptionLast,
    .mainColumnTwoColumn .timePeriodBlack .description,
    .mainColumnTwoColumn .timePeriodBlack .descriptionLast {
        max-width: 580px !important;
    }
}

@media (max-width: 767px) {
    .timePeriod, .timePeriodBlack {
        .description, .descriptionLast {
            width: 80%;
        }
    }
}

/* Report Highlights Component 
___________________________________________________________________*/
.mainColWrapper, #mainColMain, .ScrollSpyContainerComponent.colorBlock {

    .scrollSpyContainer {

        .servicesSection {

            &:first-child {
                &.addPortalTopMargin {
                    margin-top: 80px;
                }

                &.addDepartmentTopMargin {
                    margin-top: 106px;
                }
            }
        }

        .reportsSection:first-child {
            &.addPortalTopMargin {
                margin-top: 80px;
            }

            &.addDepartmentTopMargin {
                margin-top: 106px;
            }
        }

        .reportsSection {
            .mainColumnTwoColumn {
                .twoColLeft, .twoColRight {
                    .padTop {
                        min-height: 0px;
                    }

                    .reportHighlightIconDiv {
                        .left, .right {
                            margin-bottom: 30px;
                        }

                        .right {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .ReportHighlightIconComponentList, .ReportHighlightIconComponent {
        display: table;
        width: 100%;
        margin: 0 10px;

        .reportHighlightIconDiv {
            .reportHighlightContent {
                .left, .right {
                    float: left;
                    padding: 10px;
                    padding-left: initial;
                    margin-bottom: auto;
                    display: flex;
                    flex-wrap: wrap;

                    &:not(.right) svg {
                        color: $global_color_green;
                        font-weight: 700;
                        font-size: 14px;
                        width: 55px;
                        height: 55px;
                        margin: -2px 30px 0 0;
                        float: left;
                    }

                    h3 {
                        margin-top: auto;
                        margin-bottom: auto;
                        display: flex;
                        width: 70%;
                    }

                    p {
                        float: left;
                        margin-top: -3px;
                        width: 100%;
                        margin-bottom: 10px;
                    }

                    li {
                        margin-bottom: 0;
                        list-style-type: circle;
                    }
                }
            }

            .left {
                width: 420px;
            }

            .right {
                width: 470px;
            }
        }
    }

    .mainColumnTwoColumn {
        .twoColLeft, .twoColRight {
            .reportHighlightIconDiv {
                display: inline-block;
                width: 100%;
                margin-bottom: 0px;

                .reportHighlightContent {
                    width: 94%;
                    margin-bottom: 20px;

                    .left {
                        display: flex;
                        width: 100%;


                        svg {
                            color: $global_color_green;
                            font-weight: 700;
                            font-size: 14px;
                            width: 55px;
                            height: 55px;
                            margin: 1px 20px 0 0;
                            float: left;
                        }


                        h3 {
                            margin-top: auto;
                            margin-bottom: auto;
                        }
                    }
                }

                p {
                    float: left;
                    margin-top: -12px; // adjusted the spacing.
                }
            }
        }
    }
}

#leftMainRight.threeColRow div#mainColMain.col-md-8 .mainColumnTwoColumn .reportHighlightIconDiv .reportHighlightContent {
    width: 100%;
}

.reportHighlightIconComponentList.Green .left svg {
    color: $global_color_green !important;
}

.reportHighlightIconComponentList.Blue .left svg {
    color: $global_color_primaryBlue !important;
}

.reportHighlightIconComponentList.Gold .left svg,
.reportHighlightIconComponentList.Bronze .left svg {
    color: $global_color_bronze !important;
}

.reportHighlightIconComponentList.Gray .left svg {
    color: $global_color_mediumDarkGray !important;
}


@media (max-width: 991px) {
    .ReportHighlightIconComponentList, .ReportHighlightIconComponent {
        width: 100%;

        .reportHighlightIconDiv {

            .left, .right {
                width: 100% !important;
                margin-bottom: 0 !important;
            }
        }

        .reportHighlightContent .left svg {
            margin-right: 25px !important;
        }
    }
}

/* Project timeline component */
.ProjectTimelineComponent {

    .project-timeline {
        list-style: none;
        margin: 0 !important;
        padding: 0 !important;
        width: 100%;
        display: table;
        table-layout: fixed;
        overflow: hidden;
        max-width: 100% !important;

        li {
            width: 100%;
            display: table-cell;
            margin: 0;
            padding: 0;
            height: 32px;
            text-align: center;
            border-bottom: 8px solid #dedede;
            text-transform: lowercase;

            &.done {
                border-color: #a0a0a0;
            }
        }
    }

    @media(max-width: 440px) {
        .project-timeline {
            display: block;

            li {
                display: block;
                padding: 0 0 0 8px;
                height: 48px;
                text-align: left;
                border-left: 8px solid #dedede;
                border-bottom: 0;
                line-height: 1.0em;
                margin-bottom: 0 !important;
            }
        }
    }

}


/* New tabs styling */
#mainColMain, #home, .ScrollSpyContainerComponent {
    .viewTabs {
        //float: left;

        h2.tabsList-title {
            padding: 30px 0;
            margin: 0;
            border-bottom: none;
            max-width: 100%;
        }

        .tabsContainer {
            [class^='stick-top'] {
                position: absolute;
            }
        
            .stick-top {

                &.stick {
                    padding-top: 0px; 
                }
        
            }

            .tab {  
        
                background-color: #fff;
                font-weight: bold;
        
                &.tabsWrapper {
                    color: #888;
                    display: block;
        
                    /* Style the tabs */
                    .navbarContainer {
                        margin-bottom: 0;
                        white-space: nowrap;
                        margin-left: 0;
                        padding-bottom: 0;
                        scrollbar-width: none;
                        border: none;
                        border-bottom-width: 0;
                        border-bottom-left-radius: 0px;
                        border-bottom-right-radius: 0px;
        
                        &.scrollNav {
                            overflow-x: hidden;
                            font-size: 16px;  
                        }
        
                        ul {
                            list-style-type: none;
                            margin-left: 20px;
                        }
        
                        a {
                            &.tab {
                                float: left;
                                cursor: pointer;
                                padding: 15px 15px 11px 15px;
                                border-bottom: solid 4px transparent;
                                color: #666;
                                font-weight: bold;
                                min-width: 100px;
                                max-width: 160px;
                                text-align: center;
                                display: table;
                                white-space: normal;
                                line-height: 18px;
                                height: 100%;
        
                                &:hover {
                                    background-color: #FFF8E1;
                                    color: #333;
                                }
        
                                &.active {
                                    color: #333;
                                    border-bottom: 4px solid #F7C305; 
                                    padding-bottom: -4px;
                                    text-decoration: none;
                                }
        
                                &:focus {
                                    outline: solid !important;
                                    color: $global_color_linkBlue !important;
                                } 
                            }
        
                            p {
                                margin-bottom: 0;
                                display: table-cell;
                                vertical-align: middle;
                            }
                        }
        
                        &.stick {
                            margin-top: 0;
                            position: fixed;
                            top: 60px;
                            z-index: 1000;
                            min-height: 0;  
                            border-bottom: solid 1px #ccc;
                            scrollbar-width: none; 
                        } 
        
                        div {
                            float: left;
                        }
        
                        .navBarWrap {
                            display: grid !important;
                            grid-auto-flow: column;
                            margin-bottom: 0;
                            display: block;
                            overflow: hidden;
                            position: relative;
                            background: #fff;
                        } 
                    }
        
                    .navbar {
                        background-color: #fff;
                    }
        
                    .gradient-right, .gradient-left {
                        width: 100px;
                        height: 100%;
                        margin-bottom: 0;
                        position: absolute;
                        z-index: 1;
                        left: 0; 
                        bottom: 0; 
                        cursor: pointer;
                        pointer-events: none;
        
                        &:after {
                            z-index: 5;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            content: "";
                            top: 0;
                            bottom: 15px;
                        }
        
                        svg {
                            width: 7px;
                            height: 100%;
                            position: absolute;
                            z-index: 10;
                            color: #003da5;
                            pointer-events: auto;
                        }
                    }
        
                    .gradient-left {
                        &:after {
                            right: 0; 
                            background-image: linear-gradient(to left, rgba(255,255,255,0), #fff 85%);
                        }
        
                        .fa-chevron-left {
                            left: 0;
                        }
                    }
        
                    .gradient-right {
                        &:after {
                            right: 0;
                            background-image: linear-gradient(to right, rgba(255,255,255,0), #fff 85%);
                        }
        
                        .fa-chevron-right {
                            right: 1px;
                        }
                    }
        
                    .content {
                        color: #000;
                        font-weight: normal; 
        
                        /* Style the tab content */
                        .tabContent {
                            display: grid;
                            padding: 30px 20px;
                            border: 1px solid #DADAD6;
                            color: #000;
                            font-weight: normal;
                            min-height: 300px;
        
                            h2, h3, h4, h5, h6, div, span, ul, li, p, a {
                                max-width: 100%;
                            }
        
                            h2, h3, h4, h5, h6 {
                                margin-top: 0;
                            }

                            * {
                                white-space: normal;
                            }

                            .servicesSection {
                                margin-right: -22px;

                                &.addDepartmentTopMargin {
                                    margin-top: 0;
                                }
                            }
                            
                            .accordsFa {
                                margin-bottom: 20px;

                                .accordion-toggle {
                                    margin-top: 15px;
                                }
                            }
                        } 
                    }
                }
            }
        
            @media (min-width: 576px) and  (max-width: 767px) {
                .tab.tabsWrapper { 
        
                    .scrollNav {
                        padding-left: 0;
                    }
                }
            }
        
            @media (max-width: 575px) {
                
                [class^='stick-top'] {
                    position: relative;

                    &.stick {
                        padding-top: 60px; 
                        position: absolute;
                    }
                }
                
                .tab.tabsWrapper {  
                    .tabcontent {
                        padding: 15px;
                    }
        
                    .navbarContainer {
                        &.stick {
                            width: 100%;
                            //padding-top: 62px;
                        }
        
                        &.scrollNav {
                            padding-left: 0;
                            overflow-x: auto;
                        }
                    } 
                }
            }
        }
    }
}

/*#home {
    .MainColumnTabListComponent {
        .viewTabs {
            .tabContent {
                padding: 25px 10px;

                .rightWrapper {
                    padding: 0 20px;

                    .linklist {
                        h2 {
                            a {
                                font-size: 16px;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }
    }
}*/

//============ Two Column Image and Text Grid Component ==============

#mainColMain .TwoColumnImageandTextGridComponent {
    width: 100%;
    display: table;

    .twoColImageTextGridContainer {
        float: left;
    }

    .imageTextGridContainerWrapper {
        display: flex;
        flex-wrap: wrap;
        width: 102.5%;
        margin: -10px;
        float: left;
    }

    .headTitle {
        margin-bottom: -11px;

        .headtitleName {
            margin-top: 1px;
            border-bottom: none;
        }
    }

    .headDescription {
        margin-bottom: -25px;
        min-height: 33px;
    }

    .twogridImage {
        max-height: 250px;
        min-height: 250px;
        width: 98.12%;
        object-fit: cover;
    }

    .titleWithLink, .titleWithNoLink {
        margin-top: -11px;
    }

    .itemBody {
        margin-top: -31px;
        margin-bottom: -33px !important;
        width: 98.12%;
    }

    .linktoImageandText:hover {
        .titleWithLink {
            h3,
            h2 {
                text-decoration: underline;
                cursor: pointer;
                display: table;
            }
        }
    }

    .titleWithLink {
        h3,
        h2 {
            color: rgb(0, 70, 173);
        }
    }

    .nameOfTitleWithLink.externalLink {
        svg {
            font-size: 15px;
            margin-left: 10px;
            vertical-align: middle;
        }
    }

    .nameOfTitleWithNoLink, .nameOfTitleWithLink {
        border-bottom: none;
    }
}

#mainColMain .TwoColumnImageandTextGridComponent div {
    margin-bottom: 30px;
}

// If there is Left and Right sidebar on the full length width.
#leftMainRight.threeColRow div#mainColMain.col-md-8 .TwoColumnImageandTextGridComponent {
    .twogridImage {
        width: 97%;
    }

    .imageTextGridContainerWrapper {
        width: 106%;
    }

    .titleWithLink, .titleWithNoLink {
        margin-right: 4px;
    }

    .itemBody {
        padding-right: 7px;
    }
}

@media screen and (min-width: 286px) and (max-width: 991px) {
    #leftMainRight.threeColRow div#mainColMain.col-md-8 .TwoColumnImageandTextGridComponent,
    #leftMainRight.noLeftBorder div#mainColMain.col-md-9 .TwoColumnImageandTextGridComponent,
    #leftMainRight.noRightBorder div#mainColMain.col-xs-12 .TwoColumnImageandTextGridComponent,
    #leftMainRight.row div#mainColMain.col-xs-12 .TwoColumnImageandTextGridComponent {
        .twogridImage {
            width: 100%;
        }
    }
}

@media screen and (min-width: 662px) and (max-width: 767px) {
    #leftMainRight.threeColRow div#mainColMain.col-md-8 .TwoColumnImageandTextGridComponent,
    #leftMainRight.noLeftBorder div#mainColMain.col-md-9 .TwoColumnImageandTextGridComponent,
    #leftMainRight.noRightBorder div#mainColMain.col-xs-12 .TwoColumnImageandTextGridComponent,
    #leftMainRight.row div#mainColMain.col-xs-12 .TwoColumnImageandTextGridComponent,
    .TwoColumnImageandTextGridComponent.colorBlock {
        .imageandTextGridContent {
            width: 100%;
        }
    }
}

@media screen and (max-width:1292px) and (min-width: 992px) {
    #leftMainRight.noLeftBorder div#mainColMain.col-md-9 .TwoColumnImageandTextGridComponent,
    #leftMainRight.noRightBorder div#mainColMain.col-xs-12 .TwoColumnImageandTextGridComponent,
    #leftMainRight.row div#mainColMain.col-xs-12 .TwoColumnImageandTextGridComponent {

        .imageTextGridContainerWrapper {
            width: 106%;
        }
    }

    #leftMainRight.threeColRow div#mainColMain.col-md-8 .TwoColumnImageandTextGridComponent {
        .imageandTextGridContent {
            width: 100%
        }
    }
}
//============ translation tab component and translation page component ==============

.TranslationTabComponent {
    border: 2px #F1F1F1 solid;
}

.TranslationPageComponent {
    margin-bottom: 20px;
}

#mainColMain div.translationComponentFullPage_ff, div.translationComponentFullPage_ff {
    margin-bottom: 0px;
}

.TranslationTabComponent .btn-link,
.TranslationPageComponent .btn-link {
    background-color: #f1f1f1 !important;
    color: #000000 !important;
    font-weight: normal !important;
    font-size: 16px !important;
    border-radius: 3px !important;
    height: 27px !important;
    padding: 3px 10px 3px 10px !important;
    border: none !important;
}

#mainColMain .TranslationPageComponent a.btn-link:visited {
    background-color: #f1f1f1 !important;
    color: #000000 !important;
    font-weight: normal !important;
    box-shadow: inset 0 0 1px 2px #000 !important;
    border: none !important;
}

.TranslationTabComponent .btn-link:hover,
.TranslationPageComponent .btn-link:hover {
    background-color: #f1f1f1 !important;
    color: #000000 !important;
    font-weight: bold !important;
    box-shadow: inset 0 0 1px 2px #000 !important;
    border: none !important;
}

.TranslationTabComponent .btn-link:focus,
.TranslationPageComponent .btn-link:focus {
    background-color: #f1f1f1 !important;
    color: #000000 !important;
    font-weight: bold !important;
    border: none !important;
    box-shadow: inset 0 0 1px 2px #000 !important;
}

.TranslationTabComponent .btn-link:active,
.TranslationPageComponent .btn-link:active {
    background-color: #0046ad !important;
    color: #ffffff !important;
    font-weight: bold !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.TranslationTabComponent .myactivebutton,
#mainColMain .TranslationPageComponent a.btn-link.myactivebutton,
.TranslationPageComponent a.btn-link.myactivebutton {
    background-color: #0046ad !important;
    color: #ffffff !important;
    font-weight: bold !important;
    text-decoration: none !important;
    border: none !important;
    box-shadow: none !important;
}

.TranslationTabComponent .btn-link.myactivebutton:hover,
#mainColMain .TranslationPageComponent a.btn-link.myactivebutton:hover,
.TranslationPageComponent a.btn-link.myactivebutton:hover {
    background-color: #f1f1f1 !important;
    color: #000000 !important;
    font-weight: bold !important;
    box-shadow: inset 0 0 1px 2px #000 !important;
    border: none !important;
    outline: 1px;
}

.TranslationTabComponent .btn-link.myactivebutton:focus,
#mainColMain .TranslationPageComponent a.btn-link.myactivebutton:focus,
.TranslationPageComponent .btn-link.myactivebutton:focus {
    background-color: #0046ad !important;
    color: #ffffff !important;
    font-weight: bold !important;
    text-decoration: none !important;
    border: none !important;
    box-shadow: inset 0 0 1px 2px #000 !important;
    outline: none !important;
}

.TranslationTabComponent .btn-link.myactivebutton:active,
.TranslationPageComponent .btn-link.myactivebutton:active {
    background-color: #0046ad !important;
    color: #ffffff !important;
    font-weight: bold !important;
    text-decoration: none !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.mobileDDContainer {
    display: none;
}

.TranslationTabComponent ul.languagePills {
    margin: 0 !important;
    padding: 20px 20px 5px 20px !important;
    display: inline-block;
}

.TranslationTabComponent ul.languagePills > li {
    margin-right: 10px;
    float: left;
    list-style-type: none !important;
    margin-bottom: 10px !important;
}

.TranslationPageComponent ul.languagePills {
    margin: 0 !important;
    padding: 0px !important;
    display: inline-block;
}

.TranslationPageComponent ul.languagePills > li {
    margin-right: 10px;
    float: left;
    list-style-type: none !important;
}

#mainColMain .TranslationPageComponent ul.languagePills li {
    margin-bottom: 10px;
}

#mainColMain .TranslationPageComponent ul.languagePills li:last-child {
    margin-bottom: 10px;
}

.EnTranslation {
    display: block;
}

.translationTabComponent_ff .nonmobileView, .nonmobileView {
    padding-left: 20px;
    padding-top: 12px;
}

.translationTabComponent_ff .translationContent {
    padding-left: 20px;
    padding-top: 20px;
    border-top: 2px #F1F1F1 solid;
    padding-right: 20px;
}

.translationTabComponent_ff .translationDisclaimer {
    font-style: italic;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-right: 20px;
    margin: 0px !important;
}

.TranslationPageComponent .translationDisclaimer {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 20px;
    font-style: italic;
}

#mainColMain .TranslationPageComponent div.translationDisclaimer,
#mainColMain .translationDisclaimer p,
#mainColMain .translationDisclaimer div,
.translationDisclaimer div
.translationDisclaimer p {
    margin-bottom: 0px !important;
}

#mainColMain .TranslationPageComponent .translationDisclaimer p {
    padding-top: 8px;
    padding-bottom: 10px;
    border-bottom: 2px #F1F1F1 solid;
}

.ScrollSpyContainerComponent .TranslationTabComponent .translationDisclaimer p {
    margin-bottom: 0px !important;
}

.ScrollSpyContainerComponent .TranslationTabComponent .translationContent {
    margin-bottom: 20px !important;
}

#mainColMain .TranslationTabComponent .translationDisclaimer p,
#mainColMain .TranslationTabComponent ul,
#mainColMain .TranslationTabComponent .translationContent p,
.TranslationTabComponent .translationDisclaimer p,
.TranslationTabComponent ul,
.TranslationTabComponent .translationContent p {
    max-width: 100%;
}

/*fixes for colorblocks issues*/
.TranslationTabComponent.colorBlock {
    padding: 0px !important;
}

.TranslationTabComponent.colorBlock .translationDisclaimer p {
    margin-bottom: 0px !important;
}

.TranslationTabComponent.colorBlock .translationContent p {
    padding-bottom: 10px !important;
}

@media screen and (min-width: 768px) {
    .mobileDDLTranslate, .mobileDDContainer {
        display: none;
    }

    .nonmobileView {
        display: block;
    }

    .TranslationPageComponent ul.languagePills {
        display: inline-block;
    }
}

@media screen and (max-width: 767px) {
    .TranslationTabComponent .mobileDDContainer {
        display: block;
        padding-left: 20px;
        padding-top: 15px;
        margin: 0px !important;
        padding-bottom: 15px;
    }

    .TranslationPageComponent .mobileDDContainer {
        display: block;
        padding: 0px;
        margin: 0px !important;
    }

    .nonmobileView, .TranslationTabComponent ul.languagePills,
    .TranslationPageComponent ul.languagePills {
        display: none;
    }

    .TranslationTabComponent ul,
    .TranslationPageComponent ul {
        display: none;
    }

    .TranslationTabComponent .translationDisclaimer {
        border-bottom: none;
        display: block;
        padding: 0px 15px 15px 15px !important;
    }

    .TranslationPageComponent .translationDisclaimer {
        border-bottom: none;
        display: block;
        padding-bottom: 0px;
        padding-right: 0px;
    }

    #mainColMain .TranslationPageComponent .translationDisclaimer p,
    .TranslationPageComponent .translationDisclaimer p {
        padding-top: 15px;
        padding-bottom: 0px;
        border-bottom: none;
    }

    .translationComponentFullPage_ff {
        border: 2px #F1F1F1 solid;
        padding: 15px 15px 15px 15px;
    }

    .TranslationTabComponent .translationContent {
        border-top: 2px solid #F1F1F1;
        padding-top: 15px !important;
    }

    .translationTabComponent_ff {
        padding: 0px;
    }

    .mobileDDTopRow {
        background-color: #f1f1f1;
        border-radius: 3px;
        width: 250px;
        padding: 10px;
        margin-bottom: 0px !important;
        margin-left: 0px;
        margin-top: 0px;
    }

    .mobileDDTopRow:hover {
        box-shadow: inset 0 0 1px 2px rgba(51, 51, 51, 0.5);
        border: none !important;
    }


    .mobileDDLTranslate {
        background-color: #f1f1f1;
        border: none;
        width: 200px;
        font-weight: bold;
    }

    .mobileDDTopRow:focus-within {
        box-shadow: inset 0 0 1px 2px rgba(51, 51, 51, 0.5);
        border: none !important;
    }

    .mobileDDLTranslate:focus-visible {
        outline: none;
        border: none;
    }

    .mobileDDLTranslate option {
        background-color: #fff;
        margin: 5px;
    }

    .mobileDDLTranslate option:checked {
        font-weight: bold;
    }
}
