/*Topic Pages
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
.topicSubhead{
    margin: 30px 0px 40px 20px;
    font-family: $global_fontFamily_default;
    font-size: $global_fontSize_default;
    font-weight: 300;
    color: $global_color_mediumGray;
}
.topicSubhead .topicCount{
    font-weight:800;
    font-size:36px;
    margin-right:5px;
}
.secondaryListTitle {
    padding: 10px;
}
.description {
  padding: 0 10px;
}
/* page level containers  */
.bodyTileWrapper {
    padding-right: 0px;
    padding-left: 0px;
}
#topicSidebar {
    padding-top:30px; 
}
.topicSidebar {
    #additionalFiltersWrap {
        margin-left: 0;
    }
}
.componentListBorder {
    border: 1px solid #999;
}
#topicServiceContainer, #featuredTopicServiceContainer {
    padding-bottom: 30px;
}
#topicProgramContainer:before{
    display:block;
    content:"";
    border-top:1px dotted $global_color_lightGray;
    width:98%;
    height:1px;
    margin-left:5px;
    left:0px;
}

/*Portal Department Lists*/
.topTier .bodycopy{
    padding-left: 20px;
    padding-top: 60px;
}

/*Topic Filter Refiner*/
#topicFilterContainer, #additionalFiltersWrap, #textFilter.design20 {

    .topicSubhead, #additionalFiltersTitle {
        font-weight: 700;
        font-size: 20px;
        margin: 0;
        padding: 15px 0px 20px 0px;
        color: $global_color_black;
    }

    .form-group {
        margin-left: 10px;

        .keyword {
            display: block;
        }

        &.design20 {
            margin-left: 0;

            #filterTextInput {
                margin-left: 10px;
            }
        }
    }

    .form-group *{
        display:inline;
        border-radius:0px;
    }

    input#filterTextInput {
        width: 66%;
        border:1px solid $global_color_black;

        &::placeholder {
            color: #000;
            opacity: .8;
        }

        &:-ms-input-placeholder {
            color: #000;
            opacity: .8;
        }

        &::-ms-input-placeholder {
            color: #000;
            opacity: .8;
        }

        &:focus {
            border-width: 0px;
            outline: solid 2px $global_color_black;
            outline-offset: -2px;
        }
    }

    button.btn.refine {
        width: 24%;
        background: $global_color_black;
        color: $global_color_white;
        border:1px solid $global_color_black;
        font-weight:bold;
        margin: -3px 0px 0px -5px;
    }

    button.refine:hover,
    button.refine:focus {
        background: $global_color_white;
        color: $global_color_black;
    }
}

#textFilter.design20 {
    margin-left: -10px;

    button.btn.refine { 
        margin: -2px 0px 0px -5px;
    }
}

#topicFilterBtn,
#topicFilterContainer button.clearFilters,
#textFilter.design20 button.clearFilters  {
    color: $global_color_darkGray;
    background-color: $global_color_white;
    width: 90%;
    margin: 20px 0 0 10px;
}

#textFilter.design20 button.clearFilters  {
    margin: 0;
}

/*Topic Filter Checkboxes*/

#topicFilterContainer, #additionalFiltersWrap {

    label {
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        width: 95%;
        height: 40px;
        margin-left: 25px;
    }

    input[type=checkbox] {
        display: block;
        margin-top: 0px;
        margin-left: 10px;
        height: 1px;
        width: 1px;
        position: absolute;
        top: 2px;
        -moz-appearance: initial;
        -webkit-appearance: initial;
        border: 0px;
        background: transparent;s

        &:focus,
        &:focus-within {
            outline: none;

            &:before {
                position: absolute;
                display: block;
                content:'';
                height: 24px;
                width: 24px;
                top: 0px;
                //box-shadow: inset 0 3px 3px rgba(0,0,0,.075), 0 0 12px rgba(0,0,255,0.5);
                border: 2px solid $global_color_linkBlue;
            }
        }
        

    } 

    input[type=checkbox] + label:before {
        content: "";
        color: $global_color_mediumGray;
        border: 1px solid $global_color_darkGray;
        background: $global_color_white;
        width: 24px;
        height: 24px;
        margin-right: 12px;
        margin-bottom: -22px;
        margin-left: -35px;
        display: block;
    } /* unchecked icon */

    input[type=checkbox]:checked + label:before { 
        content: "\f00c";
        font-family: FontAwesome; 
        font-size:36px;
        line-height:10px;
    } /* checked icon */

    .checkbox {
        height:34px;
    }
}


#topicFilterContainer .checkbox {
    height:34px;
}

/* topic filter */
#appliedFiltersContainer.design20 {
    margin-bottom: 15px;
}

button.appliedFilterButton {
    font-family: $global_fontFamily_default;
    font-size: 15px;
    background: $global_color_black;
    color: $global_color_white;
    border: none;
    padding: 5px 5px 5px 10px;
    margin: 10px;

    &:hover {
        background: $global_color_black;
        color: $global_color_white;
    }

    svg {
        position: relative;
        top: 1px;
        padding: 0 5px 0 10px;
        font-size: 14px;
    }
}

/*Topic Page Program Jump*/
.programJump{
    display: inline-block;
    padding: 10px;
    margin:40px 0px 0px 0px;
    width:90%;
    border: 2px solid $global_color_black;
}
.programJump:hover{
    background: $global_color_black;
    transition: all .4s;
}
.programJump:hover *{
    color: $global_color_white;
}
.programJump *{
    margin:0px;
    color: $global_color_black;
}
.programJump h3{
    float:left;
    font-family: $global_fontFamily_default;
    font-size: 20px;
}
.programJump i{
    float: right;
    margin-top:3px;
}        

/*Topic Page Related Departments*/
#leftNav .relatedListContainer{
    background: $global_color_white;
    padding-bottom:10px;
    margin-bottom: 30px;
}
#leftNav .relatedListContainer h3{
    font-family: $global_fontFamily_default;
    font-size:20px;
    padding:10px;
    background: $global_color_black;
    color: $global_color_white;
    text-align:center;
}
#leftNav .relatedList li {
    padding: 10px 20px 0px 20px;
}
#leftNav .relatedList li a{
    padding:0px 5px;
    max-width: 100%;
}
#leftNav .relatedList li a:hover {
    background: $global_color_black;
}

.visuallyHidden {
    margin: 0;
    padding: 0;
    height: 1px;
    width: 1px;
    white-space: nowrap;
    clip-path: inset(50%) !important;
    position: absolute;
}

@media (max-width: 575px) {
    .btn {
        &.btn-default {
            &.refine {
                min-height: auto !important;
            }
        }
    }
}