/*Campaign
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
.Campaign #campaignDeptTitle {
    margin: 20px 0px 0px 0px;
}

.Campaign #campaignDeptTitle a {
    font-family: $global_fontFamily_deptTitle;
    font-weight: bold;
    font-size: 2.12rem;
    color: #000;
    text-transform: none;
}

.Campaign .mainColumnTwoColumn .col-md-6.col-xs-12.twoColRight{
    padding-left: 30px;
}

.Campaign .MainColumnXHTMLCopyComponent.colorBlock {
    padding-top: 50px;
}

.Campaign .MainColumnXHTMLCopyComponent.colorBlock ul {
    padding-left: 20px;
}

.Campaign .MainColumnXHTMLCopyComponent.colorBlock h2 {
    /* font-weight: normal; */
    margin-bottom: 30px !important;
}

.Campaign #deptTitle {
    margin-bottom: 15px !important;
}

.Campaign .MainColumnAccordionListComponent.colorBlock,
.Campaign .TranslationPageComponent.colorBlock,
.Campaign .TranslationTabComponent.colorBlock {
    max-width: 1292px;
    width: 1292px;
    margin-left: auto;
    margin-right: auto;
}


/*h2 style is different for newsletter w just email field*/
.Campaign .MainColumnXHTMLCopyComponent.colorBlock.newsletterOneFormField h2 {
    color: #FFFFFF;
    text-align: center;
}

/*h2 style is different for newsletter w just email field*/
.Campaign .MainColumnXHTMLCopyComponent.colorBlock.newsletterMultipleFormFields h2 {
    text-align: center;
}

.Campaign .MainColumnXHTMLCopyComponent.colorBlock p {
    text-align: center;
}

.Campaign .MainColumnXHTMLCopyComponent.colorBlock ul li p {
    text-align: left;
}

/* styles specific for the campaign form */

#campaignform .form-control {
    width: 500px !important;
}

.Campaign .center {
    text-align: center;
}

.Campaign fieldset {
    margin-left: 30% !important;
}

.Campaign fieldset h3, .Campaign fieldset p {
    margin-left: -15px !important;
}

.Campaign #btn_campaignFormClear {
    background-color: rgb(227, 232, 245) !important;
    color: #0046AD;
    font-size: 16px !important;
    font-weight: bold !important;
    margin-top: 20px;
}

.Campaign #btn_campaignFormShow,
.Campaign #btn_campaignFormSubmit {
    font-size: 16px !important;
    font-weight: bold !important;
    margin-top: 20px;
}

.Campaign .required {
    color: #E4002B;
    font-weight: normal;
}

/********** Newsletter signup **********/
.Campaign #campaignNewsletterWrapper {
    text-align: center;
}

.Campaign #newsletterSubWrapper, .Campaign #newsletterSubWrapper h2 {
    color: #FFFFFF;
}

.Campaign #newsletterSubWrapper {
    padding-bottom: 20px
}

.Campaign #newsletterSubWrapper input {
    font-size: 16px;
    height: 40px;
    border: none;
}

.Campaign #btn_newsletterSignupSubmit {
    background-color: #515151;
    font-size: 16px !important;
    font-weight: bold !important;
    height: 40px;
    color: #FFFFFF;
    width: 85px;
    margin-left: -10px;
    display: inline-block;
    border: solid 2px #FFFFFF;
}

.Campaign #newsletterEmailAddress {
    width: 215px;
    color: #515151;
    font-weight: normal;
    text-align: center;
}

/*campaign body copy section */
.Campaign .colorblock.withBackgroundColor {
    background-color: #f1f1f1;
    padding: 50px 0 50px 0;
}

.Campaign .colorblock.noBackgroundColor {
    padding: 50px 0 50px 0;
}

.campaignBodyCopy {
    max-width: 969px !important;
}

@media (max-width: 992px) {
    .Campaign .MainColumnAccordionListComponent.colorBlock {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
}


@media(max-width: 767px) {
    #campaignform fieldset {
        margin-left: 0px !important;
    }

    #campaignform .form-control {
        width: 95% !important;
    }

    #campaignform #newsletterForm {
        margin-left: 15%;
    }

    .Campaign .MainColumnXHTMLCopyComponent.colorBlock {
        padding: 15px;
    }

    .Campaign .MainColumnXHTMLCopyComponent.colorBlock ul {
        padding: 0;
        margin-left: 10px;
    }

    .Campaign .MainColumnXHTMLCopyComponent.colorBlock h2 {
        margin-left: 0px;
        padding: 0;
    }

    .Campaign .titleTopNavBreadcrumbWrapper .container,
    .Campaign .MainColumnXHTMLCopyComponent.colorBlock .container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .Campaign .portalBanner, .Campaign .portalBanner .bannerImage, .Campaign .portalBanner .bannerImage .img-responsive {
        height: 0px;
    }
}
