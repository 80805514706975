/*IGX Search Results
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
#mainColMain p .resultsDiv, #mainColMain div .easy-autocomplete, #mainColMain div .easy-autocomplete-container, 
#mainColMain div #keymatchResults, #mainColMain div#facetFilters, #mainColMain div .resultsDiv, #mainColMain div #searchResultsWrapper, #mainColMain p #searchResultsWrapper, #mainColMain div .resultsDescription, #mainColMain p .resultsDescription, #mainColMain div .greenLink, #mainColMain div *[id^='score_'],
#mainColMain div #appliedFiltersContainer, #mainColMain div [id^='xId_'] {
    margin-bottom: 0;
}

#x96713 #deptBreadCrumbsWrapper.noDirector, #x79937 #deptBreadCrumbsWrapper.noDirector, #x91038 #deptBreadCrumbsWrapper.noDirector {
    display: none;
}

.department, .topTier, .CityWideDetail {

    #leftMainRight {
        padding-top: 34px !important;
    }
}

.resultsDetails {
    float: left;
    display: block;
}

h1 {
    margin-bottom: 30px;
}

.filterH3 {
    margin-bottom: 16px;
}

.facetedSearchFilters.departmentPageTypes {
    padding-bottom: 30px;
}

.searchResultsBackground {
    position: relative;
}

#mainColMain {
    
    padding-bottom: 0;

    #searchResultsWrapper {

        margin-bottom: 0;

        .factedSearchWrapper {
            margin: -12px 0 50px 0;
            min-height: 100px;
        }

        .facetedSearchFilters {
            width: 100%;
            padding: 0px 0px 2px 0px;
            font-weight: 700;
            font-size: 20px;
            margin: 0;
            color: $global_color_primaryBlue;

            label:hover {
                text-decoration: underline;
            }

            .loadMore { 
                background-color: $global_color_white; 
                text-align: center;
                font-style: italic;
                cursor: pointer;
            }

            .loadMore:hover  {
                color: $global_color_primaryBlue;
                text-decoration: underline;
            }

            label {
                font-size: 14px;
                font-weight: bold;
                width: 93.5%;
                margin: 13px 0 12px 0;
            }

            input[type=checkbox] {
                cursor: pointer;
                z-index: 1;
                //width: 300px;
                height: 40px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border: none;
            } 

            .checkbox {
                margin-top:0px;
                padding-right: 20px;
                border-left: 3px solid $global_color_lightWhite;
                border-right: 3px solid $global_color_lightWhite;
                margin-bottom: 0px !important;
                min-height: 44px;
                position: relative;
                display: inline-block;
                z-index: 10;

                &.checked {
                    svg {
                        display: block;
                    }
                }

                .countLabel {
                    font-weight: normal;
                }

                svg {
                    display: none;
                    font-size:20px;
                    line-height:10px;
                    color:#37A447;
                    position: absolute;
                    right: 18px;
                    top: 14px;
                }
            }

            .checkbox::after {
                position: absolute;
                content: '';
                border-bottom: 3px dotted $global_color_black;
                width: 90%;
                transform: translateX(-50%);
                bottom: -3px;
                left: 50%;
            }

            .checkbox.lastCheckBox::after {
                position: absolute;
                content: '';
                border-bottom: 3px solid $global_color_lightWhite;
                bottom: -3px;
                z-index: -1;
            }

            .facetedSearchFilters input[type="checkbox"] {
                font-weight: bold;
            }

            .lastCheckBox {
                border-bottom: 3px solid $global_color_lightWhite;
            }

            button.clearFilters {
                color: $global_color_darkGray;
                background-color: $global_color_white;
                border: solid 1px $global_color_lightGray !important;
                margin-top: 20px;
                width: 95%;
            }

            button.clearFilters:hover {
                color: $global_color_black;
                border: solid 1px $global_color_mediumGray !important;
            }

            .form-group * {
                display: inline;
                border-radius: 0px;
            }

            .resultsGrid {
                margin-bottom: 0;
            }

            .btn.btn-default.mobileFilter:hover {
                background-color: $global_color_white;
                text-decoration: underline;
            }

            #imageGallery {

                .searchImage {
                    float: left;
                    width: 200px;
                    height: 130px;
                    padding-right: 5px;
                    margin-bottom: 0;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    border: 1px solid $global_color_black;
                }
            }

            .decreaseFont {
                font-size: 13px;

                &.btn {
                    white-space: normal;
                }
            }
        }

        .filteredByGrid  {
            margin-bottom: 0px;
        }

        #filteredBySummary {
            float: left;
            display: block;
            width: 100%;
            font-size: 16px;

            #filteredList {
                font-weight: bold;
                font-style: italic;
            }
        }

        .resultsSummary {
            font-size: 16px;
            padding: 11px 0;
            margin-bottom: 40px;
            border-bottom: solid 2px $global_color_lightGray;
            width: 95%;
        }

        .searchPagination {

            margin-bottom: 20px;
            font-size: 14px;
            font-weight: normal;
            
            .pageNumber.currentPage {
                color: $global_color_white;
                background-color: $global_color_primaryBlue;
                pointer-events: none;
            }

            .pageNumber {
                color: $global_color_black;
                border-right: none;
                margin: 0 -3px;
                width: 35px;
                font-weight: normal;
            }
        
            .pageNumber:hover {
                background-color: $global_color_black;
                color: $global_color_white;
            }
        
            #nextPage:hover, #previousPage:hover {
                color: $global_color_white;
            }
        }

        #nextPage, #previousPage {
            cursor: pointer;
            width: 101px;
            height: 30px;
            text-align: center;
            color: $global_color_black;
            font-weight: normal;
        }

        #previousPage.pageNumber.disabled, #nextPage.pageNumber.disabled {
            color: $global_color_lightGray;
            border-color: $global_color_lightGray;
        }
    }
}

#mainColContent {

    .btn.mobileFilter:hover {
        color: $global_color_primaryBlue;
    }

    .btn-default.clearFilters {
        width: 95%;
    }

    .btn.mobileFilter:not(.header) {
        border-left: 3px solid $global_color_lightWhite !important;
        border-right: 3px solid $global_color_lightWhite !important;
        border-bottom: 3px solid$global_color_lightWhite !important;
        border-top: none !important;
        color: $global_color_primaryBlue; 
    }

    .btn.desktopFilter.header {
        padding-top: 4px;
    }

    .btn.mobileFilter.header:hover, .btn.desktopFilter.header:hover  {
        background-color: $global_color_lightGray;
        border: solid 1px transparent;
    }

    .facetedSearchFilters:not(.relevanceDate) {

        .mobileFilter.selected {
            background-color: $global_color_primaryBlue;
            color: $global_color_white !important;
            border-left: solid 3px transparent !important;
            border-right: solid 3px transparent!important;
            border-bottom: solid 3px transparent!important;
        }
    }
}

.checkbox.loadMore {
    border-bottom: 3px solid $global_color_lightWhite;
    position: relative;
    display: inline-block;
}

.checkbox.loadMore::after {
    position: absolute;
    content: '';
    border-top: 3px dotted $global_color_black;
    width: 90%;
    transform: translateX(-50%);
    top: 0px;
    left: 50%;
}

#appliedFiltersContainer.faceted {
    margin-bottom: 20px !important;
}

button.appliedFilterButton {
    margin: 10px 10px 10px 0;
    background: $global_color_primaryBlue;
    font-size: 14px;
}

#inputWrapper { 
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%);
    margin: auto;
    height: 90px;
    left: 0;
    right: 0;
    background-color: $global_color_white;
    padding-top: 27px;
    z-index: 100;

    .toggle {
        width: 77%;
        float: left;
        color: $global_color_black;
        /*padding-right: 25%;*/
    }

    #googleSearchToggle {

        .radio {
            input[type="radio"]:checked + span::before, 
            #googleSearchToggle_M .radio input[type="radio"]:checked + span::before {
                background: $global_color_black;
            }
        }

    }
}

#inputWrapper.dept {
    height: 100px;
}

.department.SearchResults .easy-autocomplete {
    width: 54%;
    float: left;
}

.input-group {
    background: $global_color_white;
}

#googleSearch {


    .easy-autocomplete-container {
        //width: 524px;

        ul {

            li:last-child {
                // padding-bottom: 10px;
            }

        }
    }

}

.easy-autocomplete-container {
    position: relative;
    top: 0px;
    float: left;

    ul {
        padding: 0;
        margin: 0;
        background-color: rgba(255,255,255, .95);
        font-style: italic;
        font-size: 24px;
        color: #444;
        -webkit-box-shadow: 0 8px 8px rgba(0,0,0,.3);
        box-shadow: 0 8px 8px rgba(0,0,0,.3);
    }

    ul > li {
        list-style: none;
        padding: 0 0 0 13px; 
        cursor: pointer;

        :hover {
            // background-color: $global_color_lightGray;
            // color: $global_color_black;
        }
    }

    ul > li.selected {
        background-color: $global_color_lightGray;
        color: $global_color_black;
    }

    div {
        margin-bottom: 0;
    }
}

.youSearchedForLabel {
    float: left;
    font-size: 24px;
    margin-right: 10px;
    top: 1px;
    position: relative;
    margin-left: 28px;
}

input.searchField {
    border: solid 1px $global_color_lightGray !important;
    padding: 0px 12px;
    font-size: 26px;
    height: 36px !important;
    margin-right: 10px;
    width: 100%;
    float: left;
    font-style: italic;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        padding: 0 12px;
    }
}

.iconLabelWrapper {
    display: block;
    position: relative;
    right: 0;
    float: right;
    width: 156px;
}

.searchFa {
    float: left;
    top: 2px;
    position: relative;
    margin: 0 5px;
    cursor: pointer;
}

.searchLabel {
    font-size: 24px;
    top: 1px;
    font-weight: bold;
    cursor: pointer;
}

.resultsDiv, .zeroResults {
    margin-bottom: 0;
    width: 100%;
    float: left;

    .highlight {
        font-weight: bold;
    }
} 

.zeroResults {
    margin: 0;
}

#keymatchResults {
    float: left;
    padding: 10px 0;
    width: 95%;

    h4 {
        padding-bottom: 15px;
    }

    .keymatchList {
        border: solid 1px #ccc;
        -webkit-box-shadow: 0 5px 5px rgba(0,0,0,0.075);
        box-shadow: 0 5px 5px rgba(0,0,0,0.075);
        float: left;
        width: 100%;
        padding: 10px 0;
    }

    .resultsDescription:first-letter {
        text-transform: capitalize
      }
}

.mainLink {
    text-decoration: underline;
    font-weight: bold;
    font-size: 18px;
}

*[id^='score_'], *[id^='xId_'] {
    color: #999;
    display: none;
    float: left;
    padding-right: 10px;
}

.greenLink {
    color: $global_color_green;
    font-weight: normal;
}

#nextPage {
    width: 80px;
}

[id^='searchPagination_'] {
    visibility: hidden;
    padding: 0 10px;
    margin: 14px 0 0 10px;
    float: left;
    width: 95%;
    text-align: center;


    a {
        display: inline-table;
    }
}

.padding10 {
    padding: 6px;
}

#googleTopHits {
    float: left;
    display: table;
    height: 300px;
}

#gaTopHits {
    overflow: hidden;
    height: 300px;
    width: 100%;
    position: absolute;
}

.btn.primary {
    margin-top: 30px;
    width: 90%;
    background-color: $global_color_green;
}

.btn.mobileFilter.header, .btn.desktopFilter.header {
    color: $global_color_black !important;
    font-size: 16px;
    font-weight: bold !important;
    background-color: $global_color_lightWhite;
    border-bottom: 1px solid $global_color_lightGray;
    height: 46px;
    text-align: left;
    padding-left: 21px !important;

    span {
        font-weight: normal;
    }
}

.btn.mobileFilter.header {
    margin-top: 2px;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn.desktopFilter.header, .facetedSearchFilters .checkbox {
    width: 95%;
}

.btn.desktopFilter .fa-w-10, .btn.mobileFilter .fa-w-10 {
    position: absolute;
    right: 20px;
}

.btn.desktopFilter .fa-minus, .btn.mobileFilter .fa-minus {
    top: 15px;
    z-index: 5;
    right: 14px;
    position: absolute;
    font-size: 14px;
}

.btn.mobileFilter .fa-minus, .btn.mobileFilter .fa-plus {
    top: 18px !important;
    right: 21px !important;
}

.btn.desktopFilter .fa-plus, .btn.mobileFilter .fa-plus {
    top: 14px;
    z-index: 6;
    right: 15px;
    position: absolute;
    font-size: 14px;
}

.toggleButtons {

    .btn {
        width: 45%;
        float: left;
        vertical-align: top;
        margin: 5px;
        min-width: 100px;
    }
}

.toggleButtons {

    .btn.active {
        color: $global_color_white;
        background-color: $global_color_primaryBlue;
        border-color: $global_color_primaryBlue;
        pointer-events: none;
    }
}

#imageGallery {
    float: left;
    padding: 0px 0 20px 0px;
    width: 100%;

    a {
        font-weight: normal;
        float: left;
        margin-right: 10px;
        text-align: center;
        font-size: 14px;
    }
}

@media (min-width: 1292px) and (max-width: 1450px) {

    .SearchResults {
        .youSearchedForLabel {
        //margin-left: -2%;
        }

        .easy-autocomplete {
            width: 45%;
        }
    }
}


@media (min-width: 991px) and (max-width: 1291px) {

    .SearchResults {
        #inputWrapper {

            .toggle {
                width: 78%;
            }
        }

        #inputWrapper.dept {
            height: 110px;
        }

        .youSearchedForLabel {
            margin-left: 2%;
        }

        .easy-autocomplete {
            width: 50% !important;
        }

        .facetedSearchFilters .btn.desktopFilter.header {
            font-size: 14px; 
        }
 
        .facetedSearchFilters .decreaseFont {
            font-size: 13px; 
            padding-top: 2px;
        }
    }
}

@media (min-width: 991px) {
    .SearchResults {
        .resultsDetails {
            width:95%;
        }

        #keymatchResults {

            .resultsDetails {
                padding: 10px 20px;
                margin-bottom: 0;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .SearchResults {
        #inputWrapper {
            width: 85%;
    
            .dept {
                height: 124px;
            }
         }
    
        #inputWrapper.dept {
            height: 130px;
        }
    
         .youSearchedForLabel {
             padding: 0 0 20px 28px;
             margin-left: 0;
         }
    
         .easy-autocomplete {
             width: 50% !important;
         }
    
        .filterH3 {
            // top: -14px !important;
            // right: 10px;
        }
    
        .searchField {
            position: relative;
            top: -8px;
        }
    
        .zeroResults {
            margin: 0;
        }
    }

}

@media (max-width: 767px) {

    .SearchResults {
        #inputWrapper {
            margin: 10px 20px 0 20px;
            padding-top: 0;
            height: 130px;
            padding: 10px;
        }

        #inputWrapper.dept {
            height: 150px;
        }

        .iconLabelWrapper {
            margin-top: 52px !important; 
            right: 5px;
        }

        .youSearchedForLabel {
            padding-bottom: 10px;
            margin-left: 0;
            top: 0;
            float: left;
            width: 85%;
        }

        .easy-autocomplete {
            width: 100% !important;
        }

        input.searchField {
            width: 81%;
        }

        .zeroResults {
            margin: 0;
        }
    }
}

@media (max-width: 991px) {

    .col-sm-3, .col-sm-9, #searchResultsWrapper.col-sm-12 {
        width: 100%;
    }

    input.searchField {
        width: 91%;
        height: 51px !important;
        //margin-bottom: 20px; 
        position: relative;
        z-index: 10;
    }

    .easy-autocomplete-container {
        width: 91%;
        display: block;
    }

    .iconLabelWrapper {
        float: right;
        position: absolute;
        z-index: 10;
        font-size: 1.35em;
        right: 1.5%;
        border-radius: 0 4px 4px 0;
        width: auto;
    }

    .searchFa {
        top: -5px;
    }

    .filterH3 {
        position: absolute;
        z-index: 11;
        cursor: pointer;
        top: -14px;
        background-color: $global_color_primaryBlue;
        color: $global_color_white;
        padding: 10px 20px;
        text-align: right;
        right: 10px;
    }

    .searchLabel {
        display: none;
    }

    #mobileFilter {
        font-size: 0.75em;
        //position: relative;
        //top: -1px; 
    }

    #mainColMain {

        #searchResultsWrapper { 

            .facetedSearchFilters {
                .checkbox::after {
                    width: 96%;
                }

                .checkbox.lastCheckBox::after {
                    position: absolute;
                    content: '';
                    border-bottom: 3px solid $global_color_lightWhite;
                    bottom: -3px;
                }
            }

            .resultsSummary.mobile {
                position: relative;
                float: left;
                width: 100%;
                z-index: 10;
                font-size: 24px;
            }
        }

        .factedSearchWrapper {
            border-right: none;
            margin-bottom: 0 !important;
        }

        div.doneButton {
            padding: 10px;
            background-color: $global_color_primaryBlue;
            margin-bottom: 0;
            font-size: 18px;
        }

        .keymatchList {
            .resultsDetails {
                padding: 10px 20px;
                margin-bottom: 0 !important;
            }
        }
    }

    .facetedSearchFilters {

        label {
            margin-top: 15px;
        }

        .checkbox {
            width: 98%;
            margin-left: 5px;
            min-height: 53px;
        }

        input[type=checkbox]:checked + label:before {
            right: 17px;
            top: 19px;
        }
    }

    #mainColContent {

        .btn-default.mobileFilter {
            color: $global_color_black;
        }

        #filterFacets {
            width: 98%;
            margin-left: 5px !important;
            margin: 2px 0 30px 0;
            background-color: $global_color_white;
            border: 3px solid $global_color_lightWhite !important;
            padding: 15px;
        }

        #filterFacets:hover {
            background-color: $global_color_lightGray;
        }

        .btn.mobileFilter, .btn-default.clearFilters, .btn-md.primary {
            width: 98%;
            margin-left: 5px;
        }

        .btn-default.clearFilters {
            margin-bottom: 50px;
        }
    }

    .btn.btn-default:not(#searchButton_M), .btn.btn-md, .btn.mobileFilter, button.appliedFilterButton {
        min-height: 50px;
    }

    .resultsDetails {
        //width:85%;
    }

    #imageGallery {

        .searchImage {
            width: 195px;
        }
    }
}

@media (min-width: 286px) and (max-width: 666px) {

    #mainColMain {
 
        #searchResultsWrapper { 

            .factedSearchWrapper {
                float: left;
                min-height: 0px
            }

            .resultsSummary {
                margin-bottom: 24px;
                font-size: 16px !important;
            }

            #keymatchResults {
                h4 {
                    padding-left: 0;
                }

                .resultsDiv {
                    padding-left: 10px; 
                }
            }

            #previousPage, #nextPage {
                width: 35px;
            }

            .searchPagination {
                margin-bottom: 40px;
            }

        }

    }

    #inputWrapper, #inputWrapper.dept {
        height: 170px;  

        .youSearchedForLabel {
            font-size: 20px;
            width: 100%;
        }
    }

    #inputWrapper {
        height: 130px;
    }

    #googleSearchToggle.toggle {
        width: auto;
        padding-right: 0;
    }

    .department.SearchResults {
        .easy-autocomplete {
            width: 100% !important;
        }
    }

    #inputSearch {
        width: 83%;
    }

    [id^='searchPagination_'] {
        padding: 0;
        width: 100%;
        margin: 14px 0 0 0;
    }

    .resultsDiv:not(:first-child), .zeroResults {
        padding: 0;
        margin-top: -11px;
    }

    .filterH3 {
        font-size: 16px;
        top: -16px;
    }

    .iconLabelWrapper {
        font-size: 1em;
        /*bottom: 34px;*/
    }
}
