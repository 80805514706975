/*Global Navs
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/

/*Skip to Main Content
______________________________________________________________________*/
a#skipToMain {
    color: #e2edff;
}

#skipToMain {
    background: $global_color_black;
    font-weight: bold;
    padding: 19px 30px;
}

#skipToMain:hover {
    background: $global_color_darkGray;
}

#skipToMain:focus {
    color: $global_color_white;
    position: fixed;
    z-index: 2000;
}

/*Breadcrumbs
______________________________________________________________________*/

/* temporary Hide social media in department top nav */
#deptBreadcrumbsContainer #deptSocialMedia {
    display: none;
}
/*Breadcrumbs*/
#deptBreadcrumbsContainer {
    margin-left: -5px;
    margin-top: -15px;
    padding-bottom: 20px;
}

.department #deptBreadcrumbsContainer {
    margin-left: -20px;
}

#portalpage #deptBreadcrumbsContainer {
    margin-top: 20px;
}

/*Left Navs
______________________________________________________________________*/
.leftNav2,
#leftNav {
    margin: 20px 0px 0px 0px;
    max-width: 90%;
}

#portalpage .leftNav2,
#portalpage #leftNav { /*legacy portal page fix*/
    margin: 45px 0px 0px 0px;
}

.leftNav2 .ui-icon,
#leftNav .ui-icon {
    display: none;
}

.leftNav2 ul,
#leftNav ul {
    padding: 0px;
    list-style: none;
}

.leftNav2 li,
#leftNav li {
    padding: 0px 5px 20px 0px;
}

#leftNav ul > li.childNavFolder > ul {
    padding: 0px 5px 0 0 !important;
}

#leftNav ul > li.parentNavFolder > ul.navFolder > li > a {
    font-weight: bold;
    font-size: 16px;
    color: $global_color_black;
}

.leftNav2 a,
#leftNav a {
    color: $global_color_black;
    display: inline-block;
    font-weight: 600;
    max-width: 75%;
}

.leftNav2 a.activePage,
#leftNav a.activePage {
    color: $global_color_primaryBlue;
    font-weight: 700;
}

.leftNav2 a:hover,
#leftNav a:hover {
    background: $global_color_primaryBlue;
    color: $global_color_white;
    text-decoration: none;
}

.leftNav2 ul > li > ul,
#leftNav ul > li > ul {
    padding: 20px 0px 0px 20px;
}

.leftNav2 ul > li > ul > li:last-child,
#leftNav ul > li > ul > li:last-child {
    padding-bottom: 0px;
}

.leftNav2 ul > li > ul > li > a,
#leftNav ul > li > ul > li > a {
    color: #555;
    font-size: 15px;
}

.leftNav2 .fa-chevron-down,
.leftNav2 .fa-chevron-right,
#leftNav .fa-chevron-down,
#leftNav .fa-chevron-right {
    margin: 3px 0 0 0;
    vertical-align: top;
    width: 24px
}

.leftNav2 a.hasChildren, 
#leftNav a.hasChildren {
    margin-left: -24px;
    width: 100%;
}
.primaryTierHasChildren {
    margin-left: 16px;
}
.parentLinkTextWrap {
    display: inline-block;
    width: calc(100% - 24px)
}

// .leftNav2>ul,
// #leftNav>ul {
//     margin-left: 16px;
// }

/*Left Nav Top Border*/
.department .leftNav2:before,
.department #leftNav:before {
    /*display: block;
    content: "";
    width: 75%;
    height: 2px;
    border-top: 8px solid $global_color_black;
    margin-top: -20px;
    margin-bottom: 20px;*/
}

/*Back To Top 
______________________________________________________________________*/
#backToTop {
    position: fixed;
    bottom: 80px;
    left: -400px;
}

#backToTop.hasScrolled {
    left: 30px;
}

#backToTop a {
    z-index: 50;
    width: 70px;
    height: 70px;
    text-align: center;
    display: block;
    background: rgba(255,255,255,.6);
    border-radius: 50px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.6);
}

#backToTop a:hover {
    background: $global_color_white;
    box-shadow: 1px 1px 6px rgba(0,0,0,0.9);
    transition: all .1s;
}

#backToTop a:hover i {
    padding-top: 13px;
    font-size: 38px;
    transition: all .1s;
}

#backToTop a i {
    color: $global_color_black;
    padding-top: 18px;
    font-size: 28px;
}

#backToTop a:hover svg {
    margin-top: 13px;
    font-size: 38px;
    transition: all .1s;
}

#backToTop a svg {
    color: $global_color_black;
    margin-top: 18px;
    font-size: 28px;
}
