/* Table of Contents
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

+ Extend Bootstrap

+ Super Global Styles

+ Tiles
  - Default
  - Feature
      > Default
      > As an Alert
      > Carousel 
  - Twitter (C)
  - Contact (B)
  - News
      > Default (C)
      > As an Alert 
      > As a Press Release (C)
      > As a Press Release (B)
  - Basic Color
  - Download
  - Event
      * Spuds - See trumba.css
      > As a Spud (B)
      > As a Feed (C)
  - Video
  - Services, Programs
      > Default
      > Popular
  - Navigation Contact
  - Newsletter Sign Up
  - Photo Credits 

+ Feature Tile Container

+ Global Navs
  - Skip to Main Content
  - Breadcrumbs
  - Left Navs
  - Back to Top

+ Portal Pages
  - Services Footer
  - Expandable Menus
  - Top Requests

+ Homepage
   
+ Topic Pages
  - Headings
  - Containers
  - Lists
  - Filter Refiner
  - Filter Checkboxes
  - Program Jump
  - Related Departments

+ Body Styles - General
  - Lightboxes

+ Body Styles - Main Content
  - Headings
  - Readability Limiter
  - Links
  - Paragraphs
  - HRs
  - Tables
  - Unordered Lists
      > Default
      > Nested
      > Children Element Margins
      > Unstyled
  - Ordered Lists
  - Ordered Lists - Extended
  - Definitions
  - Accordions
  - Tabs
  - Panels
  - Jumbotrons
  - Lead Texts
  - Wells
  - Buttons
  - Read More Expander
  - Link Lists
  - Contact Group

+ Department Styles
  - Page Title
  - Image
  - Container Spacing
  - Top Nav
  - Footer
  - Sub Footers
      > Footer Links
      > Footer Links - Short
      > Newsletter Signup
  - Left Column
  - Right Column
      > Forms
      > Contact Component

+ Location Pages
  - General
  - Hours & Contact Bar
  - Alerts
  - Popovers
  - Image Gallery

+ Components 
  - Video
  - Blog Feed
       > Default
       > Main Column
       > Sidebar
  - Board Member
  - Map Component
  - Datatable
  - Search Form Collection
      > Slides
      > Form
      > Auto complete

+ Color Blocks
  - Orphaned Page Titles
  - General
  - Base Colors
  - Link Colors
  - Headings
  - Blog Feeds
  - Titled Collections
  - Search Forms
  - Topic Filter Components

+ Unsorted
  - Nothing
   
+ Mobile & Tablet
  - Left Nav
  - Department Layout
  - Department Footer Links
  - Feature Image
  - [[ Mobile Services Wrapper Element ]] DELETE 
  - Photo Credits
  - Topic Filter Inputs
  - Topic Page Program Jump
  - Search Form Collection
  - Featured Wrapper
  - Slicked Tiles
  - Not Slicked Tiles
  - Location Pages

+ Mobile
  - Featured Container
  - Topic Filter
  - Department Footer
  - Elected Contact Tiles
  - Homepage Electeds
  - Search Form Collection
  - Color Blocks
  - Not Slicked Tiles
  - Topic Filter Component
  - Data Tables
  - Department Footer Links Short
  - Location Pages

+ Not Mobile
  -Left Nav
  -Location Pages

+ Tablet
  - Featured Container
  - Search Form Collection
  - Color Blocks
  - Not Slicked
  - Topic Filter Component
  - Department Footer Links

+ Super Old Phones
  - Slicked
  - Not Slicked

+ All Desktops
  - Featured Container
  - Department Layout
  - Location Pages

+ Small Desktops
  - Topic Pages
  - Featured Container
  - Breadcrumbs
  - Department Top Nav
  - Department Footer Links
  - Trumba Calendar
  - Feeds
  - Search Form Collection

+ Large Desktops

+ Mac Fixes


END TOC ________________________________________________________________
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/