/* Pagination 
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
.paginationItem img {
    width: 150px;
}
.paginationItem .paginationTitle {
    margin-top: 0px;
}
.paginationItem .paginationTitle, .paginationItem .titleExcerptText {
    max-width: 580px;
}
.paginationItem .col-sm-4, .paginationItem .col-md-3 {
    width: 20%;
}