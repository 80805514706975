/*All Portal Pages
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/

/*Services Footer*/
#servicesFooter {
    padding-top: 20px;
    display: none;
}

/* Topic Footer begin */
.topicTile {
    float: right;
    width: $tiles_width_default;
    height: 30px;
    margin: 5px;
}
.topicTile > .tileLink a {
    color: $global_color_black;
    padding: 10px;
}
.topicTile > .tileLink a:hover {
    background-color: $global_color_darkGray;
    color: $global_color_white;
    text-decoration: none;
}

/*Services Footer Separation*/
#portalpage
{
    border-bottom:60px solid $global_color_darkGray;
}

.siteFooterMobile{
    padding:0px 15px;
    margin-bottom:15px;
}

/*Portal Expandable Menus*/
#leftNavMobileSelect button,
.siteFooterMobile button {
    width:100%;
    height:50px;
    border-radius:0px;
    border:4px solid $global_color_black;
    background: $global_color_white;
    color: $global_color_black;
    font-weight:700;
    font-size:20px;
    text-align:left;
}
#leftNavMobileSelect button:focus,
.siteFooterMobile button:focus,
#leftNavMobileSelect button:hover,
.siteFooterMobile button:hover,
#leftNavMobileSelect button:active,
.siteFooterMobile button:active,
#leftNavMobileSelect.open button,
.siteFooterMobile .dropdown.open button {
    background: $global_color_black;
    color: $global_color_white;
    border:4px solid $global_color_black;
    position:relative;
}
#leftNavMobileSelect button .caret,
.siteFooterMobile .dropdown button .caret{
    display:none;
}
/*#leftNavMobileSelect button:after,
.siteFooterMobile .dropdown button:after{
    content:"\f078";
    font-family:'fontAwesome';
    float:right;
    position: absolute;
    right: 15px;
}*/
#leftNavMobileSelect .dropdown-menu,
.siteFooterMobile .dropdown-menu{
    width:100%;
    margin-top:0px;
    border-radius:0px; 
    position:relative;
}
.siteFooterMobile .dropdown-menu{
    margin-bottom: 15px
}

#leftNavMobileSelect .dropdown-menu li a,
.siteFooterMobile .dropdown-menu li a{
    padding:15px 20px;
}

/*Top Requests*/
[id^='top-request-wrapper'],
[id^='popular-page-wrapper'] {
    padding-top: 20px;
    padding-bottom: 50px;
}

[id^='top-request-wrapper'] h2,
[id^='popular-page-wrapper'] h2 {
    font-size: 27px;
    margin: 0px 0px 20px 0px;
    text-transform: none;
}

#featuredTileContainer [id^='top-request-wrapper'] h3.tileTitle,
#featuredTileContainer [id^='popular-page-wrapper'] h3.tileTitle {
    color: $global_color_white;
    background-color: $global_color_black;
    font-size: 22px;
    margin: -20px -15px 20px -15px;
    padding: 25px;
}
[id^='request-lists'],
[id^='popular-lists'] {
    line-height: 130%;
    font-size: 14px;
}
[id^='request-lists'] ol,
[id^='popular-lists'] ol{
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    counter-reset: li-counter;
}
[id^='request-lists'] ol > li,
[id^='popular-lists'] ol > li{
    display: inline-block;
    width: 100%;
}
[id^='request-lists'] ol > li:before,
[id^='popular-lists'] ol > li:before{
    content: counter(li-counter);
    counter-increment: li-counter;
    display: block;
    float: left;
    width: 30px;
    width: 28px;
    margin: 0px 0px 10px 0px;
    padding: 5px 0px;
    background: $global_color_mediumGray;
    color: $global_color_white;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    border-radius: 100px;
}

[id^='request-lists'] ol > li a,
[id^='popular-lists'] ol > li a {
    color: $global_color_black;
    margin-left: 5px;
    padding: 4px 5px 5px 5px;
    float: left;
    width: 85%;
}
[id^='request-lists'] ol > li a:hover,
[id^='popular-lists'] ol > li a:hover {
    background: $global_color_darkGray;
    color: $global_color_white;
    text-decoration: none;
}

#x54327 #mainContent.topTier,
#x54326 #mainContent.topTier,
#x104827 #mainContent.topTier
{
    border-top: 1px solid #474747;
}