

.ScrollSpyContainerComponent.colorBlock.container .scrollSpyContainer p a:not(.btn),
.ScrollSpyContainerComponent.colorBlock.container .scrollSpyContainer li a:not(.btn),
.CityLinkRSSComponent h2 a,
#mainColMain .CityLinkRSSComponent h2 a,
.CardContainerComponent .cardContainerBodyCopy a,
.CardContainerComponent .mobile__cardContainerBodyCopy a,
.SidebarNavigationComponent a,
.locationPage .bodyText a:not(.btn),
.Campaign .colorblock:not(.CardContainer):not(.MainColumnXHTMLCopyComponent):not(MainColumnAccordionListComponent) a,
.MainColumnAccordionListComponent a,
.MainColumnCardSetComponent a,
.TrumbaCalendarComponent a,
.BoardMemberComponent a,
.VuejsSPAComponent a,
.MainColumnNavigationComponent a,
.paginationContainer a:not(.btn),
.mainColumnTwoColumn a:not(.btn),
.mainColumnTwoColumn h2 a,
.mainColumnTwoColumn h3 a,
#mainColMain .mainColumnTwoColumn h2 a,
#mainColMain .mainColumnTwoColumn h3 a,
#mainColMain .contactPageLink a,
#mainColMain .contactTilePhone a,
#mainColMain .contactTileEmail a,
#mainColMain .contactTileAddress a,
.ContactComponent .contactPageLink a,
.ContactComponent .contactTilePhone a,
.ContactComponent .contactTileEmail a,
#rightCol .ContactComponent .contactTileAddress a,
.facilityContact .contactPageLink a,
.facilityContact .contactTilePhone a,
.facilityContact .contactTileEmail a,
.contactProgram .contactPageLink a,
.contactProgram .contactTilePhone a,
.contactProgram .contactTileEmail a,
.SidebarLinkListComponent a,
.MainColumnLinkListComponent a,
.rightWrapper .navlist a,
.titledCollectionList h3 a,
.tabsContainer .rightWrapper a,
#mainColMain .span a:not(.btn),
#mainColMain .MainColumnXHTMLCopyComponent a:not(.btn):not(.btn),
.MainColumnXHTMLCopyComponent a:not(.btn):not(.btn),
.SidebarXHTMLCopyComponent a:not(.btn),
.seagovBanner a,
.colorBlock.TwoColumnImageandTextGridComponent a,
.tabContent a:not(.btn),
.ServicesSectionComponent .servicesSection a:not(.btn) {
    color: $global_color_darkBlue !important;
    text-decoration: underline !important;
    text-decoration-thickness: 1px !important;
    text-underline-offset: 1px !important;
}

//dark background links
.colorBlock.DarkGray.TwoColumnImageandTextGridComponent a,
.CardContainerComponent.DarkGrayCardContainer .cardContainerBodyCopy a {
    color: #FFF !important;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 1px;
}

.seagovBanner a,
.colorBlock.TwoColumnImageandTextGridComponent a {
    font-weight: normal !important;
}

//***visited
.ScrollSpyContainerComponent.colorBlock.container .scrollSpyContainer p a:not(.btn):visited,
.ScrollSpyContainerComponent.colorBlock.container .scrollSpyContainer li a:not(.btn):visited,
.CityLinkRSSComponent h2 a:visited,
#mainColMain .CityLinkRSSComponent h2 a:visited,
.CardContainerComponent .cardContainerBodyCopy a:visited,
.SidebarNavigationComponent a:visited,
.locationPage .bodyText a:not(.btn):visited,
.Campaign .colorblock:not(.CardContainer):not(.MainColumnXHTMLCopyComponent):not(MainColumnAccordionListComponent) a:visited,
.MainColumnAccordionListComponent a:visited,
.MainColumnCardSetComponent a:visited,
.TrumbaCalendarComponent a:visited,
.BoardMemberComponent a:visited,
.VuejsSPAComponent a:visited,
.MainColumnNavigationComponent a:visited,
.paginationContainer a:not(.btn):visited,
.mainColumnTwoColumn a:not(.btn):visited,
.mainColumnTwoColumn h2 a:visited,
.mainColumnTwoColumn h3 a:visited,
#mainColMain .mainColumnTwoColumn h2 a:visited,
#mainColMain .mainColumnTwoColumn h3 a:visited,
#mainColMain .contactPageLink a:visited,
#mainColMain .contactTilePhone a:visited,
#mainColMain .contactTileEmail a:visited,
#mainColMain .contactTileAddress a:visited,
.ContactComponent .contactPageLink a:visited,
.ContactComponent .contactTilePhone a:visited,
.ContactComponent .contactTileEmail a:visited,
#rightCol .ContactComponent .contactTileAddress a:visited,
.facilityContact .contactPageLink a:visited,
.facilityContact .contactTilePhone a:visited,
.facilityContact .contactTileEmail a:visited,
.contactProgram .contactPageLink a:visited,
.contactProgram .contactTilePhone a:visited,
.contactProgram .contactTileEmail a:visited,
.SidebarLinkListComponent a:visited,
.MainColumnLinkListComponent a:visited,
.rightWrapper .navlist a:visited,
.titledCollectionList h3 a:visited,
.tabsContainer .rightWrapper a:visited,
#mainColMain .span a:not(.btn):visited,
#mainColMain .MainColumnXHTMLCopyComponent a:not(.btn):visited,
.MainColumnXHTMLCopyComponent a:not(.btn):visited,
.SidebarXHTMLCopyComponent a:not(.btn):visited,
.seagovBanner a:visited,
.tabContent a:not(.btn):visited,
.ServicesSectionComponent .servicesSection a:not(.btn):visited {
    color: #4C2C92 !important;
    text-decoration: underline !important;
    text-underline-offset: 1px !important;
    text-decoration-thickness: 1px !important;
}


.TwoColumnImageandTextGridComponent a:visited .titleWithLink h3,
.TwoColumnImageandTextGridComponent a:visited .titleWithLink h2 {
    color: #4C2C92;
    text-decoration: underline;
}

.TwoColumnImageandTextGridComponent a:visited {
    color: #4C2C92;
}

.DarkGray.TwoColumnImageandTextGridComponent a:visited .titleWithLink h3,
.DarkGray.TwoColumnImageandTextGridComponent a:visited .titleWithLink h2,
.CardContainerComponent.DarkGrayCardContainer .cardContainerBodyCopy a:visited {
    color: #DBD5E9 !important;
}

.DarkGray.TwoColumnImageandTextGridComponent a:visited {
    color: #DBD5E9 !important;
}

//***hover
.ScrollSpyContainerComponent.colorBlock.container .scrollSpyContainer p a:not(.btn):hover,
.ScrollSpyContainerComponent.colorBlock.container .scrollSpyContainer li a:not(.btn):hover,
.CityLinkRSSComponent h2 a:hover,
#mainColMain .CityLinkRSSComponent h2 a:hover,
.CardContainerComponent .cardContainerBodyCopy a:hover,
.CardContainerComponent .mobile__cardContainerBodyCopy a:hover,
.SidebarNavigationComponent a:hover,
.locationPage .bodyText a:not(.btn):hover,
.Campaign .colorblock:not(.CardContainer):not(.MainColumnXHTMLCopyComponent):not(MainColumnAccordionListComponent) a:hover,
.MainColumnAccordionListComponent a:hover,
.MainColumnCardSetComponent a:hover,
.TrumbaCalendarComponent a:hover,
.BoardMemberComponent a:hover,
.VuejsSPAComponent a:hover,
.MainColumnNavigationComponent a:hover,
.paginationContainer a:not(.btn):hover,
.mainColumnTwoColumn a:not(.btn):hover,
.mainColumnTwoColumn h2 a:hover,
.mainColumnTwoColumn h3 a:hover,
#mainColMain .mainColumnTwoColumn h2 a:hover,
#mainColMain .mainColumnTwoColumn h3 a:hover,
#mainColMain .contactPageLink a:hover,
#mainColMain .contactTilePhone a:hover,
#mainColMain .contactTileEmail a:hover,
#mainColMain .contactTileAddress a:hover,
.ContactComponent .contactPageLink a:hover,
.ContactComponent .contactTilePhone a:hover,
.ContactComponent .contactTileEmail a:hover,
#rightCol .ContactComponent .contactTileAddress a:hover,
.facilityContact .contactPageLink a:hover,
.facilityContact .contactTilePhone a:hover,
.facilityContact .contactTileEmail a:hover,
.contactProgram .contactPageLink a:hover,
.contactProgram .contactTilePhone a:hover,
.contactProgram .contactTileEmail a:hover,
.SidebarLinkListComponent a:hover,
.MainColumnLinkListComponent a:hover,
.rightWrapper .navlist a:hover,
.titledCollectionList h3 a:hover,
.tabsContainer .rightWrapper a:hover,
#mainColMain .span a:not(.btn):hover,
#mainColMain .MainColumnXHTMLCopyComponent a:not(.btn):hover,
.MainColumnXHTMLCopyComponent a:not(.btn):hover,
.SidebarXHTMLCopyComponent a:not(.btn):hover,
.seagovBanner a:hover,
.tabContent a:not(.btn):hover,
.ServicesSectionComponent .servicesSection a:not(.btn):hover {
    color: #006699 !important;
    text-decoration: underline !important;
    text-underline-offset: 3px !important;
    text-decoration-thickness: 1px !important;
    background: none !important;
    color: none !important;
    background: none !important;
}

//dark background hover
.colorBlock.DarkGray.TwoColumnImageandTextGridComponent .linktoImageandText:hover h3.nameOfTitleWithLink,
.colorBlock.DarkGray.TwoColumnImageandTextGridComponent .nameOfTitleWithLink:hover,
.colorBlock.DarkGray.TwoColumnImageandTextGridComponent a:hover,
.CardContainerComponent.DarkGrayCardContainer .cardContainerBodyCopy a:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    background-color: none !important;
    color: #FFF !important;
}

.TwoColumnImageandTextGridComponent .titleWithLink h3:hover,
.TwoColumnImageandTextGridComponent .titleWithLink h2:hover {
    color: #006699 !important;
}

.colorBlock.TwoColumnImageandTextGridComponent a:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    color: #006699 !important;
}

//***active
.ScrollSpyContainerComponent.colorBlock.container .scrollSpyContainer p a:not(.btn):active,
.ScrollSpyContainerComponent.colorBlock.container .scrollSpyContainer li a:not(.btn):active,
.CityLinkRSSComponent h2 a:active,
#mainColMain .CityLinkRSSComponent h2 a:active,
.CardContainerComponent .cardContainerBodyCopy a:active,
.SidebarNavigationComponent a:active,
.locationPage .bodyText a:not(.btn):active,
.Campaign .colorblock:not(.CardContainer):not(.MainColumnXHTMLCopyComponent):not(MainColumnAccordionListComponent) a:active,
.MainColumnAccordionListComponent a:active,
.MainColumnCardSetComponent a:active,
.TrumbaCalendarComponent a:active,
.BoardMemberComponent a:active,
.VuejsSPAComponent a:active,
.MainColumnNavigationComponent a:active,
.paginationContainer a:not(.btn):active,
.mainColumnTwoColumn a:not(.btn):active,
.mainColumnTwoColumn h2 a:active,
.mainColumnTwoColumn h3 a:active,
#mainColMain .mainColumnTwoColumn h2 a:active,
#mainColMain .mainColumnTwoColumn h3 a:active,
#mainColMain .contactPageLink a:active,
#mainColMain .contactTilePhone a:active,
#mainColMain .contactTileEmail a:active,
#mainColMain .contactTileAddress a:active,
.ContactComponent .contactPageLink a:active,
.ContactComponent .contactTilePhone a:active,
.ContactComponent .contactTileEmail a:active,
#rightCol .ContactComponent .contactTileAddress a:active,
.facilityContact .contactPageLink a:active,
.facilityContact .contactTilePhone a:active,
.facilityContact .contactTileEmail a:active,
.contactProgram .contactPageLink a:active,
.contactProgram .contactTilePhone a:active,
.contactProgram .contactTileEmail a:active,
.SidebarLinkListComponent a:active,
.MainColumnLinkListComponent a:active,
.rightWrapper .navlist a:active,
.titledCollectionList h3 a:active,
.tabsContainer .rightWrapper a:active,
#mainColMain .span a:not(.btn):active,
#mainColMain .MainColumnXHTMLCopyComponent a:not(.btn):active,
.MainColumnXHTMLCopyComponent a:not(.btn):active,
.SidebarXHTMLCopyComponent a:not(.btn):active,
.seagovBanner a:active,
.tabContent a:not(.btn):active,
.ServicesSectionComponent .servicesSection a:not(.btn):active {
    color: #006699 !important;
    text-decoration: underline !important;
    text-underline-offset: 3px !important;
    text-decoration-thickness: 1px !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px !important;
}

//***focus
.ScrollSpyContainerComponent.colorBlock.container .scrollSpyContainer p a:not(.btn):focus,
.ScrollSpyContainerComponent.colorBlock.container .scrollSpyContainer a a:not(.btn):focus,
.CityLinkRSSComponent h2 a:focus,
#mainColMain .CityLinkRSSComponent h2 a:focus,
.CardContainerComponent .cardContainerBodyCopy a:focus,
.SidebarNavigationComponent a:focus,
.locationPage .bodyText a:not(.btn):focus,
.Campaign .colorblock:not(.CardContainer):not(.MainColumnXHTMLCopyComponent):not(MainColumnAccordionListComponent) a:focus,
.MainColumnAccordionListComponent a:focus,
.MainColumnCardSetComponent a:focus,
.TrumbaCalendarComponent a:focus,
.BoardMemberComponent a:focus,
.VuejsSPAComponent a:focus,
.MainColumnNavigationComponent a:focus,
.paginationContainer a:not(.btn):focus,
.mainColumnTwoColumn a:not(.btn):focus,
.mainColumnTwoColumn h2 a:focus,
.mainColumnTwoColumn h3 a:focus,
#mainColMain .mainColumnTwoColumn h2 a:focus,
#mainColMain .mainColumnTwoColumn h3 a:focus,
#mainColMain .contactPageLink a:focus,
#mainColMain .contactTilePhone a:focus,
#mainColMain .contactTileEmail a:focus,
#mainColMain .contactTileAddress a:focus,
.ContactComponent .contactPageLink a:focus,
.ContactComponent .contactTilePhone a:focus,
.ContactComponent .contactTileEmail a:focus,
#rightCol .ContactComponent .contactTileAddress a:focus,
.facilityContact .contactPageLink a:focus,
.facilityContact .contactTilePhone a:focus,
.facilityContact .contactTileEmail a:focus,
.contactProgram .contactPageLink a:focus,
.contactProgram .contactTilePhone a:focus,
.contactProgram .contactTileEmail a:focus,
.SidebarLinkListComponent a:focus,
.MainColumnLinkListComponent a:focus,
.rightWrapper .navlist a:focus,
.titledCollectionList h3 a:focus,
.tabsContainer .rightWrapper a:focus,
#mainColMain .span a:not(.btn):focus,
#mainColMain .MainColumnXHTMLCopyComponent a:not(.btn):focus,
.MainColumnXHTMLCopyComponent a:not(.btn):focus,
.SidebarXHTMLCopyComponent a:not(.btn):focus,
.seagovBanner a:focus,
.tabContent a:not(.btn):focus,
.ServicesSectionComponent .servicesSection a:not(.btn):focus {
    color: #006699 !important;
    text-decoration: underline !important;
    text-underline-offset: 3px !important;
    text-decoration-thickness: 1px !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px !important;
}

h3.nameOfTitleWithLink:focus,
h2.nameOfTitleWithLink:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
/*.department .colorBlock .mainColumnTwoColumn a {
    font-weight: normal !important;
    e.g. errors on https://wwwqa.seattle.gov/utilities
}*/

.department .colorBlock .mainColumnTwoColumn p a,
.department .colorBlock .mainColumnTwoColumn li a {
    font-weight: normal !important;
}

.ContactComponent a,
.facilityContact a,
#mainColContent .contactTile .contactTilePhone a,
#mainColContent .contactTile .contactTileAddress a,
#mainColContent .contactTile .contactTileEmail a {
    font-weight: normal !important;
}

.department .colorBlock .mainColumnTwoColumn h3 a {
    font-weight: bold !important;
}

//sample page: council home page
.mainColumnTwoColumn .titledCollectionList a {
    text-decoration: none !important;
}

.mainColumnTwoColumn .feedContainer .rssTitle a {
    text-decoration: none !important;
    color: #000 !important;
}

.mainColumnTwoColumn .feedContainer .rssTitle a:hover {
    background: #003da5 !important;
    color: #fff !important;
}

//card links inside tabs
.tabContent .card.cardwithlink a,
.tabContent .card.cardwithlink a:visited,
.tabContent .card.cardwithlink a:hover,
.tabContent .card.cardwithlink a:active,
.tabContent .card.cardwithlink a:focus
{
    text-decoration: none !important;
}
//blog title
.tabContent .rssTitle a,
.tabContent .rssTitle a:visited,
.tabContent .rssTitle a:active,
.tabContent .rssTitle a:focus,
.accordionWrapper .rssTitle a,
.accordionWrapper .rssTitle a:visited,
.accordionWrapper .rssTitle a:active,
.accordionWrapper .rssTitle a:focus {
    text-decoration: none !important;
    color: #000 !important;
}

.tabContent .rssTitle a:hover,
.accordionWrapper .rssTitle a:hover {
    text-decoration: none !important;
    background: #003da5 !important;
    color: #fff !important;
}

