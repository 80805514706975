/*Mobile & Tablet
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
@media (max-width: 991px) {
    html {
        font-size: $global_fontSize_tablet_default;
    }

    h1 {
        font-size: 2.375rem;
        font-weight: 700;
        font-family: $global_fontFamily_default;
    }

    h2 {
        font-size: 1.875rem;
        font-weight: 700;
        font-family: $global_fontFamily_default;
    }

    h3 {
        font-size: 1.375rem;
        font-weight: 700;
        font-family: $global_fontFamily_default;
    }

    h4 {
        font-size: 1.125rem;
        font-weight: 700;
        font-family: $global_fontFamily_default;
    }

    h5 {
        font-size: 0.875rem;
        font-weight: 700;
        font-family: $global_fontFamily_default;
    }

    .colorBlock.colorBlockQuoteWide .textOnly {
        padding: 0 40px;
    }
    .colorBlockQuoteWide.colorBlock:not(.SearchCollectionFormContainerComponent):not(.SearchCollectionFormComponent) {
        padding: 26px 40px;
    }

    .parallaxCopy {
        max-width: 750px;
    }

    /*Department RightCol*/
    .department #rightCol:before {
        visibility: hidden;
    }
    /*Department Left Nav*/
    .department nav .leftNav2,
    .department nav #leftNav {
        display:none;
    }

    /*Department Layout*/
    .department #main,
    .department aside{ 
        padding:0px;
        float: left;
    }
    .department .col-md-9 .twoColLeft,
    .department .col-md-9 .twoColRight{
        padding:0px 10px;
    }
    .department .twoColLeft {
        padding-bottom: 15px;
        overflow-x: hidden;
    }
    .department .twoColRight {
        overflow-x: hidden;
    }
    #departmentFooterLinksNewsWrapper,
    #departmentContactFooter {
        padding:30px 15px;
    }
    /*Department Footer Links*/
    #deptFootNewsWrapper{
        width:100%;
        padding-top:40px;
        padding-bottom:70px;
    }
    #deptFootNewsWrapper i.fa, .newsletterSignup i.fa {
        padding-right:5px;
    }
    #deptFootNewsWrapper form, .newsletterSignup form {
        margin-top: 10px;
    }
    #deptFootNewsWrapper input[name="email"], .newsletterSignup input[name="email"] {
        width: 95%;
        float:left;
        position:absolute;
    }
    #deptFootNewsWrapper button, .newsletterSignup button {
        right:0px;
        margin-top:0px;
        position:absolute;
    }
    /*feature image*/
    .featureWrapper.emptyFeatureWrapper,
    .featureWrapperShort {
        height:0px;
        padding-bottom:30%;
        margin-bottom: 0px;
    }
    .taxonomyTile.width2, #featuredTileContainer .overlayBox {
        width: $tiles_width_default;
    }
    #featuredTileContainer .featureTile h3.tileTitle {
        font-family: $global_fontFamily_default;
        font-size: 21px;
        font-weight:bold;
        line-height: 22px;
        max-width: 92%;
        margin-bottom:12px;
    }
    #featuredTilesCarousel .carousel-control {
        display: none;
    }
    #featuredTileContainer .programTile .overlayBox {
        bottom: 0px;
    } 
    .taxonomyTile.width2.height2.slick-slide.slick-cloned {
        padding: 0px;
    }
    #featuredTileContainer .slick-cloned .overlayBox {
        margin-left: 0px;
    }
    /* mobileServices wrapper element*/
    .mobileServices, .nestedTileWrapper {
        border: none;
        margin: 5px;
    }
    .mobileServices .halfHeight.taxonomyTile, .nestedTileWrapper .taxonomyTile {
        margin-right: 0px;
        margin-left: 0px;
    }
    .mobileServices .halfHeight:first-child, .nestedTileWrapper .taxonomyTile:first-child {
        margin-bottom: 10px;
        margin-top: 0px;
    }    
    /* Photo Credits*/    
    #featuredTileContainer .taxonomyTile .photoCredit{
        display:block;
        text-align: right;
        width: 280px;
        right: inherit;
    }
    #featuredTileContainer .taxonomyTile .photoCredit i.fa-camera {
        font-size:14px;
        padding:5px;
        margin-top:5px;
        color: $global_color_lightGray;
    }

    #featuredTileContainer .taxonomyTile .photoCredit svg.fa-camera {
        font-size: 14px;
        padding: 5px;
        margin-top: 5px;
        color: $global_color_lightGray;
    }

    .featureWrapper .featuredPhotoCreditContainer
    {
        display: none;
    }
        
    #featuredTileContainer .taxonomyTile .seattleAlert .photoCredit {
        width: 100%;
        right: 60px;
    }
    .featureTile.seattleAlert .alertIcon {
        margin-left: 240px;
    }

    #featuredTileContainer .taxonomyTile .photoCredit .tooltip,
    #featuredTileContainer .taxonomyTile .photoCredit .tooltip-inner,
    #featuredTileContainer .taxonomyTile .tooltip-arrow {
        display:none;
    }

    /*Topic Filter Inputs*/
    #topicFilterContainer input#filterTextInput {
        width: 70%;
    }

    #topicFilterContainer button.btn.clearFilters {
        width: 100%;
    }

    /*Topic Page Program Jump*/
    .programJump{
        display:none;
    }
    .programJumpMobile {
        top:45px;
        right:15px;
        position:absolute;
        text-decoration:underline;
        font-weight:bold;
    }
    .programJumpMobile.additionalFilterOffSet {
        top:136px;
    }
    .programJumpMobile i{
        color: $global_color_black;
        padding-left: 5px;
    }

    /* SearchFormCollection */
    .findByInput button#currentLocation{
        z-index:3;
        left:10px;
        width:30px;
        height:30px;
        padding:0px;
        top:10px;
        background: $global_color_white;
        border:2px solid #ddd;
        border-radius:50px;
        color:#002da5;
    }
    .findByInput button#currentLocation:hover{
        background: $global_color_black;
        color: $global_color_black;
        border:2px solid $global_color_black;
    }
    .findByInput button#currentLocation:not([style*="display: none"]) ~ * input, 
    .findByInput button#currentLocation:not([style="*display: none"]) ~ input{
        padding-left:51px;
    }
    /*Featured Wrapper*/
    .featureWrapper.mobile:not(.emptyFeatureWrapper){
        padding:0px;
    }
    .featureWrapper.mobile #featuredTileContainer{
        margin:0px !important;
        height:inherit !important;
    }
    /*Slicked*/
    #slickWilly{
        background: $global_color_darkGray;
        padding:5px 0px 0px 0px;
    }
    #slickWilly .container{
        margin-bottom:0px;
    }
    #slickWilly .taxonomyTile{
        overflow:hidden;
        position:relative;
    }
    #slickWilly .nestedTileWrapper .taxonomyTile{
        position:relative;
    }
    #slickWilly .nestedTileWrapper .taxonomyTile:first-child{
        margin-bottom:10px;
    }
    #slickWilly .nestedTileWrapper .taxonomyTile .overlayBox{
        bottom:0px;
    }
    /*Not Slicked*/
    #nonSlickWilly{ 
        background: $global_color_lightGray;
        padding:15px 0px;
    }
    /*Location pages*/
    .locationPage .col-xs-12.amenities,
    .locationPage .col-xs-12.bodyText {
        float:initial;
    }
    .locationPage .imageGallery:after {
        content:'';
        display:inline-block;
        width:100%;
    }
    .parkDetailPage .featureWrapper.emptyFeatureWrapper,
    .parkDetailPage .featureWrapperShort {
        margin-bottom: 0px;
        padding-bottom: 50%;
    }
    .parkDetailPage #parkHoursAndContact {
        background: #F3F7FF;
    }    
    #parkHoursAndContact .parkHoursAndContactContainer {
        padding: 10px;
    }
    .contactTile #parkContactExpanded {
        background: $global_color_white;
        margin:15px -10px;
        padding:30px 40px;
        -moz-box-shadow:    inset  0  8px 8px -8px rgba(0,0,0,.15), 
                    inset  0 -8px 8px -8px rgba(0,0,0,.15);
        -webkit-box-shadow: inset  0  8px 8px -8px rgba(0,0,0,.15), 
                    inset  0 -8px 8px -8px rgba(0,0,0,.15);
        box-shadow:       inset  0  8px 8px -8px rgba(0,0,0,.15), 
                    inset  0 -8px 8px -8px rgba(0,0,0,.15);
    }

    .locationPage .mobilePageTitle {
        background: $global_color_black;
    }
    .locationPage .mobilePageTitle h1.pageTitle{
        position:relative;
    }

    .bladeMode {
        width: 100%;
        overflow: hidden;
    }

    /** legacy - TO BE CLEANED UP AFTER GO LIVE **/
    #deptFootLinksWrapperShort ul li{
        float:inherit;
    }	
    /** END legacy - TO BE CLEANED UP AFTER GO LIVE **/

    /**Department Footer Links --- Short**/
    #deptFootLinksWrapperShort.aboutNav, #aboutBar {

        padding-bottom: 25px;

        ul {
            left: 0;

            li {
                float:inherit !important;
                padding: 0;

                a {
                    display: block;
                    padding: 0;
                }

                &:first-child {
                    border-right: none !important;
                    padding: 0;

                    a {
                        border-bottom: solid 1px $global_color_white;
                        padding-left: 0 !important;
                    }
                }
            }
        }
    }	
}

/*Mobile
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
@media (max-width: 767px) {


    html {
        font-size: $global_fontSize_mobile_default;
    }

    h1 {
        font-size: 2.375rem;
        font-weight: 700;
        font-family: $global_fontFamily_default;
    }

    h2 {
        font-size: 1.875rem;
        font-weight: 700;
        font-family: $global_fontFamily_default
    }

    h3 {
        font-size: 1.375rem;
        font-weight: 700;
        font-family: $global_fontFamily_default;
    }

    h4 {
        font-size: 1.125rem;
        font-weight: 700;
        font-family: $global_fontFamily_default;
    }

    h5 {
        font-size: 0.875rem;
        font-weight: 700;
        font-family: $global_fontFamily_default;
    }


    #mainColContent #mainColMain .row {
        margin-top: 30px;
    }

    h1.pageTitle {
        font-size: 2.3rem;
        line-height: 24px;
        margin: 25px 0px 14px 0px;
    }

    #deptBreadcrumbsContainer {
        display: none;
    }
    /*Featured Container */
    .featureWrapper{
        padding:5px 0px;
    }
    .featureWrapper:not(.emptyFeatureWrapper, .bannerHalfImage) {
        background-color: $global_color_darkGray !important;
        background-size: cover;
        background-position: center;
    }
    .featureWrapper.emptyFeatureWrapper{
    /* display: none; */
    }
    div#featuredTileContainer {
        margin-top:10px;
        height:320px !important;
    }
    #featuredTileContainer > .taxonomyTile {
        margin: 5px 0; 
    }
    div#featuredTileContainer.slick-slider {
        margin-bottom: 10px;
    } 
    .featureWrapper .featuredPhotoCreditContainer{
        display: none;
    }
    .secondaryTiles, #secondaryItems .taxonomyTile, #topicServices, #featuredTopicServices .taxonomyTile, #topicPrograms .taxonomyTile {
        width: 96%;
        min-width: 300px;
    }           
    /* topic filter mobile*/
    #topicFilterContainer, #additionalFiltersWrap {
        display: none;
        border: solid 1px $global_color_lightGray;
        padding: 10px;
    }
    button#topicFilterBtn {  
        width: 100%;                    
        border-radius: 0;
        margin-left: 0;
    }
    /*Department Footer*/
    div#deptFootLinksWrapper {
        padding-right: 10px;
        padding-left: 10px;
    }
    #deptFootContactWrapper{
        text-align:left; 
        float:left !important; 
        margin-top:30px; 
        margin-left:5px;
    }
    /*Elected Page - Contact Tiles*/
    #home .tileContactNavigation,
    #mainContent.topTier .tileContactNavigation {
        width: $tiles_width_default; /*isotope freaks out without this*/
        margin: 5px auto;
    }

    #mainContent.topTier .taxonomyTile.navigationContact{
        margin:5px auto;
        left:0px;
        right:0px;
    }
    #mainContent.topTier h3.secondaryListTitle{
        width: $tiles_width_default;
        margin:0px;
        padding-top:40px;
        text-align:left;
    }
    /*Homepage Electeds*/
    .homeElectedNavigationWrapper{
        -webkit-box-shadow: 0px 0px 10px rgba(0,0,0,.8);
        -moz-box-shadow: 0px 0px 10px rgba(0,0,0,.8);
        box-shadow:inset -20px 20px -20px 20px rgba(0,0,0,.95);
        background: $global_color_white;
    }
    .homeElectedNavigationWrapper .taxonomyTile.navigationContact{
        margin:5px auto;
        left:0px;
        right:0px;
    }
    .homeElectedNavigationWrapper h2{
        width: $tiles_width_default;
        margin:0px auto;
        padding:20px 0px 0px 0px;
        text-align:left;
        color: $global_color_black;
    }
    .homeElectedNavigationWrapper h3{float:none !important;}

    .homeElectedNavigationWrapper h3 a{
        width: $tiles_width_default;
        margin:0px auto;
        text-align:left;
        border:1px solid $global_color_mediumGray;
        color: $global_color_black;
        font-family: $global_fontFamily_default;
        font-size:18px;
        display:block;
        padding:15px;
    }
    .homeElectedNavigationWrapper h3 a i{float:right;}
                
    /*Dept footer address pop out*/     
    #departmentContactFooter .footerPhysicalAddress,
    #departmentContactFooter .footerMailingAddress{ 
        float:left; 
        border-top:1px solid $global_color_black;
        width:100%;
        padding-top:20px;
    }
    /* SearchFormCollection */
    .featureWrapperSlides,
    .featureWrapperSlides .slide{
        height:450px;
    }
    .searchFormCollectionWrapper .featureWrapper,
    .searchFormCollectionWrapper .featureWrapper.emptyFeatureWrapper{
        padding-top:0px;
        height:450px;
    }
    .featureWrapperSlides .slick-prev,
    .featureWrapperSlides .slick-next{
        margin-top:130px;
    }
    .featureWrapperSlides .slick-prev{
        left:-20px;
    }
    .featureWrapperSlides .slick-next{
        right:-20px;
    }
    .featureWrapperSlides .slick-prev:before,
    .featureWrapperSlides .slick-next:before{
        font-size: 50px;
    }
    .searchFormCollectionWrapper .searchFormWrapper {
        width: 92%;
        margin: 90px auto;
    }
    .byWrapper{
        display:block;
        clear:both;
        left: 0px;
        margin: -5px 0px 0px 0px;
        width: 100%;
        text-align: center;
    }
    
    .findByWrapper.form-group {
        margin-top: 30px;
    }
    /*Hide Search Results Map initially in mobile*/
    #searchRefineBlade + .container #mainContent .row div.col-md-6:last-child { 
        display:none;
    }
    .pseudoHidden{
        height:0px;
        overflow:hidden;
        position:relative;
    }
    /* Center Tiles inside Color Blocks */
    .colorBlock.TitledCollection .container{
        width:320px;
    }
    /*Not Slicked*/
    #nonSlickWilly .container{
        width: 305px;
        padding:0px !important;
    }
    #nonSlickWilly .taxonomyTile{
        margin-left: 0px;
        margin-right: 0px;
    }
    /*Color Block Topic Filter Component*/
    .colorBlock.TopicFilterComponent .topicSidebar,
    .colorBlock.TopicFilterComponent .topics{
        width:320px;
        margin:0px auto;
        float:none;
    }
    /* Data tables */
    .dataTables_length, .dataTables_filter {
        clear: none !important;
        margin: 0 0 10px !important;
    }
    .dataTables_length {
        float: left !important;
        text-align: left !important;
        width: 25%;
    }
    .dataTables_filter {
        float: right !important;
        text-align: right !important;
        width: 75%;
    }
    .dataTables_filter input {
    }
    .dataTables_info {
        background: $global_color_darkGray none repeat scroll 0 0;
        color: $global_color_white !important;
        font-weight: 600;
        margin-top: 10px;
        padding-bottom: 10px;
    }
    /**Location Pages**/
    .locationPage .emptyFeatureWrapper.mobile {
        height: 300px;
    }
    .locationPage .emptyFeatureWrapper.mobile h1 {
        font-size:30px;
    }
    .locationPage .featureWrapper.noImage,
    .parkDetailPage .featureWrapper.noImage {
        display: block;
        background-color: $global_color_darkGray;
    }
    .parkHoursAndContactContainer .svg-inline--fa {
        width: 40px;
        text-align: center;
    }
    #locationHoursAndContact .svg-inline--fa,
    #parkHoursAndContact .svg-inline--fa {
        line-height: 25px;
    }
    /*Location A to Z Lists*/
    .aToZ .paginationContainer{
        position:relative !important;
        margin-top:-50px;
        max-width:800px;
    }
    .aToZ #mainColMain .paginationItem  div {
        margin-bottom:10px;
    }
    .aToZ #mainContent .paginationItem img{
        display:none;
    }
    .aToZ #mainContent .padTop .span{
        display:none;
    }
    .department #rightCol {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .MajorEvent h1.pageTitle {
        font-size: 22px;
        line-height: 32px;
        margin: 20px 0px;
    }
}

/*Not Mobile
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
@media (min-width: 768px) {
    .leftNav2 li,      
    #leftNav li{
        width: 100%;
    }
    /*Location Pages*/
    .locationPage h1.pageTitle,
    .parkDetailPage h1.pageTitle {
        margin-left: 10px;
    }
}

/*Tablet & Medium Devices
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
@media (min-width: 768px) and (max-width: 991px) {
    .secondaryTiles .taxonomyTile, #secondaryItems .taxonomyTile, #topicServices .taxonomyTile, #featuredTopicServices .taxonomyTile, #topicPrograms .taxonomyTile {
        width: 48%;
        min-width: $tiles_width_default;
    }

    #contentListWrapper {
        background-position: center;
        padding-bottom: 100px;
    }
    /*Featured Container*/
    .department .featureWrapper {
        margin-bottom: 30px;
    }

    .featureWrapper:not(.emptyFeatureWrapper, .bannerHalfImage) {
        background-color: $global_color_darkGray !important;
    }

    .featureWrapper .featuredPhotoCreditContainer {
        display: none;
    }

    div#featuredTileContainer {
        margin-top: 20px;
        height: $tiles_width_default !important;
    }
    /* SearchFormCollection */
    .featureWrapperSlides .slick-prev,
    .featureWrapperSlides .slick-next {
        margin-top: 100px;
    }

    .featureWrapperSlides .slick-prev {
        left: -10px;
    }

    .featureWrapperSlides .slick-next {
        right: -10px;
    }

    .featureWrapperSlides .slick-prev:before,
    .featureWrapperSlides .slick-next:before {
        font-size: 30px;
    }
    /* Center Tiles inside Color Blocks */
    .colorBlock.TitledCollection .container {
        width: 640px;
    }
    /*Not Slicked*/
    #nonSlickWilly {
        padding: 40px 0px;
    }

    #nonSlickWilly .container {
        width: 640px;
        padding: 0px !important;
    }
    /*Color Block Topic Filter Component*/
    .colorBlock.TopicFilterComponent #taxonomyTermGroup {
        -moz-column-count: 3;
        -moz-columns: 3;
        -webkit-columns: 3;
        columns: 3;
    }

    .colorBlock.TopicFilterComponent .topicSidebar {
        padding: 0px 5px;
    }

    .colorBlock.TopicFilterComponent .topicSidebar,
    .colorBlock.TopicFilterComponent .topics {
        width: 640px;
        margin: 0px auto;
        float: none;
    }

    /** legacy - TO BE CLEANED UP AFTER GO LIVE **/
    #deptFootLinksWrapperShort {
        padding: 30px 0px 5px 0px;
    }

    #deptFootLinksWrapperShort ul:not(.footerLinkList) {
        -moz-column-count: 2;
        -moz-columns: 2;
        -webkit-columns: 2;
        columns: 2;
    }

    #deptFootLinksWrapperShort ul li {
        float: inherit;
        margin: 0px 0px 20px 0px;
    }
    /** END legacy - TO BE CLEANED UP AFTER GO LIVE **/

    /**Department Footer Links --- Short**/
    #deptFootLinksWrapperShort.aboutNav {
        ul {

            li {
                width: 100%;

                &:first-child {

                }
            }
        }
    }
}

@media (max-width: 575px) {

    #deptFootLinksWrapperShort.aboutNav, #aboutBar {     
        ul {

            li:first-child {
                margin-bottom: 0;
                padding: 0 20px;

                a {
                    font-size: 26px !important;
                }
            }

            li {
                padding: 0 20px !important;

                a {
                    font-size: 16px !important;
                }
            }
        }
    }

    #topicFilterContainer, #additionalFiltersWrap {
        min-width: 300px;
    }
    #additionalFiltersWrap {
        border-top: none;
    }

}